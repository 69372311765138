import styled from 'styled-components';
import ProductPrice from '../../../../../components/shared/ProductPrice';

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const ImageContainer = styled.div`
  height: 100px;
  max-height: 100px;
  aspect-ratio: 0.75;
  flex-shrink: 0;
`;

export const InfoContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const InfoContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InfoContainerRight = styled.div`
  /* border: 1px dashed red; */
`;

export const StyledProductPrice = styled(ProductPrice)`
  margin-top: auto;
`;
