import { useDiscovery } from '../context';
import FilterName from './FilterName';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterNameContainer = () => {
  const { filters, selectedFilterName, setSelectedFilterName } = useDiscovery();

  return (
    <Wrapper>
      {filters.map(filter => (
        <FilterName
          key={filter.name}
          isSelected={filter.name === selectedFilterName}
          onClick={() => setSelectedFilterName(filter.name)}
        >
          {filter.placeholder}
        </FilterName>
      ))}
    </Wrapper>
  );
};

export default FilterNameContainer;
