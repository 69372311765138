import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export const PORTAL_ROOT_ID = 'portal-root';

function createWrapperAndAppendToBody() {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', PORTAL_ROOT_ID);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

interface IPortalProps {
  children: ReactNode;
}

const Portal = ({ children }: IPortalProps) => {
  let element = document.getElementById(PORTAL_ROOT_ID);
  // if element is not found with wrapperId,
  // create and append to body
  if (!element) {
    element = createWrapperAndAppendToBody();
  }

  return createPortal(children, element);
};

export default Portal;
