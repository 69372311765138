import { useEffect } from 'react';
import {
  getCustomizationRemarks,
  getStorePickups,
  setCustomizationRemarks,
  setCustomizationRemarksPagination,
  setPendingTasksSearch,
  setStorePickups,
  setStorePickupsPagination,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import Discovery from '../../../components/desktop/Discovery';
import { useDebounce } from '@point-of-sale/hooks';
import { emptyFunction } from '@point-of-sale/utils';

const PendingTasksDiscovery = () => {
  const search = useAppSelector(store => store.pendingTasks.search);

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      setPendingTasksSearch({
        query: event.target.value,
      })
    );

    dispatch(
      setStorePickupsPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    dispatch(
      setStorePickups({
        data: {
          ids: [],
          records: {},
        },
      })
    );

    dispatch(
      setCustomizationRemarksPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    dispatch(
      setCustomizationRemarks({
        data: {
          ids: [],
          records: {},
        },
      })
    );
  }

  const debouncedApiCall = useDebounce(() => {
    dispatch(getStorePickups());
    dispatch(getCustomizationRemarks());
  }, 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [search.query]);

  const dispatch = useAppDispatch();

  return (
    <Discovery
      filters={[]}
      onFilterChange={emptyFunction}
      onFilterClear={emptyFunction}
      placeholder="Search by Customer Name, Customer Phone"
      onSearchChange={onSearchChange}
      searchQuery={search.query}
      renderSelectedFilters={() => null}
    />
  );
};

export default PendingTasksDiscovery;
