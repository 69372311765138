import { CartIcon, CustomerIcon, ReceiptIcon } from '@point-of-sale/components';
import { RootStateType, setIsEditCustomerInfoModalOpen, store } from '@point-of-sale/store';
import { openInNewTab, openInSameTab } from '@point-of-sale/utils';

export const getBreadcrumbs = (cartId: number) => [
  {
    icon: CustomerIcon,
    title: 'Add Customer Details',
    identifier: 'add-customer',
    onClick: () => {
      openInNewTab('/point-of-sale/add-customer');
    },
  },
  {
    icon: CartIcon,
    title: 'Add Items',
    identifier: 'cart',
    onClick: () => {
      if (cartId === -1) {
        openInSameTab(`/point-of-sale/add-customer`);
        return;
      }

      openInSameTab(`/point-of-sale/cart?id=${cartId}`);
    },
  },
  {
    icon: ReceiptIcon,
    title: 'Complete Payment',
    identifier: 'checkout',
    onClick: () => {
      if (cartId === -1) {
        openInSameTab(`/point-of-sale/add-customer`);
        return;
      }

      const reduxStore = store.getState() as RootStateType;
      const reduxDispatch = store.dispatch;

      const isCartEmpty = reduxStore.pointOfSale.cart.data.cartItems.ids.length === 0;

      if (isCartEmpty) {
        return;
      }

      const selectedAddressId = reduxStore.pointOfSale.selectedAddressId;

      const customerAddress =
        reduxStore.pointOfSale.addresses.data.records[reduxStore.pointOfSale.selectedAddressId];

      const cartItems = reduxStore.pointOfSale.cart.data.cartItems;

      const containsSomeStorePickupItems = cartItems.ids.some(
        id => cartItems.records[id].deliveryMode === 'STORE_PICKUP'
      );

      const containsSomeHomeDeliveryItems = cartItems.ids.some(
        id => cartItems.records[id].deliveryMode === 'HOME_DELIVERY'
      );

      const homeDeliveryItemsWithoutAddress = cartItems.ids.filter(
        id =>
          cartItems.records[id].deliveryMode === 'HOME_DELIVERY' &&
          !cartItems.records[id].customerDeliveryAddressId
      );

      const customerData = reduxStore.pointOfSale.customer.data;

      if (!customerData.phone && containsSomeStorePickupItems) {
        reduxDispatch(setIsEditCustomerInfoModalOpen(true));
        return;
      }

      if ((selectedAddressId === -1 || !customerAddress) && containsSomeHomeDeliveryItems) {
        reduxDispatch(setIsEditCustomerInfoModalOpen(true));
        return;
      }

      if (homeDeliveryItemsWithoutAddress.length > 0) {
        reduxDispatch(setIsEditCustomerInfoModalOpen(true));
        return;
      }

      openInSameTab(`/point-of-sale/checkout?id=${cartId}`);
    },
  },
];
