import { addDays, isSameDay } from 'date-fns';
import { CheckBox } from '@point-of-sale/components';
import { AddressDTOType, INormalizedServiceabilityData } from '@point-of-sale/schemas';
import { emptyFunction, formatToReadableDate } from '@point-of-sale/utils';
import { HOME_DELIVERY } from './constants';

import * as Styles from './styles';
import { useEffect } from 'react';

interface IDeliverToHomeProps {
  onChange: (promiseDate: string) => void;
  isSelected: boolean;
  shouldShowDelivery: boolean;
  homeDeliveryDate: string;
  fulfillmentFacilityId?: number;
}

const DeliverToHome = ({
  onChange,
  isSelected,
  shouldShowDelivery,
  homeDeliveryDate,
}: IDeliverToHomeProps) => {
  return (
    <Styles.Tr $isSelected={isSelected} onClick={() => onChange(homeDeliveryDate)}>
      <Styles.Td>
        <CheckBox
          shape="round"
          name={HOME_DELIVERY}
          isChecked={isSelected}
          onChange={emptyFunction}
        />
      </Styles.Td>
      <Styles.Td>Deliver To Home</Styles.Td>
      <Styles.Td></Styles.Td>
      <Styles.Td>
        {shouldShowDelivery && (
          <>
            {homeDeliveryDate !== 'EXPRESS' && <>Est. Delivery: &nbsp;</>}
            {homeDeliveryDate}
          </>
        )}
      </Styles.Td>
    </Styles.Tr>
  );
};

export default DeliverToHome;
