import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import { Button } from '@point-of-sale/components';
import { ProductListItemContext } from './context';
import BasicInfo from './BasicInfo';
import VariantsInfo from './VariantsInfo';
import InventoryBottomSheet from './InventoryBottomSheet';
import { useProductVariants } from '../../../../hooks/useProductVariants';
import * as Styles from './styles';

interface IProductListItemProps {
  id: number;
  lastItemRef?: (node: HTMLDivElement) => void;
}

const ProductListItem = ({ id, lastItemRef }: IProductListItemProps) => {
  const data = structuredClone(
    useAppSelector(store => store.pointOfSale.products.data.records[id], shallowEqual)
  );

  const [isInventoryBottomSheetOpen, inventoryBottomSheetActions] = useBoolean();

  const {
    selectedVariant,
    allColorOptions,
    allSizeOptions,
    onSelectColor,
    onSelectSize,
    enabledSizes,
  } = useProductVariants({ data });

  return (
    <ProductListItemContext.Provider
      value={{
        id,
        data,
        selectedVariant,
        allColorOptions,
        allSizeOptions,
        onSelectColor,
        onSelectSize,
        enabledSizes,
        isInventoryBottomSheetOpen,
        inventoryBottomSheetActions,
      }}
    >
      <Styles.Wrapper ref={lastItemRef}>
        <BasicInfo />
        <VariantsInfo />
        <Button
          rightIcon="ChevronRightIcon"
          onClick={inventoryBottomSheetActions.on}
          variant="outline"
          sizeVariant="xs"
        >
          View Inventory Details
        </Button>
        <InventoryBottomSheet />
      </Styles.Wrapper>
    </ProductListItemContext.Provider>
  );
};

export default ProductListItem;
