import { IconPropsType } from '../types';

const CameraSwitchIcon = ({ fill = 'var(--white)', size = 24, ...props }: IconPropsType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={fill}
      d="M21 6h-1.5a.5.5 0 0 1-.5-.5A1.5 1.5 0 0 0 17.5 4h-6A1.5 1.5 0 0 0 10 5.5a.5.5 0 0 1-.5.5H8V5H4v1H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m1 12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2V6h2v1h2.5A1.5 1.5 0 0 0 11 5.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5A1.5 1.5 0 0 0 19.5 7H21a1 1 0 0 1 1 1zM8.2 13h-1a4.796 4.796 0 0 1 8.8-2.644V9h1v3h-3v-1h1.217A3.79 3.79 0 0 0 8.2 13m7.6 0h1A4.796 4.796 0 0 1 8 15.644V17H7v-3h3v1H8.783a3.79 3.79 0 0 0 7.017-2"
    />
  </svg>
);
export default CameraSwitchIcon;
