import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { thunk } from 'redux-thunk';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { identityReducer } from './identity/reducer';
import { pointOfSaleReducer } from './pointOfSale/reducer';
import { checkoutReducer } from './checkout/reducer';
import { commonReducer } from './common/reducer';
import { openCartsReducer } from './openCarts/reducer';
import { pendingTasksReducer } from './pendingTasks/reducer';
import { orderManagementSystemReducer } from './orderManagementSystem/reducer';
import { ENV } from '@point-of-sale/env';
import { permissionReducer } from './permissions/slice';

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['identity', 'permissions'],
};

const rootReducer = combineReducers({
  identity: identityReducer,
  pointOfSale: pointOfSaleReducer,
  checkout: checkoutReducer,
  openCarts: openCartsReducer,
  pendingTasks: pendingTasksReducer,
  common: commonReducer,
  oms: orderManagementSystemReducer,
  permissions: permissionReducer,
});

// <any, any> because of an open issue https://github.com/rt2zz/redux-persist/issues/1140
const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
  devTools: ENV.VITE_ENVIRONMENT === 'local',
});

export const persistor = persistStore(store);

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type ThunkActionType = ThunkAction<void, RootStateType, unknown, Action>;
