import toast from 'react-hot-toast';
import { addDays, getUnixTime } from 'date-fns';
import type { Primitive } from 'type-fest';
import {
  addItemToCart,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { Button } from '@point-of-sale/components';
import { FacilityDTOType, INormalizedServiceabilityData } from '@point-of-sale/schemas';
import { useBoolean } from '@point-of-sale/hooks';
import { getExpressPromiseDate } from '@point-of-sale/utils';
import useProductContext from '../context/useProductContext';

interface IBuyFromAnotherStoreProps {
  anotherStore: FacilityDTOType;
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const BuyFromAnotherStore = ({
  anotherStore,
  serviceabilityData,
  isFetchingServiceability,
}: IBuyFromAnotherStoreProps) => {
  const { quantity, selectedVariant, fulfillmentFacilityId, deliverToPincode } =
    useProductContext();

  const customerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  function addToCart() {
    if (!selectedVariant) {
      toast.error('Please select a variant');
      return;
    }

    if (!fulfillmentFacilityId) {
      toast.error('[fulfillmentFacilityId] not found');
      return;
    }

    loadingActions.on();

    // * BEWARE OF FROM AND TO IN THIS CASE
    const serviceabilityFilteredByFrom = serviceabilityData[selectedVariant.skuCode].filter(
      item =>
        String(item.from.facilityId) === String(anotherStore.id) &&
        item.info.facilityPincode === anotherStore.address.pincode
    );

    let serviceability;

    if (isSelectedSalesChannelOnline) {
      serviceability = serviceabilityFilteredByFrom.find(item => item.to === deliverToPincode);
    } else {
      serviceability = serviceabilityFilteredByFrom?.[0];
    }

    const promiseDate = serviceability?.info.express
      ? getExpressPromiseDate()
      : serviceability?.info.jit
      ? getUnixTime(addDays(new Date(), serviceability?.info.jitTat ?? 1)).toString()
      : getUnixTime(addDays(new Date(), serviceability?.info.bestTat ?? 1)).toString();

    const extraProperties: Record<string, Primitive> = isSelectedSalesChannelOnline
      ? {
          customerDeliveryAddressId: customerAddress?.id, // * customer's address id
        }
      : {
          customerDeliveryStoreId: fulfillmentFacilityId, // * current store's id
        };

    if (serviceability?.info.express) {
      extraProperties['isExpress'] = true;
    }

    dispatch(
      addItemToCart(
        {
          skuId: selectedVariant.skuId,
          skuCode: selectedVariant.skuCode,
          deliveryMode: isSelectedSalesChannelOnline
            ? 'HOME_DELIVERY'
            : serviceability?.info.jit
            ? 'STORE_PICKUP'
            : 'STORE_PICKUP',
          fulfillmentMode: serviceability?.info.jit ? 'JIT' : 'ONHAND',
          fulfillmentFacilityId: anotherStore.id, // * another store's id
          fulfillmentFacilityGroupId: anotherStore.facilityGroupId,
          fulfillmentFacilityType: anotherStore.facilityType,
          quantity,
          promiseDate: promiseDate,
          ...extraProperties,
        },
        loadingActions.off
      )
    );
  }

  return (
    <Button
      sizeVariant="xs"
      variant="outline"
      onClick={addToCart}
      isLoading={isLoading || isFetchingServiceability}
    >
      Buy from {anotherStore.name}
    </Button>
  );
};

export default BuyFromAnotherStore;
