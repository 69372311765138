export const POSActionsTypes = {
  // * Search Operations
  SET_SEARCH: 'pos/SET_SEARCH',
  SET_PAGINATION: 'pos/SET_PAGINATION',

  SET_IS_BARCODISH: 'pos/SET_IS_BARCODISH',

  SET_PRODUCTS: 'pos/SET_PRODUCTS',
  UPDATE_FILTER: 'pos/UPDATE_FILTER',
  DELETE_FILTER: 'pos/DELETE_FILTER',

  SET_MODE: 'pos/SET_MODE',

  SET_FACETS: 'pos/SET_FACETS',

  SET_CUSTOMER_DETAILS: 'pos/SET_CUSTOMER_DETAILS',
  SET_CUSTOMER_GST_DETAILS: 'pos/SET_CUSTOMER_GST_DETAILS',
  SET_CUSTOMER_GST_MANUAL_DETAILS: 'pos/SET_CUSTOMER_GST_MANUAL_DETAILS',

  // * Product level operations
  SET_SELECTED_COLOR: 'pos/SET_SELECTED_COLOR',
  SET_SELECTED_SIZE: 'pos/SET_SELECTED_SIZE',

  // * Cart Operations

  SET_CART_DETAILS: 'pos/SET_CART_DETAILS',
  RESET_CART_STATE: 'pos/RESET_CART_STATE',
  ADD_TO_CART: 'pos/ADD_TO_CART',
  SET_CART_ITEM_QUANTITY: 'pos/SET_CART_ITEM_QUANTITY',
  DELETE_CART_ITEM: 'pos/DELETE_CART_ITEM',

  UPDATE_CART_ITEM_EDIT_CACHE: 'pos/UPDATE_CART_ITEM_EDIT_CACHE',

  // * Address Operations
  SET_ADDRESSES_DATA: 'pos/SET_ADDRESSES_DATA',

  SET_SELECTED_ADDRESS_ID: 'pos/SET_SELECTED_ADDRESS_ID',

  RESET: 'pos/RESET',

  SET_ID_EDIT_CUSTOMER_INFO_MODAL_OPEN: 'pos/SET_ID_EDIT_CUSTOMER_INFO_MODAL_OPEN',

  SET_CUSTOMER_INFO_MODAL_UPDATE_CALLBACK: 'pos/SET_CUSTOMER_INFO_MODAL_UPDATE_CALLBACK',

  SET_BUYING_WITHOUT_INVENTORY_ALLOWED_FOR_BARCODE:
    'pos/SET_BUYING_WITHOUT_INVENTORY_ALLOWED_FOR_BARCODE',

  SET_SERVICEABILITY_FOR_CART: 'pos/SET_SERVICEABILITY_FOR_CART',
} as const;

export const DEFAULT_SEARCH_FILTER = {
  field: 'variants.inStockFacilities',
  filterType: 'ISNOTEMPTY',
  value: '',
} as const;
