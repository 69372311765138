import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import * as Styles from './styles';

interface ILayoutProps {
  header?: ReactNode;
}

const Layout = ({ header }: ILayoutProps) => {
  return (
    <Styles.Wrapper>
      <Outlet />
    </Styles.Wrapper>
  );
};

export default Layout;
