import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { motion } from 'motion/react';

interface ToggleButtonProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  icons?: { checked?: React.ReactNode; unchecked?: React.ReactNode };
  name?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  leftLabel?: string;
  rightLabel?: string;
}

interface ToggleWrapperProps {
  $isOn: boolean;
  $isDisabled?: boolean;
}

const ToggleContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isDisabled }: { $isDisabled?: boolean }) =>
    $isDisabled ? 'not-allowed' : 'pointer'};
`;

const Label = styled.span<{ $isDisabled: boolean }>`
  margin: 0 8px;
  font-size: 14px;
  color: ${({ $isDisabled }: { $isDisabled?: boolean }) =>
    $isDisabled ? 'var(--dove-gray)' : 'var(--cod-gray)'};
`;

const ToggleWrapper = styled.div<ToggleWrapperProps>`
  width: 36px;
  height: 20px;
  border-radius: 12px;
  background-color: ${({ $isOn, $isDisabled }) =>
    $isDisabled ? 'var(--ghost-white)' : $isOn ? 'var(--royal-blue)' : 'var(--ghost-white)'};
  display: flex;
  align-items: center;
  justify-content: ${({ $isOn }) => ($isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
  transition: background-color 0.3s;

  border: 0.5px solid ${({ $isOn }) => ($isOn ? 'var(--royal-blue) ' : ' var(--athens-gray) ')};
`;

const ToggleCircle = styled(motion.div)`
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Toggle = ({
  checked,
  defaultChecked,
  onChange,
  name,
  ariaLabel,
  ariaLabelledBy,
  leftLabel,
  rightLabel,
  icons = {},
  isDisabled = false,
}: ToggleButtonProps) => {
  const [isOn, setIsOn] = React.useState(defaultChecked || false);

  // Handle controlled component if `checked` prop is provided
  const isControlled = checked !== undefined;

  const handleClick = () => {
    if (isDisabled) return;

    if (!isControlled) {
      setIsOn(!isOn);
    }

    if (onChange) {
      onChange({ target: { checked: !isOn } } as ChangeEvent<HTMLInputElement>);
    }
  };

  const renderIcon = (icon: React.ReactNode, isChecked: boolean) =>
    icon ? <IconWrapper>{isChecked ? icon : icon}</IconWrapper> : null;

  return (
    <ToggleContainer $isDisabled={isDisabled}>
      {leftLabel && <Label $isDisabled={isDisabled}>{leftLabel}</Label>}

      <ToggleWrapper
        onClick={handleClick}
        $isOn={isControlled ? checked : isOn}
        $isDisabled={isDisabled}
      >
        {renderIcon(icons.checked, isOn)}
        <HiddenCheckbox
          name={name}
          checked={isControlled ? checked : isOn}
          onChange={handleClick}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          disabled={isDisabled}
        />
        <ToggleCircle layout transition={{ type: 'spring', stiffness: 700, damping: 30 }} />
        {renderIcon(icons.unchecked, !isOn)}
      </ToggleWrapper>

      {rightLabel && <Label $isDisabled={isDisabled}>{rightLabel}</Label>}
    </ToggleContainer>
  );
};

export default Toggle;
