// import { useEffect, useMemo } from 'react';
// import styled from 'styled-components';
// import { Text } from '@point-of-sale/components';
// import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { formatNumberToLocale, getDiscountPercentage } from '@point-of-sale/utils';
import { IProductListItem, useAppSelector } from '@point-of-sale/store';
import useProductContext from '../context/useProductContext';
import useDiscount from '../hooks/useDiscount';
import ProductPrice from '../../../shared/ProductPrice';

// const PriceText = styled.div`
//   width: unset;
//   /* margin-bottom: 4px; */

//   display: flex;
//   flex-direction: column;
//   align-items: end;
//   flex-wrap: wrap;

//   p,
//   span {
//     text-align: right;
//     white-space: nowrap;
//   }
// `;

interface IPriceProps {
  isExpanded: boolean;
  selectedVariant: IProductListItem['variants'][number] | null;
}

const Price = ({ isExpanded, selectedVariant }: IPriceProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const { data } = useProductContext();

  const { discount, currencySymbol, determinedVariant } = useDiscount({
    product: data,
    selectedVariant,
  });

  return (
    <ProductPrice
      currencySymbol={currencySymbol}
      determinedVariantPrices={determinedVariant}
      discount={discount}
      isExpanded={isExpanded}
      mode={mode}
      selectedVariant={selectedVariant}
    />
  );

  // return (
  //   <PriceText>
  //     {!selectedVariant && (
  //       <Text as="span" fontSize={12} color="var(--dove-gray)">
  //         Starting at
  //         <br />
  //       </Text>
  //     )}
  //     {discount > 0 && (
  //       <Text
  //         as="span"
  //         fontSize={isExpanded ? 14 : 10}
  //         weight="semibold"
  //         color="var(--persian-red)"
  //       >
  //         {`${discount}% off`}
  //         &nbsp;&nbsp;
  //         <Text
  //           as="span"
  //           fontSize={isExpanded ? 16 : 12}
  //           color="var(--alto)"
  //           textDecorationLine="line-through"
  //         >
  //           {currencySymbol}
  //           &nbsp;
  //           {formatNumberToLocale(determinedVariant.mrp)}
  //         </Text>{' '}
  //       </Text>
  //     )}
  //     <Text
  //       fontSize={isExpanded ? 24 : 14}
  //       lineHeight={isExpanded ? 32 : 20}
  //       textAlign="right"
  //       weight={isExpanded ? 'bold' : 'semibold'}
  //       color="var(--cod-gray)"
  //     >
  //       {currencySymbol}
  //       &nbsp;
  //       {mode === 'BUY_NOW' && formatNumberToLocale(determinedVariant.sellingPrice)}
  //       {mode === 'MADE_TO_ORDER' &&
  //         formatNumberToLocale(determinedVariant.mtoSellingPrice ?? determinedVariant.sellingPrice)}
  //     </Text>
  //   </PriceText>
  // );
};

export default Price;
