import { IOption } from '@point-of-sale/components';

export interface IDiscountOption extends IOption {
  filter: 'variants.totalDiscount' | 'variants.discountGroupId';
}

export const getDiscountFilterOptions = (): Array<IDiscountOption> => {
  return [
    {
      label: '10% off',
      value: '10',
      filter: 'variants.totalDiscount',
    },
    {
      label: '20% off',
      value: '20',
      filter: 'variants.totalDiscount',
    },
    {
      label: '30% off',
      value: '30',
      filter: 'variants.totalDiscount',
    },
    {
      label: 'Buy 2 get 20% off',
      value: '1',
      filter: 'variants.discountGroupId',
    },
  ];
};
