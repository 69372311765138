import toast from 'react-hot-toast';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import type { CredentialResponse } from '@react-oauth/google';
import { useAppDispatch, verifyGoogleAuth } from '@point-of-sale/store';
import { GOOGLE_CLIENT_ID } from './constants';
import * as Styles from './styles';

interface IGoogleLoginButtonProps {
  setStep: (step: number) => void;
}

const GoogleLoginButton = ({ setStep }: IGoogleLoginButtonProps) => {
  const dispatch = useAppDispatch();

  const handleSuccess = (response: CredentialResponse) => {
    if (response.credential) {
      dispatch(
        verifyGoogleAuth(response.credential, {
          successCallback: () => {
            setStep(3);
          },
          failureCallback: () => {
            toast.error('Could not log you in, Try with another method');
          },
        })
      );
    }
  };

  const handleFailure = () => {
    toast.error('Could not log you in, Try with another method');
  };

  return (
    <Styles.Wrapper>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
          text="continue_with"
          ux_mode="popup"
        />
      </GoogleOAuthProvider>
    </Styles.Wrapper>
  );
};

export default GoogleLoginButton;
