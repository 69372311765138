import { ReactNode, useEffect } from 'react';
// import { AnimatePresence } from 'motion/react';
import IconButton from '../IconButton';
import Portal from '../Portal';
import { ModalSizeVariants } from './types';
import * as Styles from './styles';
import { swallowEvent } from '@point-of-sale/utils';

interface IModalProps {
  isOpen: boolean;
  onClose?: VoidFunction;
  children: ReactNode;
  heading?: ReactNode;
  footer?: ReactNode;
  size?: ModalSizeVariants;
}

const Modal = ({
  isOpen,
  onClose,
  heading,
  children,
  footer,

  size = 'regular',
}: IModalProps) => {
  const handleEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose?.();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  //AnimatePresence
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isOpen && (
        <Portal>
          <Styles.Overlay
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1, backdropFilter: 'blur(2px)', WebkitBackdropFilter: 'blur(2px)' }}
            // exit={{ opacity: 0 }}
            // transition={{ duration: 0.3 }}
            onClick={e => {
              if (e.target === e.currentTarget) {
                swallowEvent(e);
                onClose?.();
              }
            }}
          >
            <Styles.Wrapper
              $size={size}
              initial={{
                opacity: 0,
                y: -100,
              }}
              animate={{ y: 0, opacity: 1 }}
              exit={{
                opacity: 0,
                y: 100,
              }}
              // onClick={swallowEvent}
            >
              {onClose && (
                <Styles.CloseButtonWrapper>
                  <IconButton
                    shapeVariant="circle"
                    icon="CrossIcon"
                    onClick={event => {
                      swallowEvent(event);
                      onClose();
                    }}
                  />
                </Styles.CloseButtonWrapper>
              )}
              {heading && <Styles.Header>{heading}</Styles.Header>}
              <Styles.Content $size={size}>{children}</Styles.Content>
              {footer && <Styles.Footer>{footer}</Styles.Footer>}
            </Styles.Wrapper>
          </Styles.Overlay>
        </Portal>
      )}
    </>
  );
};

export default Modal;
