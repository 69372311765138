import { useMemo } from 'react';
import { format } from 'date-fns';
import { OrderDTOType } from '@point-of-sale/schemas';
import { upsertSalesStaffInOrder, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { formatNumberToLocale, openInNewTab } from '@point-of-sale/utils';
import { Button, Select, Text } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import ImageGallery from '../../ImageGallery';
import InvoiceRegenerationModal from '../InvoiceRegenerationModal';
import * as TableStyles from '../../Table/styles';

interface IBasicDetailsProps {
  data: OrderDTOType;
}

const BasicDetails = ({ data }: IBasicDetailsProps) => {
  const [isInvoiceGenerationModalOpen, invoiceGenerationModalActions] = useBoolean();

  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);
  const salesStaffIds = useAppSelector(store => store.common.storeStaff.data.ids);
  const salesStaffRecords = useAppSelector(store => store.common.storeStaff.data.records);

  const dispatch = useAppDispatch();

  const images = useMemo(
    () =>
      Object.values(data.lineItems ?? {}).map(item => ({
        src: item.productImage?.url,
        text: item.productName,
        subText: item.brandName,
      })),
    [data.lineItems]
  );

  const salesFacility = data.salesFacilityId ? facilityRecords[data.salesFacilityId] : null;

  const shouldShowSalesPoc = data.salesChannelType === 'POS';

  const salesStaffOptions = salesStaffIds.map(id => ({
    value: id,
    label: salesStaffRecords[id].name,
  }));

  return (
    <>
      <TableStyles.Wrapper
        $shouldFillHeight={false}
        style={{
          width: '100%',
        }}
      >
        <TableStyles.Table
          style={{
            width: '100%',
          }}
        >
          <TableStyles.Thead>
            <TableStyles.TableHeadingRow>
              <TableStyles.TableHeader>Items</TableStyles.TableHeader>
              <TableStyles.TableHeader>Customer Details</TableStyles.TableHeader>
              <TableStyles.TableHeader>Order Id</TableStyles.TableHeader>
              <TableStyles.TableHeader>Order Date</TableStyles.TableHeader>
              <TableStyles.TableHeader>Billing Location</TableStyles.TableHeader>
              {shouldShowSalesPoc && <TableStyles.TableHeader>Sales Poc</TableStyles.TableHeader>}
              <TableStyles.TableHeader>No. Of Items</TableStyles.TableHeader>
              <TableStyles.TableHeader>Total Amount</TableStyles.TableHeader>
              <TableStyles.TableHeader>Invoice</TableStyles.TableHeader>
            </TableStyles.TableHeadingRow>
          </TableStyles.Thead>
          <tbody>
            <TableStyles.TableRow key={data.salesChannelOrderId} $zebra={false}>
              <TableStyles.TableData>
                <ImageGallery heading="Product Gallery" truncateAfter={3} images={[...images]} />
              </TableStyles.TableData>
              <TableStyles.TableData>
                {!data.customerId ? (
                  <Text fontSize={12} weight="medium">
                    GUEST
                  </Text>
                ) : (
                  <TableStyles.TableData>
                    <Text fontSize={12} weight="medium">
                      {data.customerName}
                    </Text>
                    <br />
                    <Text fontSize={12} weight="medium">
                      {data.customerPhone}
                    </Text>
                  </TableStyles.TableData>
                )}
              </TableStyles.TableData>
              <TableStyles.TableData>{String(data.salesChannelOrderId)}</TableStyles.TableData>
              <TableStyles.TableData>
                {data.createdOn && format(new Date(data.createdOn), 'PPpp')}
              </TableStyles.TableData>
              <TableStyles.TableData>
                {data.salesChannelType === 'SHOPIFY' ? (
                  'Shopify'
                ) : (
                  <>
                    {salesFacility?.name},
                    <br />
                    {salesFacility?.address.cityDisplayName}
                  </>
                )}
              </TableStyles.TableData>

              {shouldShowSalesPoc && (
                <TableStyles.TableData style={{ width: '200px' }}>
                  <Select<(typeof salesStaffOptions)[number]>
                    options={salesStaffOptions}
                    value={salesStaffOptions.find(option => option.value === data.salesStaffId)}
                    onChange={selectedOption => {
                      if (!selectedOption) {
                        return;
                      }

                      const newSalesPoc = Object.values(salesStaffRecords).find(
                        item => String(item.id) === String(selectedOption.value)
                      );

                      if (newSalesPoc && newSalesPoc.id !== data.salesStaffId) {
                        dispatch(upsertSalesStaffInOrder(data.id, newSalesPoc.id));
                      }
                    }}
                    placeholder="Select Sales POC"
                    isSearchable

                    // error={error}
                  />
                </TableStyles.TableData>
              )}

              <TableStyles.TableData>{data.lineItems?.length}</TableStyles.TableData>

              <TableStyles.TableData>
                {CURRENCY_SYMBOLS[(data.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code}{' '}
                {formatNumberToLocale(data.costBreakup?.chargeableAmount ?? 0)}
              </TableStyles.TableData>
              <TableStyles.TableData>
                {data.purchaseReceipt?.url ? (
                  <Button
                    leftIcon="ReceiptIcon"
                    sizeVariant="xs"
                    colorScheme="dark"
                    variant="outline"
                    onClick={() => {
                      if (data.purchaseReceipt?.url) {
                        openInNewTab(data.purchaseReceipt.url);
                      }
                    }}
                  >
                    Bill copy
                  </Button>
                ) : (
                  <Button
                    leftIcon="ReceiptIcon"
                    sizeVariant="xs"
                    colorScheme="dark"
                    variant="outline"
                    onClick={invoiceGenerationModalActions.on}
                  >
                    Generate Invoice
                  </Button>
                )}
              </TableStyles.TableData>
            </TableStyles.TableRow>
          </tbody>
        </TableStyles.Table>
      </TableStyles.Wrapper>
      <InvoiceRegenerationModal
        onClose={invoiceGenerationModalActions.off}
        isOpen={isInvoiceGenerationModalOpen}
        orderData={data}
      />
    </>
  );
};

export default BasicDetails;
