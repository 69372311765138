import { useEffect } from 'react';
import { LegacySelect, Select, Text } from '@point-of-sale/components';
import { SourceForOnlinePosEnumType } from '@point-of-sale/schemas';
import {
  assignSourceToCart,
  setSalesPoc,
  setSalesStaffIdOfCart,
  setSourceForOnlinePos,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import * as Styles from './styles';

const Parties = () => {
  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  const salesPoc = useAppSelector(store => store.checkout.salesPoc);
  const sourceForOnlinePos = useAppSelector(store => store.checkout.sourceForOnlinePos);
  const { ids: storeStaffIds, records: storeStaffRecords } = useAppSelector(
    store => store.common.storeStaff.data
  );
  const savedSalesStaffId = useAppSelector(store => store.pointOfSale.cart.data.salesStaffId);
  const savedSourceForOnlinePos = useAppSelector(store => store.pointOfSale.cart.data.source);

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (savedSalesStaffId) {
      const selectedSalesPoc = Object.values(storeStaffRecords).find(
        item => String(item.id) === String(savedSalesStaffId)
      );

      if (selectedSalesPoc) {
        dispatch(setSalesPoc(selectedSalesPoc));
      }
    }
  }, [savedSalesStaffId, storeStaffRecords]);

  useEffect(() => {
    if (savedSourceForOnlinePos) {
      dispatch(setSourceForOnlinePos(savedSourceForOnlinePos));
      // dispatch(assignSourceToCart(savedSourceForOnlinePos));
    }
  }, [savedSourceForOnlinePos]);

  const staffOptions = storeStaffIds
    .filter(id => storeStaffIds.includes(id))
    .map(id => ({ label: storeStaffRecords[id].name, value: id }));

  return (
    <Styles.Wrapper>
      <Styles.FormFieldWrapper>
        <Text fontSize={14} weight="bold" color="var(--black)">
          Sales POC
          <Text fontSize={14} color="var(--alizarin-crimson)">
            *
          </Text>
        </Text>

        <Select<(typeof staffOptions)[number]>
          options={staffOptions}
          value={staffOptions.find(option => option.value === salesPoc?.id)}
          onChange={selectedOption => {
            if (!selectedOption) {
              return;
            }

            const newSalesPoc = Object.values(storeStaffRecords).find(
              item => String(item.id) === String(selectedOption.value)
            );
            if (newSalesPoc) {
              dispatch(setSalesStaffIdOfCart(newSalesPoc));
            }
          }}
          placeholder="Select Sales POC"
          isSearchable
          isDisabled={hasPaymentsStarted}

          // error={error}
        />
      </Styles.FormFieldWrapper>

      {isSelectedSalesChannelOnline && (
        <Styles.FormFieldWrapper>
          <Text fontSize={14} weight="bold" color="var(--black)">
            Source
            <Text fontSize={14} color="var(--alizarin-crimson)">
              *
            </Text>
          </Text>
          <LegacySelect
            isOutlined
            sizeVariant="small"
            options={[
              {
                label: 'Try at Home',
                value: 'TRY_AT_HOME',
              },
              {
                label: 'Whatsapp',
                value: 'WHATSAPP',
              },
              {
                label: 'Video Call',
                value: 'VIDEO_CALL',
              },
            ]}
            value={sourceForOnlinePos ?? ''}
            defaultValue={sourceForOnlinePos ?? ''}
            onChange={selectedOption => {
              dispatch(assignSourceToCart(selectedOption.value as SourceForOnlinePosEnumType));
            }}
          />
        </Styles.FormFieldWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default Parties;
