import { callApi } from '../services/callApi';

export const requestOtpApi = (email: string) =>
  callApi('POST', `/pos/auth/request/otp`, {
    targetMode: 'EMAIL',
    identityKey: email,
    operation: 'LOGIN',
  });

export const loginApi = ({
  id,
  secret,
  authMode,
}: {
  id: string;
  authMode: 'EMAIL_OTP' | 'GOOGLE';
  secret?: string;
}) =>
  callApi('POST', `/pos/auth/login`, {
    id,
    secret,
    authMode,
  });

export const logoutApi = ({ token, refreshToken }: { refreshToken: string; token: string }) =>
  callApi('POST', `/pos/auth/logout`, {
    token,
    refreshToken,
  });
