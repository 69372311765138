import { useEffect, useState } from 'react';

export const useReleaseIdentifier = () => {
  const [releaseIdentifier, setReleaseIdentifier] = useState(null);

  useEffect(() => {
    fetch('/release-info.json')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        // throw new Error('Failed to fetch release info');
      })
      .then(data => setReleaseIdentifier(data.github_run_number))
      .catch(error => console.error('Error:', error));
  }, []);

  return { releaseIdentifier };
};
