import styled from 'styled-components';
import { ThemeType } from '../themes/types';
import IconButton from '../IconButton';
import { VIEW_PORTS } from '../constants/viewports';

export const Wrapper = styled.div`
  height: 45px;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 32px 0 12px;

  position: relative;

  flex: 1;
`;

export const Input = styled.input<{ theme: ThemeType }>`
  background: var(--white);
  outline: none;
  border: none;

  font-size: ${({ theme }) => theme.typography.fonts.size[16]};
  font-weight: ${({ theme }) => theme.typography.fonts.weight.semibold};
  flex: 1;

  ::placeholder {
    color: var(--silver);
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
`;
