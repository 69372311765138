import styled from 'styled-components';

export const Wrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

export const Content = styled.div`
  background: var(--white);
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;

  div.half {
    width: 50%;
  }
`;

export const Actionables = styled.div`
  position: sticky;
  bottom: 0;
  padding: 16px;
  background: var(--white);
  border-top: 1px solid var(--alto);
  z-index: 10;
`;

export const PhoneNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 16px;

  input[name='phone'] {
    height: 40px;
  }
`;
