import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/App';
import { getRootNode } from '@point-of-sale/utils';

const root = ReactDOM.createRoot(getRootNode());

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
