import { IColor, ISize } from '@point-of-sale/components';
import { BooleanActions } from '@point-of-sale/hooks';
import { IProductListItem } from '@point-of-sale/store';
import { createContext, useContext } from 'react';

interface ProductListItemContextProps {
  id: number;
  data: IProductListItem;
  selectedVariant: IProductListItem['variants'][number] | null;
  // TODO: Create type somewhere else
  allColorOptions: Array<{
    displayName: string;
    colorCodes?: string[] | undefined;
  }>;
  // TODO: Create type somewhere else
  allSizeOptions: Array<{
    id: number;
    name: string;
    quantity:
      | {
          store: null;
          warehouse: null;
        }
      | {
          store: number;
          warehouse: number;
        };
  }>;

  enabledSizes: Array<string>;

  onSelectColor: (newColor: IColor) => void;
  onSelectSize: (newSize: ISize) => void;

  isInventoryBottomSheetOpen: boolean;
  inventoryBottomSheetActions: BooleanActions;
}

export const ProductListItemContext = createContext<ProductListItemContextProps | undefined>(
  undefined
);

export const useProductListItem = (): ProductListItemContextProps => {
  const context = useContext(ProductListItemContext);
  if (context === undefined) {
    throw new Error('useProductListItem must be used within a ProductListItemProvider');
  }
  return context;
};
