import { ProductVariantDTOType } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';
import { omit } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';
import { Button, Capsule } from '@point-of-sale/components';
import { NoResults } from '@runway/illustrations';
import * as Styles from './styles';

interface IInventoryTableProps {
  inventory: ProductVariantDTOType['facilityInventory'];
}

const InventoryTable = ({ inventory }: IInventoryTableProps) => {
  const [shouldShowZeroInventory, zeroInventoryActions] = useBoolean();

  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const facilities = useAppSelector(store => store.common.facilities.data);

  const inventoryToShow = omit(inventory ?? {}, [currentFacilityId as unknown as string]);

  const inventoryArray = Object.entries(inventoryToShow).map(([id, details]) => ({
    id,
    ...(details as { availableInventory: number }),
  }));

  inventoryArray.sort((a, b) => b.availableInventory - a.availableInventory);

  const fulfillableInventory = inventoryArray.filter(inventory => inventory.availableInventory > 0);
  const nonFulfillableInventory = inventoryArray.filter(
    inventory => inventory.availableInventory === 0
  );

  const filteredInventory = shouldShowZeroInventory ? inventoryArray : fulfillableInventory;

  return (
    <Styles.Wrapper>
      <Styles.Table>
        {filteredInventory.length > 0 && (
          <thead>
            <Styles.TableRow>
              <Styles.TableHeader>Facility ID</Styles.TableHeader>
              <Styles.TableHeader>Inventory</Styles.TableHeader>
            </Styles.TableRow>
          </thead>
        )}

        <tbody>
          {filteredInventory.map(inventory => {
            const facility = facilities.records[inventory.id as unknown as number];

            if (!facility) {
              return null;
            }

            return (
              <Styles.TableRow key={inventory.id}>
                <Styles.TableData>
                  {facility?.name}, &nbsp;
                  {facility?.address?.cityDisplayName}
                  {facility?.facilityType === 'VENDOR' && (
                    <Capsule
                      essence="light"
                      complexion="accent"
                      size="small"
                      content="JIT"
                      className="vendor-capsule"
                    />
                  )}
                </Styles.TableData>
                <Styles.TableData>{inventory?.availableInventory}</Styles.TableData>
              </Styles.TableRow>
            );
          })}

          {filteredInventory.length === 0 && (
            <Styles.TableRow>
              <Styles.TableData colSpan={2} $hasBorder={false}>
                <NoResults text="No inventory available at other locations" />
              </Styles.TableData>
            </Styles.TableRow>
          )}

          {nonFulfillableInventory.length > 0 && (
            <Styles.TableRow>
              <Styles.TableData colSpan={2}>
                <Button
                  onClick={zeroInventoryActions.toggle}
                  sizeVariant="xs"
                  colorScheme="dark"
                  variant="outline"
                >
                  {shouldShowZeroInventory ? 'Hide' : 'Show'} Non-fulfillable Inventory
                </Button>
              </Styles.TableData>
            </Styles.TableRow>
          )}
        </tbody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default InventoryTable;
