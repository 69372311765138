import { Text } from '@point-of-sale/components';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);
  position: relative;

  border-radius: 8px;
`;

export const DisabledOverlay = styled.div`
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  cursor: not-allowed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  border-radius: 8px;
`;

export const DisabledReason = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;
`;

export const Heading = styled.header`
  border-bottom: 1px solid var(--alto);
  padding: 0 24px;

  display: flex;
  align-items: center;
`;

export const Main = styled.main`
  display: flex;
`;

export const PaymentModesWrapper = styled.div`
  border-top: 2px solid var(--lunar-green);
`;

interface IPaymentModesItemProps {
  $isActive?: boolean;
  $isDisabled?: boolean;
}

export const PaymentModesItem = styled.div<IPaymentModesItemProps>`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  border-bottom: 1px solid var(--alto);
  height: 80px;

  min-width: 210px;

  border-right: ${({ $isActive }) => ($isActive ? 'none' : '1px solid var(--alto)')};

  background: ${({ $isActive }) => ($isActive ? 'var(--white)' : 'var(--titan-white)')};

  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

export const SectionsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SectionsContent = styled.div`
  padding: 16px 20px;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SectionsFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
`;
