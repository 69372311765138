import { motion } from 'motion/react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const Filter = styled(motion.div)`
  border: 1px solid var(--alto);
  padding: 0 12px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--white);
  width: fit-content;
`;
