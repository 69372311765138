import { WithHeader } from '@point-of-sale/components';
import OpenCartsTable from '../../../components/desktop/OpenCartsTable';
import Cart from '../../../components/desktop/Cart';
import * as Styles from './styles';
import OpenCartsDiscovery from './OpenCartsDiscovery';

const OpenCarts = () => {
  return (
    <Styles.Wrapper>
      <OpenCartsDiscovery />
      <Styles.Content>
        <Styles.Left>
          <OpenCartsTable />
        </Styles.Left>
        <Styles.Right>
          <Cart usage="RECOVER" />
        </Styles.Right>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default WithHeader(OpenCarts, {
  name: 'Open Carts',
});
