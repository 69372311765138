import { WithHeader } from '@point-of-sale/components';
import ProductDiscovery from '../../../components/mobile/ProductDiscovery';
// import ProductsTable from '../../../components/desktop/ProductsTable';
import ProductsList from '../../../components/mobile/ProductsList';
import * as Styles from './styles';

const ExploreProducts = () => {
  return (
    <Styles.Wrapper>
      <ProductDiscovery />
      <Styles.Content>
        <ProductsList />
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default WithHeader(ExploreProducts, {
  name: 'Inventory',
});
