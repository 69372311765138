import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px;
  height: calc(100dvh - 52px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
`;

export const Content = styled.div`
  overflow: auto;
  display: flex;
  gap: 16px;
  flex: 1;
`;
