import { z } from 'zod';
import { SourceForOnlinePosEnum } from '../checkout';
import { DeliveryModeSchema, FulfillmentModeSchema } from '../common';
import { AlterationEntryDTOSchema } from '../common/alterationEntry';
import { CustomizationCategorySchema } from './customizationCategory';

export const AddCartItemDTOSchema = z.object({
  skuId: z.number(),
  skuCode: z.string(),
  quantity: z.number(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  fulfillmentFacilityId: z.number(),
  customerDeliveryStoreId: z.number().optional(),
  customerDeliveryAddressId: z.number().optional(),
  promiseDate: z.string().optional(),
  isExpress: z.boolean().optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  alterationEntry: AlterationEntryDTOSchema.optional(),
  addedWithoutInventory: z.boolean().optional(),
});

export type AddCartItemDTOType = z.infer<typeof AddCartItemDTOSchema>;

export const CartItemProductVariantDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  images: z.array(z.object({ url: z.string(), uploaded: z.boolean() })).optional(),
  brandName: z.string(),
  productName: z.string(),
  components: z.string(),
  size: z.string(),
  color: z.string(),
  sellingPrice: z.number(),
  mrp: z.number(),
  option: z.object({ SIZE: z.string(), COLOR: z.string() }),
  skuId: z.number(),
  vendorId: z.number(),
  skuCode: z.string(),
  currency: z.string(),
  barcode: z.string(),
  taxClassPerc: z.number().optional(),
  exchangeable: z.boolean(),
  returnable: z.boolean(),
  vendorArticleNumber: z.string().optional(),
  enabled: z.boolean(),
  availableFacilities: z.array(z.string()).optional(),
  inStockFacilities: z.array(z.string()).optional(),
  facilityInventory: z.record(z.string(), z.object({ availableInventory: z.number() })).optional(),
  inventorySummary: z.record(z.string(), z.number()).optional(),
  mtoAcceptedByBrand: z.boolean(),
  mtcAcceptedByBrand: z.boolean().optional(),
  mtoTAT: z.number().optional(),
  mtcTAT: z.number().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
  discountGroupId: z.number().optional(),
});

export const SkuAttributeMappings = z.array(
  z.object({
    attributeName: z.string(),
    isMandatory: z.boolean(),
    isMandatoryForWeb: z.boolean(),
    validValues: z.array(z.string()).optional(),
  })
);

export type SkuAttributeMappingType = z.infer<typeof SkuAttributeMappings>;

const BaseProductTypeDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string().datetime(),
  lastModifiedOn: z.string().datetime(),
  version: z.number(),
  id: z.number(),
  name: z.string(),
  level: z.string(),
  parentId: z.number(),
  enabled: z.boolean(),
  skuAttributeMappings: SkuAttributeMappings.optional(),
  imageAssetUrl: z.string().optional(),
  featured: z.boolean().optional(),
  sizeChartImageAssetUrl: z.string().optional(),
});

export type ParentTypeType = z.infer<typeof BaseProductTypeDTOSchema> & {
  parent?: ParentTypeType;
};

export const ProductTypeDTOSchema: z.ZodType<ParentTypeType> = BaseProductTypeDTOSchema.extend({
  parent: z.lazy(() => ProductTypeDTOSchema.optional()),
});

export const SkuEntryDtoSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  status: z.string(),
  code: z.string(),
  barcode: z.string(),
  vendorArticleNumber: z.string(),
  lkStyleNumber: z.string(),
  vendorSkuCode: z.string(),
  taxClassPerc: z.number(),
  currency: z.string(),
  mrp: z.number(),
  sellingPrice: z.number(),
  name: z.string(),
  labelName: z.string(),
  productName: z.string(),
  productTypeId: z.number(),
  vendorId: z.number(),
  mtcAcceptedByBrand: z.boolean(),
  mtoAcceptedByBrand: z.boolean(),
  returnable: z.boolean(),
  exchangeable: z.boolean(),
  hsnCode: z.string(),
  shortDescription: z.string(),
  longDescription: z.string(),
  descriptionOfProductAttachments: z.string(),
  images: z.array(z.object({ id: z.number(), url: z.string(), uploaded: z.boolean() })),
  components: z.string(),
  componentsQty: z.string(),
  size: z.string(),
  brandSize: z.string(),
  color: z.string(),
  brandColor: z.string(),
  primaryColor: z.string(),
  secondaryColor: z.string(),
  lining: z.boolean(),
  washType: z.string(),
  washCareDescription: z.string(),
  modelSizeDescription: z.string(),
  fit: z.string(),
  fitDescription: z.string(),
  materialForLabelPrint: z.string(),
  productType: ProductTypeDTOSchema,
  brandName: z.string(),
  assetAlbumId: z.number(),
  brandPDPUrl: z.string(),
  countryOfOrigin: z.string(),
  sizeInCm: z.string(),
  manufacturedByName: z.string(),
  manufacturedByAddress: z.string(),
  season: z.string(),
  occasions: z.string(),
  pockets: z.string(),
  neckType: z.string(),
  sleeveLength: z.string(),
  dressSkirtLength: z.string(),
  productFeatures: z.string(),
  fabricForListing: z.string(),
  fabricDescription: z.string(),
  liningFabric: z.string(),
  vendorDiscount: z.number(),
  platformDiscount: z.number(),
  groupId: z.string(),
  importedOn: z.string(),
  fabricForLabel: z.string(),
  heroLine: z.string(),
  pressPieces: z.string(),
  valueDrivers: z.string(),
  gbb: z.string(),
  seasonalMustHave: z.string(),
  noos: z.string(),
  continuity: z.string(),
  rangeName: z.string(),
  materialForOnlineListing: z.string(),
  materialDescription: z.string(),
  productCare: z.string(),
  closure: z.string(),
  dimensions: z.string(),
  strap: z.string(),
  strapMaterial: z.string(),
  innerMaterial: z.boolean(),
  innerMaterialDescription: z.string(),
  pattern: z.string(),
  numCompartments: z.string(),
  productId: z.number(),
  categoryId: z.number(),
  subCategoryId: z.number(),
  bodySizeBustCm: z.number(),
  bodySizeWaistCm: z.number(),
  styleDescriptionBrand: z.string(),
  styleDescriptionWebsite: z.string(),
  tags: z.array(z.string()),
  isSizeChartValid: z.boolean(),
  merchType: z.string(),
  materialAndCareHTML: z.string(),
  enabledForMeta: z.boolean(),
  enabledForOnline: z.boolean(),
});

export const ServiceChargeDTOSchema = z.object({
  amount: z.union([z.string(), z.number()]),
  type: z.enum(['SHIPPING', 'BRAND_CUSTOMIZATION', 'ALTERATION']),
  remarks: z.string().optional(),
});

export type ServiceChargeDTOType = z.infer<typeof ServiceChargeDTOSchema>;

export const CustomChargeDTOSchema = z.array(ServiceChargeDTOSchema);

export type CustomChargeDTOType = z.infer<typeof CustomChargeDTOSchema>;

export const DiscountDTOSchema = z.object({
  couponCode: z.string(),
});

export type DiscountDTOType = z.infer<typeof DiscountDTOSchema>;

export const CartItemDTOSchema = z.object({
  id: z.number(),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  productVariant: CartItemProductVariantDTOSchema,
  quantity: z.number(),
  fulfillmentFacilityId: z.number(),
  deleted: z.boolean(),
  totalSellingPrice: z.number(),
  totalShippingCharge: z.number().optional(),
  totalCodCharge: z.number().optional(),
  enabled: z.boolean().optional(),
  oos: z.boolean(),
  serviceable: z.boolean(),
  totalBagDiscount: z.number().optional(),
  eligibleForComputation: z.boolean(),
  sellingPrice: z.number(),
  customerDeliveryStoreId: z.number().optional(),
  skuCode: z.string(),
  customerDeliveryAddressId: z.number().optional().nullable(),
  promiseDate: z.string().optional(),
  isExpress: z.boolean().optional(),
  alterationEntry: AlterationEntryDTOSchema.optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  customCharges: CustomChargeDTOSchema.optional(),
  chargeableAmount: z.number().optional(),
  chargeableAmountExCustomCharges: z.number().optional(),
  totalCouponDiscount: z.number().optional(),
});

export type CartItemDTOType = z.infer<typeof CartItemDTOSchema>;

export const CartStatusSchema = z.enum([
  'ACTIVE',
  'PAYMENT_PROCESSING',
  'ORDER_PLACED',
  'EXPIRED',
  'DISCARDED',
]);

export type CartStatusType = z.infer<typeof CartStatusSchema>;

export const CartDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  salesChannelId: z.number().optional(),
  salesChannelTerminalId: z.number(),
  shippingCharge: z.number().optional(),
  codCharge: z.number().optional(),
  bagDiscount: z.number().optional(),
  cartItems: z.array(CartItemDTOSchema),
  customerId: z.number().optional(),
  chargeableAmount: z.number().optional(),
  amountPaid: z.number().optional(),
  minAmountToBePaid: z.number().optional(),
  customCharges: CustomChargeDTOSchema.optional(),
  salesStaffId: z.number().optional(),
  status: CartStatusSchema.optional(),
  currency: z.string().optional(),
  salesFacilityId: z.number().optional(),
  customerPincode: z.string().optional(),
  couponEnabled: z.boolean(),
  couponCode: z.string().optional(),
  couponDiscount: z.number().optional(),
  customerBillingName: z.string().optional(),
  customerPhone: z.string().optional(),
  customerBillingAddressId: z.number().optional(),
  customerGSTIN: z.string().optional(),
  couponErrorMessage: z.string().optional(),
  source: SourceForOnlinePosEnum.optional(),
});

export type CartDTOType = z.infer<typeof CartDTOSchema>;
