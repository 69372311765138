import { Text } from '@point-of-sale/components';
import PendingStorePickupsTable from '../../../components/desktop/PendingStorePickupsTable';
import PendingCustomizationRemarks from '../../../components/desktop/PendingCustomizationRemarks';
import * as Styles from './styles';
import PendingTasksDiscovery from './PendingTasksDiscovery';

const PendingTasks = () => {
  return (
    <Styles.Wrapper>
      {/* <Styles.Search
        placeholder="Search customer name, customer phone, order number, item name etc"
        isFullWidth
        sizeVariant="xl"
        leftIcon="SearchIcon"
        value={''}
        autoFocus
        // onBlur={() => searchInputRef.current?.focus()}
        onChange={() => {
          // console.log('asdc');
        }}
      /> */}
      <PendingTasksDiscovery />
      <Styles.Content>
        <Styles.Top>
          <Text fontSize={16} weight="semibold">
            Pending Store Pickups
          </Text>
          <PendingStorePickupsTable />
        </Styles.Top>
        <Styles.Bottom>
          <Text fontSize={16} weight="semibold">
            Pending Customization Remarks
          </Text>
          <PendingCustomizationRemarks />
        </Styles.Bottom>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default PendingTasks;
