import { useEffect } from 'react';
import {
  setCartMinAmountToBePaid,
  setCartTotalAmountPaid,
  setCartTotalAmountToBePaid,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import useCartParams from '../../../hooks/useCartParams';
import Summary from './Summary';
import PaymentCollection from './PaymentCollection';
import Parties from './Parties';
import MinimumCollections from './MinimumCollections';
import * as Styles from './styles';

const Checkout = () => {
  useCartParams();

  const { chargeableAmount, amountPaid, minAmountToBePaid } = useAppSelector(
    store => store.pointOfSale.cart.data
  );

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();
  const salesPoc = useAppSelector(store => store.checkout.salesPoc);
  const sourceForOnlinePos = useAppSelector(store => store.checkout.sourceForOnlinePos);

  /// change this to useAppSelector
  const hasSalesOrders = useAppSelector(store =>
    Object.values(store.pointOfSale.cart.data.cartItems.records).some(
      item => item.fulfillmentMode !== 'ONHAND'
    )
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCartMinAmountToBePaid(minAmountToBePaid ?? 0));
    dispatch(setCartTotalAmountPaid(amountPaid ?? 0));
    dispatch(setCartTotalAmountToBePaid(chargeableAmount ?? 0));
  }, [chargeableAmount, amountPaid, minAmountToBePaid]);

  const isDisabled = !salesPoc || (isSelectedSalesChannelOnline && !sourceForOnlinePos);

  const disabledReason = !salesPoc
    ? 'Please Choose a Sales POC'
    : isSelectedSalesChannelOnline && !sourceForOnlinePos
    ? 'Please Choose a Source for Online POS'
    : '';

  return (
    <Styles.Wrapper>
      <Styles.Left>
        <PaymentCollection
          {...(isDisabled
            ? { isDisabled: true, disabledReason }
            : {
                isDisabled: false,
              })}
        />
        {hasSalesOrders && <MinimumCollections />}
      </Styles.Left>
      <Styles.Right>
        <Parties />
        <Summary />
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default Checkout;
