import ReactSelect, { Props as SelectProps, GroupBase, StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';
import { PORTAL_ROOT_ID } from '../Portal';

// Types for the component props
interface SelectWrapperProps<Option, IsMulti extends boolean = false> {
  label?: string;
  options: SelectProps<Option, IsMulti>['options'];
  value: SelectProps<Option, IsMulti>['value'];
  onChange: SelectProps<Option, IsMulti>['onChange'];
  isMulti?: IsMulti;
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  error?: string;
  name?: string;
  isSearchable?: boolean;
  className?: string;
}

// Styled components
const SelectContainer = styled.div<{ error?: boolean }>`
  margin: 10px 0;
  position: relative;

  .react-select--is-disabled .react-select__control {
    background: var(--titan-white);
  }

  ${props =>
    props.error &&
    css`
      .react-select__control {
        border-color: red !important;
        box-shadow: 0 0 0 1px red !important;
      }
    `}
`;

const Label = styled.label<{ disabled?: boolean }>`
  font-size: 14px;
  color: ${props => (props.disabled ? '#aaa' : '#333')};
  margin-bottom: 5px;
  display: block;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

// Custom styles for react-select
const customStyles: StylesConfig<any, boolean> = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'var(--royal-blue)' : base.borderColor,
    '&:hover': {
      borderColor: 'var(--royal-blue)',
    },
  }),
  menu: base => ({
    ...base,
  }),
  menuPortal: base => ({ ...base, zIndex: 30 }),
};

const SelectWrapper = <Option, IsMulti extends boolean = false>({
  label,
  options,
  value,
  onChange,
  isMulti = false as IsMulti,
  isDisabled = false,
  isClearable = false,
  isLoading = false,
  placeholder = 'Select an option...',
  isSearchable = false,
  error,
  name,
  className,
}: SelectWrapperProps<Option, IsMulti>) => {
  return (
    <SelectContainer error={!!error} className={className}>
      {label && <Label disabled={isDisabled}>{label}</Label>}
      <ReactSelect<Option, IsMulti, GroupBase<Option>>
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        name={name}
        classNamePrefix="react-select"
        styles={customStyles}
        isSearchable={isSearchable}
        theme={theme => ({
          ...theme,
          borderRadius: 1,
          colors: {
            ...theme.colors,
            primary: 'var(--royal-blue)',
          },
        })}
        menuPortalTarget={document.getElementById(PORTAL_ROOT_ID)}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SelectContainer>
  );
};

export default SelectWrapper;
