import { Button, Input, RadioGroup } from '@point-of-sale/components';
import { addTransactionToCart, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useEffect, useState } from 'react';
import { useBoolean } from '@point-of-sale/hooks';
import { emptyFunction, formatNumberToLocale } from '@point-of-sale/utils';
import PaymentStatusIndicator from '../PaymentStatusIndicator';
import * as Styles from './styles';
import { PropsWithRemainingAmount } from '../types';
import toast from 'react-hot-toast';
import ManualPaymentStatusIndicator from '../ManualPaymentStatusIndicator';

const UnifiedPaymentsInterface = ({ remainingAmount }: PropsWithRemainingAmount) => {
  const salesPoc = useAppSelector(store => store.checkout.salesPoc);
  const paymentOptions = useAppSelector(store => store.checkout.paymentOptions.data);

  const isLoading = useAppSelector(store => store.checkout.cartTransactions.isLoading);

  const [isStatusModalOpen, statusModalOpenActions] = useBoolean();
  const [transactionId, setTransactionId] = useState<number>();

  const [isManualPaymentStatusModalOpen, manualPaymentStatusModalOpen] = useBoolean();
  const [manualPaymentStatus, setManualPaymentStatus] = useState<'PENDING' | 'SUCCESS' | 'FAILED'>(
    'PENDING'
  );

  const [requestValue, setRequestValue] = useState<string>(String(remainingAmount));
  const [paymentTerminal, setPaymentTerminal] = useState('');

  const [requestValueForModal, setRequestValueForModal] = useState<string>();

  const [remarks, setRemarks] = useState('');

  const paymentTerminals = Object.values(paymentOptions.records).find(
    item => item.gateway === 'PINE_LABS'
  )?.paymentTerminals;

  const dispatch = useAppDispatch();

  const onRequestMoney = () => {
    if (paymentTerminal === 'MANUAL' && (!remarks || remarks.trim() === '')) {
      toast.error('Please enter remarks to proceed');
      return;
    }

    if (paymentTerminal === 'MANUAL') {
      manualPaymentStatusModalOpen.on();
      setManualPaymentStatus('PENDING');
    }

    const payload =
      paymentTerminal === 'MANUAL'
        ? {
            paymentGateway: 'MANUAL',
            amount: Number(requestValue),
            paymentMode: 'UPI',
            remarks: remarks.trim(),
          }
        : {
            paymentGateway: 'PINE_LABS',
            amount: Number(requestValue),
            paymentTerminalId: Number(paymentTerminal),
            paymentMode: 'UPI',
          };

    setRequestValueForModal(formatNumberToLocale(Number(requestValue)));

    const successCallback =
      paymentTerminal === 'MANUAL'
        ? () => {
            setManualPaymentStatus('SUCCESS');
          }
        : (newTransactionId: number) => {
            setTransactionId(newTransactionId);
            // setPaymentTerminal('');
            statusModalOpenActions.on();
          };

    const failCallback =
      paymentTerminal === 'MANUAL'
        ? () => {
            setManualPaymentStatus('FAILED');
          }
        : emptyFunction;

    dispatch(addTransactionToCart(payload, { successCallback, failCallback }));
  };

  useEffect(() => {
    setRequestValue(String(remainingAmount));
  }, [remainingAmount]);

  const paymentTerminalOptions = [
    ...(paymentTerminals?.map(item => ({
      label: item.displayName,
      value: String(item.id),
    })) ?? []),
    {
      value: 'MANUAL',
      label: 'Manual',
    },
  ];

  return (
    <Styles.Wrapper>
      <RadioGroup
        name="options"
        options={paymentTerminalOptions}
        selectedValue={paymentTerminal}
        onChange={selectedValue => {
          if (selectedValue === 'MANUAL') {
            setPaymentTerminal(selectedValue);
            setRequestValue(String(remainingAmount));
            setRemarks('');
            return;
          }

          const newPaymentTerminal = paymentTerminalOptions.find(
            item => String(item.value) === String(selectedValue)
          );

          if (newPaymentTerminal) {
            setPaymentTerminal(String(newPaymentTerminal.value));
            setRequestValue(String(remainingAmount));
            setRemarks('');
          }
        }}
      />
      <Input
        variant="outline"
        sizeVariant="small"
        type="number"
        label={paymentTerminal === 'MANUAL' ? 'Add Amount *' : 'Enter Request Amount *'}
        placeholder={paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Enter request amount'}
        isFullWidth
        value={requestValue}
        onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
        onChange={e => {
          if (Number(e.target.value) < 0) {
            return;
          }

          if (Number(e.target.value) > remainingAmount) {
            return;
          }

          setRequestValue(e.target.value);
        }}
      />
      {paymentTerminal === 'MANUAL' && (
        <Input
          sizeVariant="small"
          variant="outline"
          label="Remarks *"
          placeholder="UPI ID, Phone Number, etc."
          value={remarks}
          onChange={event => {
            setRemarks(event.target.value);
          }}
        />
      )}
      <Styles.ButtonGroup>
        <Button
          variant="outline"
          isLoading={isLoading}
          onClick={onRequestMoney}
          isDisabled={!requestValue || !paymentTerminal || !salesPoc}
        >
          {paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Request'}
        </Button>
        <PaymentStatusIndicator
          transactionId={transactionId}
          isOpen={isStatusModalOpen}
          onClose={statusModalOpenActions.off}
        />
        <ManualPaymentStatusIndicator
          paymentMode="UPI"
          requestValue={requestValueForModal}
          status={manualPaymentStatus}
          isOpen={isManualPaymentStatusModalOpen}
          onClose={() => {
            // setRequestValue(String(remainingAmount));
            setRemarks('');
            manualPaymentStatusModalOpen.off();
          }}
        />
      </Styles.ButtonGroup>
    </Styles.Wrapper>
  );
};

export default UnifiedPaymentsInterface;
