import styled from 'styled-components';
import { NoImageIcon } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { Ribbon } from '../../../Ribbon';

const StyledImage = styled.div`
  height: 90px;
  width: 60px;
  position: relative;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const IconWrapper = styled.div`
  border: 1px solid var(--alto);
  height: 90px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1px;
`;

interface IImageProps {
  src: string | undefined;
  // isBuyNow: boolean;
  isMTO: boolean;
  isMTC: boolean;
  isJIT: boolean;
  label: string;
}

const Image = ({ src, label, isMTC, isMTO, isJIT }: IImageProps) => {
  const [isImageValid, imageValidActions] = useBoolean(true);

  const isMadeToOrder = isMTC || isMTO || isJIT;

  return (
    <StyledImage>
      {src && isImageValid ? (
        <img
          src={src ?? ''}
          alt="Product"
          onError={() => {
            imageValidActions.off();
          }}
        />
      ) : (
        <IconWrapper>
          <NoImageIcon size={28} />
        </IconWrapper>
      )}

      <Ribbon
        $background={isMadeToOrder ? 'var(--chinese-purple)' : 'var(--royal-blue)'}
        $position="bottom"
      >
        {isJIT ? 'JIT - ' : ''}
        {label}
      </Ribbon>
    </StyledImage>
  );
};

export default Image;
