import { useEffect, useState } from 'react';
import { IOption } from '@point-of-sale/components';
import { DiscoveryContext } from './context';
import Filters from './Filters';
import SelectedFilters from './SelectedFilters';
import * as Styles from './styles';

export interface IFilter {
  name: string;
  placeholder: string;
  options: Array<IOption>;
  values:
    | Array<string>
    | Array<number>
    | {
        from: number;
        to: number;
      };
  type?: 'MULTISELECT' | 'DATE_RANGE' | 'SINGLE_SELECT';
}

interface IDiscoveryProps {
  searchQuery: string;
  onPressEnter?: (search: string) => void;
  filters: Array<IFilter>;
  customInput?: React.ReactNode;
  onApply: (filtersToBeApplied: Record<string, any>) => void;
  onClearAll: () => void;
  // onClearSingleFilter: (filter: IPosFilter) => void;
}

const Discovery = ({
  searchQuery,
  onPressEnter,
  filters,
  onApply,
  onClearAll,
  customInput,
}: // onClearSingleFilter,
IDiscoveryProps) => {
  const [search, setSearch] = useState('');

  const [filtersToBeApplied, setFiltersToBeApplied] = useState({});

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  const [selectedFilterName, setSelectedFilterName] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedFilterName && filters.length > 0) {
      setSelectedFilterName(filters[0].name);
    }
  }, [filters]);

  return (
    <DiscoveryContext.Provider
      value={{
        filters,
        selectedFilterName,
        setSelectedFilterName,
        filtersToBeApplied,
        setFiltersToBeApplied,
      }}
    >
      <Styles.Wrapper>
        <Styles.Row>
          <Styles.SearchInput
            // placeholder={placeholder}
            value={search}
            autoFocus
            type="search"
            enterKeyHint="search"
            onChange={e => setSearch(e.target.value)}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                onPressEnter?.(search);
              }
            }}
          />

          {customInput}

          <Filters
            onApply={() => onApply(filtersToBeApplied)}
            onClearAll={() => {
              setFiltersToBeApplied({});
              onClearAll();
            }}
          />
        </Styles.Row>

        <SelectedFilters onClick={onApply} />
      </Styles.Wrapper>
    </DiscoveryContext.Provider>
  );
};

export default Discovery;
