import { motion } from 'motion/react';
import styled, { css, keyframes } from 'styled-components';
import { MULTI_COLORS } from './constants';

export const Wrapper = styled(motion.div)`
  /* border: 1px solid #ccc; */
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectableWrapper = styled.ul`
  display: flex;
`;

interface ISelectableProps {
  $isSelected: boolean;
  $isDisabled: boolean;
}

export const Selectable = styled.li<ISelectableProps>`
  height: 45px;
  /* min-width: 45px; */
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.5px solid var(--alto);
  border-bottom: 0.5px solid var(--alto);
  border-left: 0.5px solid var(--alto);
  cursor: pointer;

  &:last-child {
    border-right: 0.5px solid var(--alto);
  }

  ${props =>
    props.$isSelected &&
    css`
      border: 1.5px solid var(--black);

      &:last-child {
        border-right: 1.5px solid var(--black);
      }
    `}

  ${props =>
    props.$isDisabled &&
    css`
      background: var(--alabaster);
      cursor: not-allowed;
    `}
`;

interface ISelectableFillProps {
  $backgroundColor: string;
  $isDisabled: boolean;
}

const animateStripes = keyframes`
 0% {
        background-position: 0 0;
      }
      100% {
        background-position: -300% 0;
      }
`;

export const SelectableFill = styled.div<ISelectableFillProps>`
  ${props =>
    props.$backgroundColor === 'Multicolor'
      ? css`
          background-size: 300% 100%;
          background-position: 0 0;
          background-image: linear-gradient(to right, ${MULTI_COLORS.join(',')});
          animation: ${animateStripes} 6s linear infinite;
        `
      : css`
          background-color: ${props.$backgroundColor};
        `}

  ${props =>
    props.$backgroundColor.toLowerCase() === '#ffffff' &&
    css`
      border: 1px solid var(--athens-gray);
    `}


  height: 25px;
  width: 25px;
  display: inline-block;

  ${props =>
    props.$isDisabled &&
    css`
      opacity: 0.6;
    `};
`;
