export const categorizeOptions = <T, K extends keyof T>(
  options: T[],
  key: K
): Record<string, T[]> => {
  return options.reduce((acc, option) => {
    const firstLetter = String(option[key])[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(option);
    return acc;
  }, {} as Record<string, T[]>);
};
