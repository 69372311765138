import styled, { css } from 'styled-components';
import Text from '../Typography/Text';

interface ICheckBoxStyleProps {
  $isChecked: boolean;
  $isDisabled?: boolean;
}

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const Input = styled.input`
  width: 0px;
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

export const LabelText = styled(Text)<ICheckBoxStyleProps>`
  user-select: none;
  white-space: nowrap;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => (props.$isDisabled ? 'var(--dove-gray)' : 'var(--cod-gray)')};
`;

const getAtRestStyles = (isChecked: boolean) => ({
  border: `1px solid ${isChecked ? 'var(--royal-blue)' : 'var(--alto)'}`,
  background: isChecked ? 'var(--royal-blue)' : 'var(--white)',
});

const getDisabledStyles = (isChecked: boolean) => ({
  border: `1px solid var(--zumthor)`,
  background: isChecked ? 'var(--zumthor)' : 'var(--white)',
});

interface ISpanProps extends ICheckBoxStyleProps {
  $shape: 'round' | 'square';
}

export const Span = styled.span<ISpanProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  ${props =>
    props.$shape === 'round' &&
    css`
      border-radius: 50%;
    `}

  ${props =>
    props.$shape === 'square' &&
    css`
      border-radius: 1px;
    `}


  ${props =>
    props.$isDisabled ? getDisabledStyles(props.$isChecked) : getAtRestStyles(props.$isChecked)}
`;
