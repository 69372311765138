import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 8px 16px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: sticky;
  top: 0;
  z-index: 3;

  border-bottom: 1px solid var(--alto);
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
