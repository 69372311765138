import { ProductVariantDTOType } from '@point-of-sale/schemas';
import { IconButton, Text } from '@point-of-sale/components';
import { copyToClipboard } from '@point-of-sale/utils';
import * as Styles from './styles';

interface ICopyableSkuInfoProps {
  selectedVariant: ProductVariantDTOType | null;
  orientation?: 'horizontal' | 'vertical';
}

const CopyableSkuInfo = ({
  selectedVariant,
  orientation = 'horizontal',
}: ICopyableSkuInfoProps) => {
  if (!selectedVariant) {
    return null;
  }

  return (
    <Styles.SkuInfoRow $orientation={orientation}>
      <Styles.SkuInfo>
        <Text color="var(--dove-gray)" fontSize={12} width={'fit-content'}>
          SKU: &nbsp;
          <Text as="span" color="var(--cod-gray)" fontSize={12} textTransform="uppercase">
            {selectedVariant.skuCode}
          </Text>
        </Text>
        <IconButton
          icon="CopyIcon"
          sizeVariant="extraSmall"
          shapeVariant="circle"
          onClick={() => {
            copyToClipboard(selectedVariant.skuCode);
          }}
        />
      </Styles.SkuInfo>
      <Styles.SkuInfo>
        <Text color="var(--dove-gray)" fontSize={12} width={'fit-content'}>
          Barcode: &nbsp;
          <Text as="span" color="var(--cod-gray)" fontSize={12}>
            {selectedVariant.barcode}
          </Text>
        </Text>
        <IconButton
          icon="CopyIcon"
          sizeVariant="extraSmall"
          shapeVariant="circle"
          onClick={() => {
            copyToClipboard(selectedVariant.barcode);
          }}
        />
      </Styles.SkuInfo>
    </Styles.SkuInfoRow>
  );
};

export default CopyableSkuInfo;
