import { useEffect, useMemo } from 'react';
import { EmptyCart } from '@runway/illustrations';
import { Button, Skeleton, Text } from '@point-of-sale/components';
import {
  clearCartById,
  getCurrentStoreAndWarehouseFacilityIds,
  setServiceabilityForCart,
  updateCartItemEditCache,
  updateMultipleCartItemDestinations,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { formatNumberToLocale, openInSameTab } from '@point-of-sale/utils';
import CartItem from './CartItem';
import CheckoutButton from './CheckoutButton';
import useGetServiceability from '../Product/useGetServiceability';
import { areAllValuesEmptyStrings } from './helpers';
import RecoverCartButton from './RecoverCartButton';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { CartUsageType } from './types';
import { CartContext } from './context';
import { shallowEqual } from 'react-redux';
import { addDays, isSameDay } from 'date-fns';
import * as Styles from './styles';

interface ICartProps {
  usage: CartUsageType;
}

const Cart = ({ usage }: ICartProps) => {
  const mode = useAppSelector(state => state.pointOfSale.mode);

  const facilityRecords = useAppSelector(state => state.common.facilities.data.records);

  const isCartLoading = useAppSelector(state => state.pointOfSale.cart.isLoading);
  const cartId = useAppSelector(state => state.pointOfSale.cart.data.id);
  const cartCurrency = useAppSelector(store => store.pointOfSale.cart.data.currency);

  const allCustomerAddressIds = useAppSelector(
    state => state.pointOfSale.addresses.data.ids,
    shallowEqual
  );
  const allCustomerAddressRecords = useAppSelector(
    store => store.pointOfSale.addresses.data.records,
    shallowEqual
  );
  const selectedCustomerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const allCartItemIds = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.ids,
    shallowEqual
  );
  const cartItemsRecords = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.records,
    shallowEqual
  );
  const chargeableAmount = useAppSelector(state => state.pointOfSale.cart.data.chargeableAmount);

  const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

  const storePurchaseItemsWithoutAlteration = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        state.pointOfSale.cart.data.cartItems.records[id].deliveryMode === 'STORE_PURCHASE' &&
        state.pointOfSale.cart.data.cartItems.records[id].fulfillmentMode === 'ONHAND'
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const storePurchaseItemsWithoutAlterationQuantity = storePurchaseItemsWithoutAlteration.reduce(
    (acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    },
    0
  );

  const storePurchaseItemsWithAlteration = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        currentStoreAndWarehousesFacilityIds.includes(
          state.pointOfSale.cart.data.cartItems.records[id].fulfillmentFacilityId
        ) && !areAllValuesEmptyStrings(cartItemsRecords[id].alterationEntry)
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const storePurchaseItemsWithAlterationQuantity = storePurchaseItemsWithAlteration.reduce(
    (acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    },
    0
  );

  const readyMadeItemsFromOtherStoreOrWarehouse = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        state.pointOfSale.cart.data.cartItems.records[id].deliveryMode !== 'STORE_PURCHASE' &&
        cartItemsRecords[id].fulfillmentMode === 'ONHAND' &&
        !currentStoreAndWarehousesFacilityIds.includes(
          state.pointOfSale.cart.data.cartItems.records[id].fulfillmentFacilityId
        )
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const readyMadeItemsFromOtherStoreOrWarehouseQuantity =
    readyMadeItemsFromOtherStoreOrWarehouse.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const jitOrMtoOrMtcItems = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(id =>
      ['JIT', 'MTO', 'MTC'].includes(
        state.pointOfSale.cart.data.cartItems.records[id].fulfillmentMode
      )
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const jitOrMtoOrMtcItemsQuantity = jitOrMtoOrMtcItems.reduce((acc, id) => {
    return acc + cartItemsRecords[id].quantity;
  }, 0);

  const dispatch = useAppDispatch();

  const { serviceabilityData, fetchServiceabilityData } = useGetServiceability();

  const customer = useAppSelector(store => store.pointOfSale.customer.data);

  // TODO: Revisit this
  useEffect(() => {
    if (usage !== 'ADD_PRODUCTS') {
      return;
    }

    if (allCartItemIds.length > 0) {
      const skuCodes = Object.values(cartItemsRecords).map(item => item.skuCode);
      const destinationPincodes = Object.values(facilityRecords)
        .map(facility => {
          if (facility.facilityType !== 'STORE') {
            return null;
          }

          return facility.address.pincode;
        })
        .filter(item => item !== null);

      if (allCustomerAddressIds.length > 0) {
        destinationPincodes.push(
          ...allCustomerAddressIds.map(id => allCustomerAddressRecords[id].pincode)
        );
      }

      if (selectedCustomerAddress) {
        destinationPincodes.push(selectedCustomerAddress.pincode);
      }

      fetchServiceabilityData(
        skuCodes,
        // @ts-expect-error destinationPincodes will not have undefined
        Array.from(new Set(destinationPincodes)),
        Object.values(facilityRecords)
      );
    }
  }, [allCartItemIds, selectedCustomerAddress, allCustomerAddressIds, customer]);

  const itemCount = useMemo(() => {
    return allCartItemIds.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);
  }, [allCartItemIds, cartItemsRecords]);

  useEffect(() => {
    if (allCartItemIds.length === 0) {
      return;
    }

    if (usage !== 'ADD_PRODUCTS') {
      return;
    }

    dispatch(setServiceabilityForCart(serviceabilityData));

    const cartItemToBeUpdated: Array<number> = [];

    allCartItemIds.forEach(id => {
      const record = cartItemsRecords[id];

      if (
        record.deliveryMode !== 'HOME_DELIVERY' ||
        !serviceabilityData[record.skuCode] ||
        !record.promiseDate ||
        !selectedCustomerAddress?.pincode
      ) {
        return;
      }

      const itemServiceabilityData = serviceabilityData[record.skuCode].find(
        s => s.to === selectedCustomerAddress?.pincode
      );

      let supposedPromiseDate: number | string | Date = '';

      const isMTO = record.fulfillmentMode === 'MTO';
      const isMTC = record.fulfillmentMode === 'MTC';
      const isJit = record.fulfillmentMode === 'JIT';

      if (isMTO) {
        supposedPromiseDate = itemServiceabilityData?.info.mtoTat
          ? addDays(new Date(), itemServiceabilityData?.info.mtoTat)
          : '';
      } else if (isMTC) {
        supposedPromiseDate = itemServiceabilityData?.info.mtcTat
          ? addDays(new Date(), itemServiceabilityData?.info.mtcTat)
          : '';
      } else if (itemServiceabilityData?.info.express) {
        supposedPromiseDate = 'EXPRESS';
      } else {
        supposedPromiseDate = itemServiceabilityData?.info.bestTat
          ? addDays(new Date(), itemServiceabilityData?.info.bestTat)
          : '';
      }

      if (!isSameDay(new Date(record.promiseDate), new Date(supposedPromiseDate))) {
        cartItemToBeUpdated.push(id);
      }
    });

    dispatch(
      updateMultipleCartItemDestinations(
        {
          cartItemIds: cartItemToBeUpdated,
          shouldCalculatePromiseDate: true,
          deliveryMode: 'HOME_DELIVERY',
        },
        serviceabilityData
      )
    );
  }, [serviceabilityData]);

  const currency = CURRENCY_SYMBOLS[(cartCurrency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  return (
    <CartContext.Provider
      value={{
        rawServiceabilityData: serviceabilityData,
      }}
    >
      <Styles.Wrapper>
        <Styles.Header>
          <Styles.HeaderLeft>
            {isCartLoading ? (
              <>
                <Skeleton height={18} width={200} />
                <Skeleton height={22} width={250} />
              </>
            ) : (
              <>
                <Text fontSize={14} color="var(--dove-gray)">
                  {cartId === -1 ? <>...</> : <>Cart #{cartId}</>}
                </Text>
                <Text fontSize={16} color="var(--cod-gray)" weight="semibold">
                  Total Cart Value {currency} {formatNumberToLocale(chargeableAmount ?? 0)}
                </Text>
              </>
            )}
          </Styles.HeaderLeft>
          <Styles.HeaderRight>
            {usage === 'ADD_PRODUCTS' && (
              <Button
                onClick={() => {
                  dispatch(clearCartById(String(cartId)));
                  dispatch(
                    updateCartItemEditCache({
                      id: -1,
                      isPopulated: false,
                    })
                  );
                }}
                variant="ghost"
                colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
                sizeVariant="xs"
              >
                CLEAR
              </Button>
            )}

            {usage === 'CHECKOUT' && (
              <Button
                variant="ghost"
                sizeVariant="xs"
                onClick={() => {
                  openInSameTab(`/point-of-sale/cart?id=${cartId}`);
                }}
              >
                ADD MORE
              </Button>
            )}

            <Text fontSize={14} weight="bold" color="var(--dove-gray)">
              No of Items: {itemCount}
            </Text>
          </Styles.HeaderRight>
        </Styles.Header>
        <Styles.Content>
          {allCartItemIds.length === 0 && (
            <EmptyCart
              text={
                usage === 'RECOVER'
                  ? cartId === -1
                    ? 'Please Select a cart'
                    : 'Cart has no items'
                  : 'Your cart is empty'
              }
            />
          )}

          {storePurchaseItemsWithoutAlteration.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Store Purchase Items ({storePurchaseItemsWithoutAlterationQuantity})
              </Styles.CartSectionHeading>
              {storePurchaseItemsWithoutAlteration.map(id => (
                <CartItem
                  key={id}
                  data={cartItemsRecords[id]}
                  currency={cartCurrency ?? ''}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                />
              ))}
            </Styles.CartSection>
          )}
          {storePurchaseItemsWithAlteration.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Store Purchase Items With Alterations ({storePurchaseItemsWithAlterationQuantity})
              </Styles.CartSectionHeading>
              {storePurchaseItemsWithAlteration.map(id => (
                <CartItem
                  key={id}
                  data={cartItemsRecords[id]}
                  currency={cartCurrency ?? ''}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                />
              ))}
            </Styles.CartSection>
          )}
          {readyMadeItemsFromOtherStoreOrWarehouse.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Other Locations ({readyMadeItemsFromOtherStoreOrWarehouseQuantity})
              </Styles.CartSectionHeading>
              {readyMadeItemsFromOtherStoreOrWarehouse.map(id => (
                <CartItem
                  key={id}
                  data={cartItemsRecords[id]}
                  currency={cartCurrency ?? ''}
                  destinationGroupIds={readyMadeItemsFromOtherStoreOrWarehouse}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                  shouldBlockAlteration={!isSelectedSalesChannelOnline}
                />
              ))}
            </Styles.CartSection>
          )}
          {jitOrMtoOrMtcItems.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Made To Order ({jitOrMtoOrMtcItemsQuantity})
              </Styles.CartSectionHeading>
              {jitOrMtoOrMtcItems.map(id => (
                <CartItem
                  key={id}
                  data={cartItemsRecords[id]}
                  currency={cartCurrency ?? ''}
                  destinationGroupIds={jitOrMtoOrMtcItems}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                />
              ))}
            </Styles.CartSection>
          )}
        </Styles.Content>
        {usage !== 'CHECKOUT' && (
          <Styles.Footer>
            <Styles.FooterBottom>
              {usage === 'ADD_PRODUCTS' && (
                <CheckoutButton
                  chargeableAmount={`${currency} ${formatNumberToLocale(chargeableAmount ?? 0)}`}
                />
              )}
              {usage === 'RECOVER' && <RecoverCartButton />}
            </Styles.FooterBottom>
          </Styles.Footer>
        )}
      </Styles.Wrapper>
    </CartContext.Provider>
  );
};

export default Cart;
