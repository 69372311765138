import { useEffect, useState } from 'react';
import {
  getStorePickups,
  markPackageDelivered,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { formatToReadableDate, titleCase } from '@point-of-sale/utils';
import { Button, Capsule, IconButton, Text } from '@point-of-sale/components';
import { useBoolean, useFetchOnListEnd } from '@point-of-sale/hooks';
import { CURRENCY_SYMBOLS, PLACEHOLDERS } from '@point-of-sale/constants';
import CollectPayment from './CollectPayment';
import ImageGallery from '../ImageGallery';
import * as TableStyles from '../Table/styles';
import RejectPickup from './RejectPickup';

const PendingStorePickupsTable = () => {
  const { data, isLoading } = useAppSelector(store => store.pendingTasks.storePickups);
  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);

  const [loaders, setLoaders] = useState<Record<number, boolean>>({});

  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(null);

  const [isPaymentsModalOpen, paymentModalOpenActions] = useBoolean();

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(getStorePickups());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  useEffect(() => {
    dispatch(getStorePickups());
  }, []);

  useEffect(() => {
    if (data.ids.length === 0) {
      return;
    }

    const newLoaders = data.ids.reduce((acc, id) => {
      acc[id] = false;
      return acc;
    }, {} as Record<number, boolean>);

    setLoaders(newLoaders);
  }, [data.ids]);

  return (
    <TableStyles.Wrapper>
      <TableStyles.Table>
        <TableStyles.Thead>
          <TableStyles.TableHeadingRow>
            <TableStyles.TableHeader>Items</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Number</TableStyles.TableHeader>
            <TableStyles.TableHeader>Customer Details</TableStyles.TableHeader>
            <TableStyles.TableHeader>Estimated Pickup</TableStyles.TableHeader>
            <TableStyles.TableHeader>Channel</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Type</TableStyles.TableHeader>
            <TableStyles.TableHeader>Billing Location</TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
          </TableStyles.TableHeadingRow>
        </TableStyles.Thead>
        <tbody>
          {data?.ids.map((id, index) => {
            const record = data.records[id];

            const isPaymentPending = record.costBreakup.amountRemaining
              ? record.costBreakup.amountRemaining > 0
              : false;

            const currencySymbol =
              CURRENCY_SYMBOLS[
                (record.costBreakup.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR'
              ]?.code;

            const images = Object.values(record.orderLineItems ?? {}).map(item => ({
              src: item.productImage?.url,
              text: item.productName,
              subText: item.brandName,
            }));

            const isRto = record.currentStatus === 'RTO';

            return (
              <TableStyles.TableRow
                key={id}
                $isSelected={false}
                {...(index === data.ids.length - 3 ? { ref: lastItemRef } : {})}
              >
                <TableStyles.TableData>
                  <ImageGallery heading="Product Gallery" images={[...images]} />
                </TableStyles.TableData>
                <TableStyles.TableData>{record.salesChannelOrderId}</TableStyles.TableData>
                <TableStyles.TableData>
                  <Text>
                    {record.customerName ? (
                      <>
                        {record.customerName}
                        <br />
                        {record.customerPhone}
                      </>
                    ) : (
                      'Guest'
                    )}
                  </Text>
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {record.estimatedDeliveryDate
                    ? formatToReadableDate(record.estimatedDeliveryDate)
                    : PLACEHOLDERS.DOUBLE_DASH}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {titleCase(facilityRecords[record.salesFacilityId]?.facilityType)}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  <Capsule
                    essence="light"
                    complexion={record.fulfillmentMode === 'ONHAND' ? 'accent' : 'info'}
                    size="small"
                    content={record.fulfillmentMode}
                  />
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {facilityRecords[record.salesFacilityId]?.name}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {!isRto && (
                    <Text color="var(--alizarin-crimson)">
                      {record.costBreakup.amountRemaining
                        ? record.costBreakup.amountRemaining > 0 &&
                          `Balance: ${currencySymbol} ${record.costBreakup.amountRemaining}`
                        : ''}
                    </Text>
                  )}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {!isRto && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {isPaymentPending ? (
                        <Button
                          sizeVariant="xs"
                          variant="outline"
                          isLoading={loaders[record.id]}
                          onClick={() => {
                            setSelectedPackageId(record.id);
                            paymentModalOpenActions.on();
                          }}
                        >
                          Collect Payment
                        </Button>
                      ) : (
                        <Button
                          sizeVariant="xs"
                          variant="outline"
                          isLoading={loaders[record.id]}
                          onClick={() => {
                            // TODO: Add a date picker here
                            setLoaders(prev => ({
                              ...prev,
                              [record.id]: true,
                            }));
                            dispatch(
                              markPackageDelivered(record.id, () => {
                                setLoaders(prev => ({
                                  ...prev,
                                  [record.id]: false,
                                }));
                              })
                            );
                          }}
                        >
                          Mark as Complete
                        </Button>
                      )}
                    </>
                  )}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  {!isRto && <RejectPickup record={record} />}
                </TableStyles.TableData>
              </TableStyles.TableRow>
            );
          })}
        </tbody>
      </TableStyles.Table>
      {selectedPackageId && (
        <CollectPayment
          packageId={selectedPackageId}
          isOpen={isPaymentsModalOpen}
          onClose={paymentModalOpenActions.off}
        />
      )}
    </TableStyles.Wrapper>
  );
};

export default PendingStorePickupsTable;
