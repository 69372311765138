import { Fragment, useEffect } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { BrowserRouter, Route, Routes } from 'react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import {
  DesignSystemProvider,
  ErrorFallback,
  MainErrorBoundary,
  RouteProtector,
  posThemeTokens,
} from '@point-of-sale/components';
import { persistor, store } from '@point-of-sale/store';
import Login from '../pages/shared/Login';
import { useIsOnline } from '@point-of-sale/hooks';
import { SentryService } from '@point-of-sale/services';

import MobileRoutes from '../routes/Routes.mobile';
import DesktopRoutes from '../routes/Routes.desktop';
import { ScanditSDKProvider } from '@runway/barcode-scanner';

function App() {
  useIsOnline();

  useEffect(() => {
    SentryService.init();
  }, []);

  // * change the implementation of this
  const AccumulatedProvider = isDesktop ? Fragment : Fragment;
  // const AccumulatedProvider = isDesktop ? Fragment : ScanditSDKProvider;

  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AccumulatedProvider>
            <DesignSystemProvider themeTokens={posThemeTokens}>
              <Toaster
                position="top-center"
                toastOptions={{
                  style: {
                    wordBreak: 'break-all',
                  },
                }}
              />
              <MainErrorBoundary FallbackComponent={ErrorFallback}>
                <Routes>
                  <Route
                    path="*"
                    element={
                      <RouteProtector>
                        {isMobileOnly ? <MobileRoutes /> : <DesktopRoutes />}
                      </RouteProtector>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                </Routes>
              </MainErrorBoundary>
            </DesignSystemProvider>
          </AccumulatedProvider>
        </PersistGate>
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;
