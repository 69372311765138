import { motion } from 'motion/react';
import styled from 'styled-components';

export const VariantsInfoWrapper = styled.div`
  display: flex;
  gap: 64px;
`;

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  button {
    width: 250px;
  }

  /* align-items: flex-start; */
`;

export const Hr = styled.hr`
  background: var(--alto);
  margin: 0;
  border: none;
  height: 1px;
`;
