import styled from 'styled-components';
import { Select } from '@point-of-sale/components';
import { COUNTRIES } from '@point-of-sale/constants';
import { useMemo } from 'react';

export const StyledCountrySelector = styled(Select)`
  /* border: 1px solid var(--alto);  */
  padding: 0;
  margin: 0;
  display: flex;

  input {
    padding: 0;
  }

  > div {
    width: 100%;
  }

  .react-select__control {
    height: 100%;
  }
`;

interface ICountrySelectorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;

  isDisabled?: boolean;
}

const CountrySelector = ({ value, onChange, className, isDisabled }: ICountrySelectorProps) => {
  const countryOptions = useMemo(
    () =>
      COUNTRIES.map(country => ({
        label: `${country.flag} ${country.dial_code}`,
        value: country.dial_code,
      })),
    []
  );

  return (
    <StyledCountrySelector
      className={className}
      isSearchable
      placeholder="Country"
      options={countryOptions}
      value={countryOptions.find(option => option.value === value)}
      onChange={selectedOption => {
        // @ts-expect-error - selectedOption is an object
        onChange(String(selectedOption.value));
      }}
      isDisabled={isDisabled}
      // onClear={() => onChange('')}
      // defaultValue={'+91'}
    />
  );
};

export default CountrySelector;
