import { z } from 'zod';

export const CustomerDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  uidx: z.string(),
  // countryCode: z.string(),
  phone: z.string(),
  name: z.string(),
  email: z.string().optional(),
  referralCode: z.string(),
  status: z.string(),
  dob: z.string().optional(),
  gender: z.string().optional(),
  profileImageId: z.number().optional(),
  whatsappOptin: z.boolean().optional(),
  country: z.string().optional(),
});

export type CustomerDTOType = z.infer<typeof CustomerDTOSchema>;

export const PinCodeDetailsDTOSchema = z.object({
  pincode: z.string(),
  cityCode: z.string().optional(),
  cityDisplayName: z.string().optional(),
  stateCode: z.string().optional(),
  stateDisplayName: z.string().optional(),
  countryCode: z.string(),
  countryDisplayName: z.string(),
  lat: z.number(),
  lng: z.number(),
  localities: z.array(z.string()),
  formattedAddress: z.string(),
});

export type PinCodeDetailsDTOType = z.infer<typeof PinCodeDetailsDTOSchema>;

// Extend this for customer
export const AddressDTOSchema = z.object({
  id: z.number().optional(),
  customerId: z.number().optional(),
  label: z.string().optional(),
  name: z.string().optional(),
  phone: z.string().optional(),
  addressLine: z.string().optional(),
  locality: z.string().optional(),
  landmark: z.string().optional(),
  pincode: z.string().optional(),
  cityCode: z.string().optional(),
  cityDisplayName: z.string().optional(),
  stateCode: z.string().optional(),
  stateDisplayName: z.string().optional(),
  countryCode: z.string().optional(),
  countryDisplayName: z.string().optional(),
  lat: z.number().optional(),
  lng: z.number().optional(),
  isDefault: z.boolean().optional(),
});

export type AddressDTOType = z.infer<typeof AddressDTOSchema>;

export const UpsertAddressDTOSchema = z.object({
  label: z.string(),
  name: z.string(),
  addressLine: z.string(),
  pincode: z.string(),
  isDefault: z.string(),
  phone: z.string(),
  customerId: z.number().optional(),
  landmark: z.string().optional(),
  locality: z.string().optional(),
});

export type UpsertAddressDTOType = z.infer<typeof UpsertAddressDTOSchema>;
