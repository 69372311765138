import { useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { useBoolean } from '@point-of-sale/hooks';
import { Button, CoyuLogo, Text } from '@point-of-sale/components';
import { logout, useAppDispatch } from '@point-of-sale/store';
import { useReleaseIdentifier } from '../../../hooks/useReleaseIdentifier';
import { NAVIGATION_ITEMS } from './constants';
import * as Styles from './styles';

const Link = motion(Styles.Visitable);

// TODO: Refactor this component
const Navigation = () => {
  const [activeLink, setActiveLink] =
    useState<(typeof NAVIGATION_ITEMS)[number]['visitables'][number]['link']>('/');
  const [isExpanded, expandedActions] = useBoolean();

  const { releaseIdentifier } = useReleaseIdentifier();

  const dispatch = useAppDispatch();

  return (
    <>
      <Styles.Wrapper
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={{
          collapsed: { width: 70 },
          expanded: { width: 260 },
        }}
        transition={{
          duration: 0.2,
        }}
        onMouseEnter={expandedActions.on}
        onMouseLeave={expandedActions.off}
      >
        <Styles.Header
          variants={{
            collapsed: { padding: '0 20px' },
            expanded: { padding: '0 24px' },
          }}
        >
          <CoyuLogo mode={isExpanded ? 'LONG' : 'COMPACT'} fill="var(--white)" />
        </Styles.Header>

        <Styles.SectionWrapper>
          {NAVIGATION_ITEMS.map(item => (
            <Styles.Section key={item.section}>
              {item.visitables.map(visitable => {
                const Icon = visitable.icon;

                return (
                  // @ts-expect-error theme is available
                  <Link
                    key={visitable.title}
                    to={visitable.link}
                    $isActive={activeLink === visitable.link}
                    $isExpanded={isExpanded}
                    onClick={() => setActiveLink(visitable.link)}
                  >
                    <Icon
                      stroke={
                        activeLink === visitable.link ? 'var(--cod-gray)' : 'var(--dove-gray)'
                      }
                    />
                    {isExpanded && visitable.title}
                  </Link>
                );
              })}
            </Styles.Section>
          ))}

          {isExpanded && (
            <Styles.Footer>
              <Button
                onClick={() => {
                  dispatch(logout());
                }}
                variant="ghost"
                // sizeVariant=""
                colorScheme="dark"
                leftIcon="LogoutIcon"
                isFullWidth
              >
                Logout
              </Button>

              {releaseIdentifier && (
                <Text fontSize={10} color="var(--oslo-gray)">
                  v1.0.{releaseIdentifier}
                </Text>
              )}
            </Styles.Footer>
          )}
        </Styles.SectionWrapper>
      </Styles.Wrapper>
      <AnimatePresence>
        {isExpanded && (
          <Styles.Overlay
            key="overlay"
            initial={{ opacity: 0, left: 70, width: 'calc(100vw - 70px)' }}
            animate={{
              opacity: 1,
              left: 260,
              width: 'calc(100vw - 260px)',
              backdropFilter: 'blur(2px)',
              WebkitBackdropFilter: 'blur(2px)',
            }}
            // exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target === e.currentTarget) {
                expandedActions.off();
              }
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
