import { useEffect, useState } from 'react';
import { Button, Input, Text } from '@point-of-sale/components';
import toast from 'react-hot-toast';
import {
  addAddressToCustomer,
  assignCustomerBillingAddressToCart,
  getGstDetails,
  updateCartGstNumber,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import BillingAddress from './BillingAddress';
import { concatenateWithCommas } from '@point-of-sale/utils';
import * as Styles from './styles';

interface IGstDetailsProps {
  customerId: number;
}

const GstDetails = ({ customerId }: IGstDetailsProps) => {
  const { isLoading, data, isError } = useAppSelector(
    store => store.pointOfSale.customerGstDetails
  );

  const [billingAddressMode, setBillingAddressMode] = useState<'READ' | 'WRITE' | 'NONE'>('NONE');

  const [gst, setGst] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.gstin && !gst) {
      setGst(data.gstin);
      setBillingAddressMode('READ');
    }
  }, [data]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Input
          isFullWidth
          label="GST Number (optional)"
          placeholder="Enter Customer's GST"
          sizeVariant="extraSmall"
          variant="outline"
          name="gst"
          value={gst}
          onChange={e => setGst(e.target.value)}
        />
        <Button
          isLoading={isLoading}
          sizeVariant="xs"
          colorScheme="dark"
          variant="outline"
          onClick={() => {
            if (!gst) {
              toast.error('Enter a GST number to search');
              return;
            }

            dispatch(
              getGstDetails(gst, (verifiedGstNumber, isVerified, gstObject) => {
                dispatch(updateCartGstNumber(verifiedGstNumber));

                if (isVerified) {
                  // TODO: refactor
                  if (gstObject) {
                    dispatch(
                      addAddressToCustomer(
                        customerId,
                        {
                          customerId: customerId,
                          addressLine: concatenateWithCommas([
                            gstObject.principlePlaceOfBusiness?.address.buildingNumber,
                            gstObject.principlePlaceOfBusiness?.address.buildingName,
                            gstObject.principlePlaceOfBusiness?.address.floorNumber,
                            gstObject.principlePlaceOfBusiness?.address.street,
                            gstObject.principlePlaceOfBusiness?.address.location,
                          ]),
                          cityCode: gstObject.principlePlaceOfBusiness?.address.district,
                          cityDisplayName: gstObject.principlePlaceOfBusiness?.address.district,
                          label: `GST - ${gst}`,
                          locality: gstObject.principlePlaceOfBusiness?.address.locality,
                          pincode: gstObject.principlePlaceOfBusiness?.address.pinCode,
                          stateCode: gstObject.principlePlaceOfBusiness?.address.stateCode,
                          stateDisplayName: gstObject.principlePlaceOfBusiness?.address.stateCode,
                          name: gstObject.tradeName,
                          countryCode: 'IN',
                          countryDisplayName: 'India',
                        },
                        (newAddressId: number) => {
                          dispatch(assignCustomerBillingAddressToCart(newAddressId));
                        },
                        {
                          shouldMakeDeliveryAddress: false,
                        }
                      )
                    );
                  }

                  setBillingAddressMode('READ');
                } else {
                  setBillingAddressMode('WRITE');
                }
              })
            );
          }}
        >
          Verify
        </Button>
      </Styles.Row>

      {isError && (
        <Styles.Row>
          <Text fontSize={12} color="var(--web-orange)">
            Could not verify, Add billing address manually
          </Text>
        </Styles.Row>
      )}

      {billingAddressMode === 'READ' && !isError && (
        <Styles.Section>
          <Text fontSize={12} color="var(--dove-gray)">
            Billing Address
          </Text>
          <Styles.Row>
            <Text fontSize={14}>{data.legalName}</Text>
          </Styles.Row>
          <Styles.Row>
            <Text fontSize={14}>
              {data.principlePlaceOfBusiness?.address.buildingName}
              {data.principlePlaceOfBusiness?.address.buildingNumber},
              {data.principlePlaceOfBusiness?.address.pinCode},
              {data.principlePlaceOfBusiness?.address.stateCode}
            </Text>
          </Styles.Row>
        </Styles.Section>
      )}

      {isError && <BillingAddress customerId={customerId} />}
    </Styles.Wrapper>
  );
};

export default GstDetails;
