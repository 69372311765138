import styled from 'styled-components';

interface IRibbonProps {
  $position?: 'top' | 'bottom';
  $background?: 'var(--chinese-purple)' | 'var(--royal-blue)' | 'var(--chateau-green)';
}

export const Ribbon = styled.div<IRibbonProps>`
  --f: 5px; /* control the folded part*/
  --r: 3px; /* control the ribbon shape */

  position: absolute;

  ${props => (props.$position === 'top' ? 'top: 4px;' : 'bottom: 0;')}

  left: calc(-1 * var(--f));
  background: ${props => props.$background};
  border-bottom: var(--f) solid #0005;
  clip-path: polygon(
    calc(100% - var(--r)) 0,
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    100% 0
  );
  font-size: 10px;
  font-weight: 400;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;

Ribbon.defaultProps = {
  $position: 'bottom',
  $background: 'var(--royal-blue)',
};
