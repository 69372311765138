import Text from '../Typography/Text';
import { IColor } from './types';
import * as Styles from './styles';

interface IColorSelectorProps {
  availableColors: Array<IColor>;
  enabledColors: Array<IColor['name']>;
  onSelectColor: (color: IColor) => void;
  label?: string;
  selectedColor: IColor['id'] | null;
  shouldDisableZeroInventory?: boolean;
}

const ColorSelector = ({
  label,
  availableColors,
  onSelectColor,
  selectedColor,
  enabledColors,
}: IColorSelectorProps) => {
  return (
    <Styles.Wrapper
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      {label && (
        <Text as="label" weight="regular" fontSize={16} color="var(--cod-gray)">
          {label}
        </Text>
      )}
      <Styles.SelectableWrapper>
        {availableColors.map(color => {
          const isDisabled = enabledColors.length > 0 && !enabledColors.includes(color.name);

          return (
            <Styles.Selectable
              key={color.name}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                onSelectColor(color);
              }}
              $isSelected={selectedColor === color.id}
              $isDisabled={isDisabled}
            >
              {color.colorCodes.map((colorCode, index) => (
                <Styles.SelectableFill
                  key={colorCode}
                  $isDisabled={isDisabled}
                  $backgroundColor={
                    ['Multicolour', 'Multi', 'Multicolor'].includes(color.id)
                      ? 'Multicolor'
                      : colorCode
                  }
                />
              ))}
            </Styles.Selectable>
          );
        })}
      </Styles.SelectableWrapper>
    </Styles.Wrapper>
  );
};

export default ColorSelector;
