// eslint-disable-next-line @nx/enforce-module-boundaries
import { isEmptyObject } from '@point-of-sale/utils';
import { useAppSelector } from './useAppSelector';

export const useIsSelectedSalesChannelOnline = (): {
  isSelectedSalesChannelOnline: boolean;
  isLoadingFacilities: boolean;
} => {
  const selected = useAppSelector(store => store.identity.selectedSalesChannel);
  const isLoadingFacilities = useAppSelector(store => store.common.facilities.isLoading);
  const facilities = useAppSelector(store => store.common.facilities.data.records);

  if (isEmptyObject(facilities)) {
    return { isSelectedSalesChannelOnline: false, isLoadingFacilities: true };
  }

  if (!selected?.facilityId) {
    return { isSelectedSalesChannelOnline: false, isLoadingFacilities };
  }

  const selectedFacility = facilities[selected?.facilityId];

  return { isSelectedSalesChannelOnline: selectedFacility?.isOnline ?? false, isLoadingFacilities };
};
