import { useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'motion/react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import { ChevronRightIcon } from '../icons';
import { IOption } from './types';
import Text from '../Typography/Text';
import Button from '../Button';
import IconButton from '../IconButton';
import Skeletons from './Skeletons';
import * as Styles from './styles';
import { ICON_MOTION_VARIANTS } from './constants';

interface IMultiSelectProps {
  label: string;
  options: Array<IOption>;
  onChange: (values: Array<IOption>) => void;
  values: Array<string | number>;
  placeholder?: string;
  isSearchable?: boolean;
  className?: string;
  onClear?: () => void;
  isOutlined?: boolean;
  isLoading?: boolean;
  isSingleSelect?: boolean;
}

const MultiSelect = ({
  options,
  values,
  onChange,
  onClear,
  label,
  className = '',
  placeholder = 'Select',
  isSearchable = false,
  isOutlined = false,
  isLoading = false,
  isSingleSelect = false,
}: IMultiSelectProps) => {
  const [search, setSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Array<IOption>>([]);

  const [isSelectOpen, selectOpenActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      selectOpenActions.off();
    }
  });

  useEffect(() => {
    if (values.length) {
      setSelectedOptions(options.filter(option => values.includes(option.value)));
    } else {
      setSelectedOptions([]);
    }
  }, [values]);

  const listableOptions = isSearchable
    ? search
      ? options.filter(option => String(option.label).toLowerCase().includes(search.toLowerCase()))
      : options
    : options;

  return (
    <Styles.Wrapper className={className} ref={wrapperRef} $isOutlined={isOutlined}>
      <Styles.Trigger ref={ref => setReferenceElement(ref)} onClick={selectOpenActions.toggle}>
        <Text fontSize={16} color="var(--cod-gray)">
          {placeholder}
        </Text>
        <Styles.IconWrapper>
          <ChevronRightIcon
            animate={isSelectOpen ? 'open' : 'closed'}
            variants={ICON_MOTION_VARIANTS}
          />
        </Styles.IconWrapper>
      </Styles.Trigger>

      {createPortal(
        <AnimatePresence mode="wait">
          {isSelectOpen && (
            <Styles.PopoverContainer
              ref={ref => setPopperElement(ref)}
              style={{
                ...styles.popper,
                width: 600,
                height: 500,
              }}
              {...attributes.popper}
              onMouseEnter={clickOutsideAllowedActions.off}
              onMouseLeave={clickOutsideAllowedActions.on}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <Styles.PopoverHeader>
                <Text fontSize={16} weight="bold">
                  {label}
                </Text>
                {isSearchable && (
                  <Styles.SearchBox
                    sizeVariant="extraSmall"
                    variant="outline"
                    leftIcon="SearchIcon"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                )}
                {onClear && (
                  <Button
                    onClick={() => {
                      setSelectedOptions([]);
                      onClear();
                    }}
                    sizeVariant="xs"
                    variant="ghost"
                  >
                    Clear
                  </Button>
                )}
                <IconButton
                  className="close-button"
                  shapeVariant="circle"
                  icon="CrossIcon"
                  onClick={selectOpenActions.off}
                />
              </Styles.PopoverHeader>
              <Styles.OptionsGrid>
                {isLoading ? (
                  <Skeletons />
                ) : (
                  listableOptions?.map(option => (
                    <Styles.OptionCheckbox
                      key={`${option.label}--${option.value}`}
                      name={option.label as string}
                      // onChange={() => {
                      //   if (selectedOptions.map(item => item.value).includes(option.value)) {
                      //     setSelectedOptions(
                      //       selectedOptions.filter(
                      //         selectedOption => selectedOption.value !== option.value
                      //       )
                      //     );
                      //   } else {
                      //     setSelectedOptions([...selectedOptions, option]);
                      //   }
                      // }}
                      onChange={() => {
                        if (isSingleSelect) {
                          if (selectedOptions[0]?.value === option.value) {
                            setSelectedOptions([]);
                          } else {
                            setSelectedOptions([option]);
                          }
                        } else {
                          if (selectedOptions.map(item => item.value).includes(option.value)) {
                            setSelectedOptions(
                              selectedOptions.filter(
                                selectedOption => selectedOption.value !== option.value
                              )
                            );
                          } else {
                            setSelectedOptions([...selectedOptions, option]);
                          }
                        }
                      }}
                      isDisabled={option.isDisabled}
                      isChecked={selectedOptions.map(item => item.value).includes(option.value)}
                      label={option.label}
                    />
                  ))
                )}
              </Styles.OptionsGrid>
              <Styles.PopoverFooter>
                <Button
                  onClick={() => {
                    onChange(selectedOptions);
                    selectOpenActions.off();
                  }}
                >
                  APPLY
                </Button>
              </Styles.PopoverFooter>
            </Styles.PopoverContainer>
          )}
        </AnimatePresence>,
        document.body
      )}
    </Styles.Wrapper>
  );
};

export default MultiSelect;
