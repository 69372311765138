import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100dvh;
`;

// export const Left = styled.div`
//   min-width: 70px;
//   width: 70px;
// `;

// export const Right = styled.div`
//   flex: 1;
//   overflow: auto;
//   position: relative;
// `;
