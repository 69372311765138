import styled, { css } from 'styled-components';
import { motion } from 'motion/react';
import { ThemeType } from '../themes/types';
import { SelectSizeVariants } from './types';
import { SELECT_HEIGHTS } from './constants';

interface PropsWithSizeVariant {
  $sizeVariant: SelectSizeVariants;
}

type PropsWithSizeVariantAndTheme = PropsWithSizeVariant & { theme: ThemeType };

interface IWrapperProps extends PropsWithSizeVariant {
  $isOutlined: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  gap: 4px;
  position: relative;

  ${props =>
    props.$isOutlined &&
    css`
      border: 1px solid var(--alto);
      border-radius: 1px;
    `}

  height: ${props => SELECT_HEIGHTS[props.$sizeVariant]}px;
`;

export const InputWrapper = styled.input<PropsWithSizeVariantAndTheme>`
  flex: 1;
  outline: none;
  border: none;
  padding: 12px 16px;
  font-size: ${props => props.theme.typography.fonts.size[16]};

  &::placeholder {
    color: var(--alto);
    /* color: var(--dove-gray); */
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--white);
  padding: 0 12px;
  cursor: pointer;
`;

export const OptionsWrapper = styled(motion.div)`
  border-radius: 1px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  margin-top: 4px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 2;
`;

interface IOptionItemProps extends PropsWithSizeVariantAndTheme {
  $isDisabled?: boolean;
  $isSelected?: boolean;
}

export const OptionItem = styled(motion.span)<IOptionItemProps>`
  padding: 12px 16px;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
  font-size: ${props => props.theme.typography.fonts.size[14]};
  color: var(--cod-gray);
  user-select: none;

  ${props =>
    props.$isSelected &&
    css`
      color: var(--cod-gray);
      background-color: var(--zumthor);
      font-weight: ${props => props.theme.typography.fonts.weight.semibold};
    `}

  ${props =>
    props.$isDisabled
      ? css`
          cursor: not-allowed;
          color: var(--dove-gray);
        `
      : css`
          cursor: pointer;
        `}



  &:hover {
    ${props =>
      !props.$isDisabled &&
      !props.$isSelected &&
      css`
        background-color: var(--athens-gray);
      `}
  }
`;
