import { useEffect, useState } from 'react';
import { Button, Skeleton, Text } from '@point-of-sale/components';
import {
  getPaymentOptions,
  getCartTransactions,
  useAppDispatch,
  useAppSelector,
  processCartTransaction,
} from '@point-of-sale/store';
import { convertToNumber, formatNumberToLocale, swallowEvent } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import Cash from './Cash';
import CreditCard from './CreditCard';
import CreditNote from './CreditNote';
import UnifiedPaymentsInterface from './UnifiedPaymentsInterface';
import PaymentStatusModal from './PaymentStatusModal';
import { PAYMENT_MODES, PaymentModeTypes } from './constants';
import CustomerInfo from '../../../../components/desktop/ProductDiscovery/CustomerInfo';
import * as Styles from './styles';
import { isTablet } from 'react-device-detect';
import { CartTransactionDTOType } from '@point-of-sale/schemas';

type DisabledProps = {
  isDisabled: true;
  disabledReason: string;
};

type PaymentCollectionProps =
  | DisabledProps
  | {
      isDisabled: false;
    };

const PaymentCollection = ({ ...props }: PaymentCollectionProps) => {
  const isCartLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);
  const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);
  const isCartEmpty = useAppSelector(
    store => store.pointOfSale.cart.data.cartItems.ids.length === 0
  );
  const customer = useAppSelector(store => store.pointOfSale.customer.data);

  const currency = useAppSelector(store => store.pointOfSale.cart.data.currency);
  const cartMinAmountToBePaid = useAppSelector(store => store.checkout.cartMinAmountToBePaid);
  const cartTotalAmountToBePaid = useAppSelector(store => store.checkout.cartTotalAmountToBePaid);
  const cartTotalAmountPaid = useAppSelector(store => store.checkout.cartTotalAmountPaid);

  // const amountPaidByCash = useAppSelector(store =>
  //   store.checkout.cartTransactions.data.ids.reduce((acc, id) => {
  //     const transaction = store.checkout.cartTransactions.data.records[id];
  //     if (transaction.paymentMode === 'CASH') {
  //       return acc + transaction.amount;
  //     }
  //     return acc;
  //   }, 0)
  // );

  // console.log({ amountPaidByCash });

  // const hasSalesOrders = useAppSelector(store =>
  //   Object.values(store.pointOfSale.cart.data.cartItems.records).some(
  //     item => item.fulfillmentMode !== 'ONHAND'
  //   )
  // );

  const erpUser = useAppSelector(store => store.identity.erpUser);

  const cartTransactions = useAppSelector(store => store.checkout.cartTransactions.data);

  const currencySymbol =
    CURRENCY_SYMBOLS[(currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  const [section, setSection] = useState<PaymentModeTypes | 'MTO Advance Payment Details' | null>();

  const dispatch = useAppDispatch();

  const paymentByNonCashMethods = Object.values(cartTransactions.records).reduce(
    (acc: number, t: CartTransactionDTOType) => {
      if (t.paymentMode !== 'CASH' && t.status === 'VERIFIED') {
        return acc + t.amount;
      }
      return acc;
    },
    0
  );

  // console.log({ paymentByNonCashMethods });

  const remainingAmount =
    section === 'Cash'
      ? formatNumberToLocale(cartTotalAmountToBePaid - paymentByNonCashMethods)
      : formatNumberToLocale(Math.abs(cartTotalAmountToBePaid - cartTotalAmountPaid));

  useEffect(() => {
    if (cartId === -1) {
      return;
    }

    dispatch(getPaymentOptions(cartId));
    // dispatch(getPaymentTerminals());
    dispatch(getCartTransactions(cartId));
  }, [cartId, customer]);

  const heading =
    section === 'Cash'
      ? 'Amount to be collected by Cash'
      : cartTotalAmountToBePaid - cartTotalAmountPaid >= 0
      ? 'Amount remaining'
      : 'Amount to be returned';

  function getIsCompletePaymentDisabled() {
    if (isCartEmpty) {
      return true;
    }

    if (cartMinAmountToBePaid === 0 && erpUser?.roles?.some(role => role.name === 'STORE_ADMIN')) {
      return false;
    }

    return cartTotalAmountPaid < cartMinAmountToBePaid;
  }

  return (
    <Styles.Wrapper>
      {props.isDisabled && (
        <>
          <Styles.DisabledOverlay onClick={swallowEvent} />
          <Styles.DisabledReason textAlign="center" fontSize={36}>
            {props.disabledReason}
          </Styles.DisabledReason>
        </>
      )}

      <Styles.Heading>
        <Text fontSize={20} weight="bold">
          Choose Payment Mode
        </Text>

        <CustomerInfo />
      </Styles.Heading>
      <Styles.Main>
        <Styles.PaymentModesWrapper>
          {PAYMENT_MODES.map(item => (
            <Styles.PaymentModesItem
              key={item.title}
              onClick={() => setSection(item.title as typeof section)}
              $isActive={section === item.title}
              // $isDisabled={
              //   !Object.values(paymentOptionsRecords)
              //     .map(item => item.gateway)
              //     .includes(item.title)
              // }
            >
              {item.icon}
              <Text fontSize={20} weight={section === item.title ? 'bold' : 'regular'}>
                {item.title}
              </Text>
            </Styles.PaymentModesItem>
          ))}
        </Styles.PaymentModesWrapper>
        <Styles.SectionsWrapper>
          <Styles.SectionsContent>
            {isCartLoading ? (
              <Skeleton height={30} width={isTablet ? 300 : 400} />
            ) : (
              <Text fontSize={20} weight="bold">
                {heading}: {`${currencySymbol} ${remainingAmount}`}
              </Text>
            )}
            {/* TODO: Use enum here */}
            {section === 'Cash' && <Cash remainingAmount={convertToNumber(remainingAmount)} />}
            {section === 'Card' && (
              <CreditCard remainingAmount={convertToNumber(remainingAmount)} />
            )}
            {section === 'UPI' && (
              <UnifiedPaymentsInterface remainingAmount={convertToNumber(remainingAmount)} />
            )}
            {section === 'Credit Note' && <CreditNote />}
          </Styles.SectionsContent>
          <Styles.SectionsFooter>
            <Button
              onClick={() => {
                dispatch(getCartTransactions(cartId));
                dispatch(processCartTransaction());
              }}
              isDisabled={getIsCompletePaymentDisabled()}
            >
              Complete Payment
            </Button>
          </Styles.SectionsFooter>
          <PaymentStatusModal />
        </Styles.SectionsWrapper>
      </Styles.Main>
    </Styles.Wrapper>
  );
};

export default PaymentCollection;
