import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  height: calc(100dvh - 52px);
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;

  flex: 1;

  /* height: 200px; */
  overflow: hidden;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 60%;
  flex: 1;

  height: 100%;
  overflow: auto;
`;

export const Right = styled.div`
  width: 40%;
  /* width: 100%; */
  height: fit-content;

  height: 100%;

  overflow: hidden;
`;
