import { motion } from 'motion/react';
import styled from 'styled-components';

export const ImageWrapper = styled(motion.div)`
  width: 100%;
  height: 90px;
  width: 60px;
  position: relative;
`;

export const ProductImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ColoredBox = styled(motion.div)<{ $background: string }>`
  width: 100%;
  height: 100%;
  background: ${props => props.$background};

  display: flex;
  align-items: center;
  justify-content: center;
`;
