import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
/**
 *  TODO : figure out some way to clean this up
 * - maybe use a separate file to define the routes
 *  layout and page must be coupled together
 *  or should they ?
 */
import Layout from '../components/mobile/Layout';
import {
  getFacilities,
  getSalesChannels,
  getStoreStaff,
  useAppDispatch,
} from '@point-of-sale/store';

import ExploreProducts from '../pages/mobile/ExploreProducts';
import Home from '../pages/mobile/Home';

/**
 * * Should there be only one BaseRoutes component
 * * for both desktop and mobile ?
 * * or should desktop and mobile routes should be
 * * defined separately ?
 */
const MobileRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreStaff());
    dispatch(getSalesChannels());
    dispatch(getFacilities());
  }, []);

  return (
    <Routes>
      {/* <Route path="*" element={<Layout />}>
        <Route path="" element={<>Page Not Found</>} />
        <Route path="*" element={<Navigate to="/products" replace={true} />} />
      </Route> */}
      {/* <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
      </Route> */}

      <Route path="/products" element={<Layout />}>
        <Route path="" element={<ExploreProducts />} />
      </Route>

      <Route path="*" element={<Navigate to="/products" replace={true} />} />
    </Routes>
  );
};

export default MobileRoutes;
