import styled from 'styled-components';
import { IOption, Text } from '@point-of-sale/components';
import FilterValue from './FilterValue';
import { useDiscovery } from '../context';
import { isArray } from '@point-of-sale/utils';
import { categorizeOptions } from './helpers';
import { IDiscountOption } from '../../../desktop/ProductDiscovery/helpers';

interface IStyledFilterValueContainerProps {
  $gap?: 2 | 12;
}

const StyledFilterValueContainer = styled.div<IStyledFilterValueContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  gap: ${props => props.$gap}px;
`;

StyledFilterValueContainer.defaultProps = {
  $gap: 2,
};

const FilterValueLabel = styled(Text)`
  position: sticky;
  top: 0;
  background: var(--white);
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

// TODO: Virtualize this list
const FilterValueContainer = () => {
  const { selectedFilterName, filters, filtersToBeApplied, setFiltersToBeApplied } = useDiscovery();

  const selectedFilter = filters.find(filter => filter.name === selectedFilterName);

  const isSingleSelect = selectedFilter?.type === 'SINGLE_SELECT';

  const filterOptions = selectedFilter?.options?.slice();

  function onClickFilter(option: IOption | IDiscountOption) {
    if (selectedFilterName) {
      setFiltersToBeApplied(prev => {
        const newFilters = structuredClone(prev);

        if (!isArray(newFilters[selectedFilterName])) {
          newFilters[selectedFilterName] = [];
        }

        if (newFilters[selectedFilterName].map(f => f.value).includes(option.value)) {
          return {
            ...newFilters,
            [selectedFilterName]: newFilters[selectedFilterName].filter(
              v => v.value !== option.value
            ),
          };
        }

        if (isSingleSelect) {
          return {
            ...newFilters,
            [selectedFilterName]: [option],
          };
        }

        return {
          ...newFilters,
          [selectedFilterName]: [...newFilters[selectedFilterName], option],
        };
      });
    }
  }

  if (filterOptions && selectedFilter?.shouldSortAlphabetically) {
    filterOptions.sort((a, b) => String(a.label).localeCompare(String(b.label)));
  }

  if (filterOptions && selectedFilter?.shouldCategorizeAlphabetically) {
    const categorizedOptions = categorizeOptions(filterOptions, 'label');

    return (
      <StyledFilterValueContainer $gap={12}>
        {Object.keys(categorizedOptions).map(letter => (
          <CategoryContainer key={letter}>
            <FilterValueLabel fontSize={14} color="var(--dove-gray)">
              {letter}
            </FilterValueLabel>
            {categorizedOptions[letter].map(option => (
              <FilterValue
                key={option.label}
                isSelected={
                  selectedFilterName
                    ? filtersToBeApplied[selectedFilterName]
                        ?.map(f => f.value)
                        .includes(option.value)
                    : false
                }
                label={option.label}
                value={option.value}
                {...(option.count && { count: option.count })}
                onClick={value => {
                  onClickFilter(option);
                }}
              />
            ))}
          </CategoryContainer>
        ))}
      </StyledFilterValueContainer>
    );
  }

  return (
    <StyledFilterValueContainer>
      {filterOptions?.map(option => (
        <FilterValue
          key={option.label}
          isSelected={
            selectedFilterName
              ? filtersToBeApplied[selectedFilterName]?.map(f => f.value).includes(option.value)
              : false
          }
          label={option.label}
          value={option.value}
          {...(option.count && { count: option.count })}
          onClick={value => {
            onClickFilter(option);
          }}
        />
      ))}
    </StyledFilterValueContainer>
  );
};

export default FilterValueContainer;
