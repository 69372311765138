import { useMemo } from 'react';
import { IOption } from '@point-of-sale/components';
import { FacetDTOType } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';

export const useGetOptionsFromFacets = ({ withCount = false }) => {
  const facets = useAppSelector(store => store.pointOfSale.facets);

  const optionMapper = useMemo(
    () => (o: FacetDTOType['values'][0]) =>
      withCount
        ? {
            value: o.id,
            label: o.value,
            count: o.count,
          }
        : {
            value: o.id,
            label: `${o.value} (${o.count})`,
          },
    [withCount]
  );

  const getOptionsFromFacets = (filterName: FacetDTOType['key']['key']): Array<IOption> => {
    const rawOptions = facets.find(facet => facet.key.key === filterName)?.values ?? [];

    return rawOptions.map(optionMapper);
  };

  return { getOptionsFromFacets };
};
