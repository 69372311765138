import { isDesktop } from 'react-device-detect';

import Scanner from './Scanner';

interface IScanditModalProps {
  onChange: (value: string) => void;
}

export const ScanditModal = ({ onChange }: IScanditModalProps) => {
  if (isDesktop) {
    return null;
  }

  return <Scanner onChange={onChange} />;
};
