import Discount from './Discount';
import ServiceCharges from './ServiceCharges';
import PriceDetails from './PriceDetails';
// import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';
import Cart from '../../../../components/desktop/Cart';

const Summary = () => {
  // const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);

  // const hasPaymentsStarted = useAppSelector(
  //   store => store.checkout.cartTransactions.data.ids.length > 0
  // );

  return (
    <Styles.Wrapper>
      <Styles.CartContainer>
        <Cart usage="CHECKOUT" />
      </Styles.CartContainer>
      <Styles.Sticky>
        <Styles.Section>
          <Discount />
        </Styles.Section>
        <Styles.Section>
          <ServiceCharges level="TOP" />
        </Styles.Section>
        <Styles.Section>
          <PriceDetails />
        </Styles.Section>
      </Styles.Sticky>
    </Styles.Wrapper>
  );
};

export default Summary;
