import ReactDropdownSelect from 'react-dropdown-select';
import * as Styles from './styles';

interface ISelectDropdownProps<T extends string | object> {
  label?: string;
  sizeVariant?: 'small' | 'regular';
  options: Array<T>;
  values: Array<T> | [object];
  onChange: (values: Array<T>) => void;
  labelField: keyof T;
  valueField: keyof T;
  className?: string;
  dropdownHeight?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  width?: number;
  isSearchable?: boolean;
}

const SelectDropdown = <T extends string | object>({
  label,
  sizeVariant,
  options,
  onChange,
  values,
  labelField,
  valueField,
  // * Optional props
  className,
  dropdownHeight,
  isDisabled,
  isLoading,
  width = 200,
  isSearchable = false,
}: ISelectDropdownProps<T>) => {
  return (
    <Styles.Wrapper className={className} $minWidth={width}>
      <ReactDropdownSelect
        required={false}
        options={options}
        labelField={labelField as string}
        valueField={valueField as string}
        searchBy={labelField as string}
        onChange={onChange}
        // @ts-expect-error stupid types
        values={values}
        dropdownHeight={dropdownHeight}
        disabled={isDisabled}
        loading={isLoading}
        dropdownPosition="auto"
        // portal={document.getElementById('portal-root') as HTMLElement}
        backspaceDelete
        searchable={isSearchable}
      />
    </Styles.Wrapper>
  );
};

export default SelectDropdown;

// ______________________________________________________

// import ReactSelect from 'react-select';
// import * as Styles from './styles';

// interface ISelectDropdownProps<T extends string | object> {
//   label?: string;
//   sizeVariant?: 'small' | 'regular';
//   options: Array<T>;
//   values: Array<T> | [object];
//   onChange: (values: Array<T>) => void;
//   labelField: keyof T;
//   valueField: keyof T;
//   className?: string;
//   dropdownHeight?: string;
//   isDisabled?: boolean;
//   isLoading?: boolean;
//   width?: number;
// }

// const SelectDropdown = <T extends string | object>({
//   label,
//   sizeVariant,
//   options,
//   onChange,
//   values,
//   labelField,
//   valueField,
//   // * Optional props
//   className,
//   dropdownHeight,
//   isDisabled,
//   isLoading,
//   width = 200,
// }: ISelectDropdownProps<T>) => {
//   console.log({ options, labelField, valueField, values, onChange });

//   return (
//     <Styles.Wrapper className={className} $minWidth={width}>
//       <ReactSelect
//         options={options}
//         getOptionLabel={option => option[labelField] as string}
//         getOptionValue={option => option[valueField] as string}
//         onChange={selectedOptions => onChange(selectedOptions as Array<T>)}
//         value={values}
//         isDisabled={isDisabled}
//         isLoading={isLoading}
//         styles={{ menu: provided => ({ ...provided, height: dropdownHeight }) }}
//       />
//     </Styles.Wrapper>
//   );
// };

// export default SelectDropdown;
