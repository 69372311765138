import { useEffect, useMemo, useState } from 'react';
import { getUnixTime } from 'date-fns';
import {
  addItemToCart,
  getCurrentStoreAndWarehouseFacilityIds,
  getInventoryStatus,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { Modal, Text } from '@point-of-sale/components';
import DestinationSelector from '../DestinationSelector';
import useProductContext from '../Product/context/useProductContext';
import Table from './Table';
import useTableData from './Table/useTableData';
import { useBoolean } from '@point-of-sale/hooks';
import {
  DeliveryModeType,
  INormalizedServiceabilityData,
  ProductVariantDTOType,
} from '@point-of-sale/schemas';
import { getExpressPromiseDate } from '@point-of-sale/utils';
import DemandedPincodeInput from './DemandedPincodeInput';
import * as Styles from './styles';
import ReturnsAndExchangePolicy from '../ReturnsAndExchangePolicy';

interface IInventoryDetailsModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  skuCode: string;
  selectedVariant: ProductVariantDTOType;
  serviceabilityData: INormalizedServiceabilityData;
  demandedPincode: string;
  onChangeDemandedPincode: (pincode: string) => void;
}

export interface ISource {
  fulfillmentFacility: {
    id: number;
    name: string;
    city: string;
    pincode: string;
  };
  inventory: number;
  promiseDate: string;
  isJit: boolean;
}

export interface IDestination {
  customerDeliveryStoreId?: number;
  deliveryMode: DeliveryModeType | '';
}

const InventoryDetailsModal = ({
  isOpen,
  closeModal,
  skuCode,
  selectedVariant,
  serviceabilityData,
  demandedPincode,
  onChangeDemandedPincode,
}: IInventoryDetailsModalProps) => {
  const [isLoading, loadingActions] = useBoolean();

  const customerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  // const isSearchBarcodish = useAppSelector(store => store.pointOfSale.isBarcodish);

  const [source, setSource] = useState<ISource>({
    fulfillmentFacility: {
      id: 0,
      name: '',
      city: '',
      pincode: '',
    },
    inventory: 0,
    promiseDate: '',
    isJit: false,
  });

  const [destination, setDestination] = useState<IDestination>({
    deliveryMode: '',
    customerDeliveryStoreId: -1,
  });

  const dispatch = useAppDispatch();

  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  const { mannerOfPurchase } = useProductContext();

  useEffect(() => {
    const {
      hasInventoryInCurrentStoreOrWarehouse,
      hasInventoryInOtherStoreOrWarehouse,
      inventoryInOtherStoreAndWarehouses,
    } = getInventoryStatus(selectedVariant);

    if (currentFacilityId) {
      if (hasInventoryInCurrentStoreOrWarehouse) {
        setSource(prev => ({
          ...prev,
          inventory:
            selectedVariant?.facilityInventory?.[currentFacilityId]?.availableInventory ?? 0,
          fulfillmentFacility: {
            ...prev.fulfillmentFacility,
            id: currentFacilityId,
          },
        }));
      } else if (hasInventoryInOtherStoreOrWarehouse) {
        const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];
        setSource(prev => ({
          ...prev,
          fulfillmentFacility: {
            ...prev.fulfillmentFacility,
            id: firstFacilityWithInventory.id,
          },
        }));
      }

      if (isSelectedSalesChannelOnline) {
        setDestination({
          deliveryMode: 'HOME_DELIVERY',
          customerDeliveryStoreId: -1,
        });
      } else {
        setDestination({
          deliveryMode: hasInventoryInCurrentStoreOrWarehouse ? 'STORE_PURCHASE' : 'STORE_PICKUP',
          customerDeliveryStoreId: currentFacilityId,
        });
      }
    }
  }, [currentFacilityId, selectedVariant, isSelectedSalesChannelOnline]);

  const tableData = useTableData({
    customerAddress,
    serviceabilityData: serviceabilityData[skuCode] ?? [],
    destination,
    demandedPincode,
  });

  const selectedRow = useMemo(
    () => tableData.find(item => item.id === source.fulfillmentFacility.id),
    [tableData, source.fulfillmentFacility.id]
  );

  useEffect(() => {
    if (selectedRow) {
      const date = selectedRow.expectedDeliveryDate;

      if (date !== source.promiseDate || selectedRow.isJit !== source.isJit) {
        setSource(prev => ({
          ...prev,
          promiseDate: date ?? '',
          isJit: selectedRow.isJit ?? false,
        }));
      }
    }
  }, [selectedRow, source.promiseDate, source.isJit]);

  // useEffect(() => {
  //   const date = selectedRow?.expectedDeliveryDate;

  //   if (date === source.promiseDate) {
  //     setSource(prev => ({
  //       ...prev,
  //       isJit: selectedRow?.isJit ?? false,
  //     }));
  //     return;
  //   }

  //   setSource(prev => ({
  //     ...prev,
  //     promiseDate: date ?? '',
  //     isJit: selectedRow?.isJit ?? false,
  //   }));
  // }, [selectedRow]); // source

  const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

  function getButtonText() {
    if (
      currentStoreAndWarehousesFacilityIds.includes(source.fulfillmentFacility.id) &&
      source.inventory === 0
    ) {
      return 'Buy With No Inventory';
    }

    if (currentStoreAndWarehousesFacilityIds.includes(source.fulfillmentFacility.id)) {
      return 'Add to Bag';
    }

    if (source.fulfillmentFacility.id) {
      return `Buy From ${facilityRecords[source.fulfillmentFacility.id].name}`;
    }

    return 'Select Appropriate Facility';
  }

  const Header = () => (
    <Styles.HeadingWrapper>
      <Text fontSize={20} weight="bold">
        Inventory Details
      </Text>
      {isSelectedSalesChannelOnline ? (
        <Text fontSize={14} weight="regular" textAlign="right">
          Deliver To Pincode {customerAddress?.pincode}
        </Text>
      ) : (
        <>
          <Text fontSize={16} weight="medium" textAlign="right">
            Deliver To &nbsp;
          </Text>
          <DestinationSelector
            isJIT={false} // todo: revisit
            shouldShowOtherFacilities={true}
            width="200px"
            isMTC={false}
            isMTO={false}
            // 0 because TATs are not shown in this modal in destination selector
            defaultTat={0}
            customerAddress={customerAddress}
            serviceabilityData={serviceabilityData[skuCode] ?? []}
            variant={selectedVariant}
            fulfillmentMode={mannerOfPurchase.fulfillmentMode}
            onChange={data => {
              console.log('destination selector is running', { data });

              setDestination({
                deliveryMode: data.deliveryMode,
                customerDeliveryStoreId: data.customerDeliveryStoreId,
              });
              data.callback(true);
            }}
          />
          {!customerAddress && destination.deliveryMode === 'HOME_DELIVERY' && (
            <DemandedPincodeInput
              demandedPincode={demandedPincode}
              onChangeDemandedPincode={onChangeDemandedPincode}
            />
          )}
        </>
      )}
    </Styles.HeadingWrapper>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      heading={<Header />}
      footer={
        <Styles.FooterWrapper>
          <Styles.PolicyWrapper>
            <ReturnsAndExchangePolicy
              isExchangeable={
                source.isJit ? selectedVariant.mtoReturnable : selectedVariant.exchangeable
              }
              isReturnable={
                source.isJit ? selectedVariant.mtoReturnable : selectedVariant.returnable
              }
            />
          </Styles.PolicyWrapper>
          <Styles.NextButton
            sizeVariant="large"
            isLoading={isLoading}
            onClick={() => {
              // loadingActions.on();

              let determinedDeliveryMode;

              // TODO: Modularize this logic
              if (destination.deliveryMode === 'HOME_DELIVERY') {
                determinedDeliveryMode = 'HOME_DELIVERY';
              } else {
                if (currentStoreAndWarehousesFacilityIds.includes(source.fulfillmentFacility.id)) {
                  determinedDeliveryMode = 'STORE_PURCHASE';
                } else {
                  determinedDeliveryMode = 'STORE_PICKUP';
                }
              }

              const extraProperties = {} as any;

              if (
                destination.customerDeliveryStoreId &&
                destination.customerDeliveryStoreId !== -1
              ) {
                extraProperties['customerDeliveryStoreId'] = destination.customerDeliveryStoreId;
              }

              const isJit = source.isJit;

              if (source.promiseDate === 'EXPRESS') {
                extraProperties['isExpress'] = true;
                extraProperties['promiseDate'] = getExpressPromiseDate();
              } else {
                if (source.promiseDate) {
                  extraProperties['promiseDate'] = getUnixTime(
                    new Date(source.promiseDate)
                  ).toString();
                }
              }

              const fulfillmentFacility = facilityRecords[source.fulfillmentFacility.id];

              if (isJit) {
                extraProperties['isExpress'] = false;
                extraProperties['promiseDate'] = getUnixTime(
                  new Date(source.promiseDate)
                ).toString();
              }

              if (selectedVariant) {
                dispatch(
                  addItemToCart(
                    {
                      skuId: selectedVariant.skuId,
                      skuCode: selectedVariant.skuCode,
                      fulfillmentFacilityId: source.fulfillmentFacility.id,
                      deliveryMode: determinedDeliveryMode as DeliveryModeType,
                      fulfillmentMode: isJit ? 'JIT' : 'ONHAND',
                      quantity: 1,
                      fulfillmentFacilityGroupId: fulfillmentFacility.facilityGroupId,
                      fulfillmentFacilityType: fulfillmentFacility.facilityType,
                      ...extraProperties,
                    },
                    () => {
                      loadingActions.off();
                      closeModal();
                    }
                  )
                );
              }
            }}
          >
            {getButtonText()}
          </Styles.NextButton>
        </Styles.FooterWrapper>
      }
    >
      <Table data={tableData} source={source} setSource={setSource} />
    </Modal>
  );
};

export default InventoryDetailsModal;
