import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { offlineFacilitiesSelector, useAppSelector } from '@point-of-sale/store';
import { IDiscoveryFilter } from '../components/desktop/Discovery';
import { useGetOptionsFromFacets } from './useGetOptionsFromFacets';
import { getDiscountFilterOptions } from '../components/desktop/ProductDiscovery/helpers';

// TODO: Refactor
export const useProductDiscoveryFilter = (): {
  discoveryFilters: Array<IDiscoveryFilter>;
} => {
  const { getOptionsFromFacets } = useGetOptionsFromFacets({
    withCount: isMobile,
  });

  const filters = useAppSelector(store => store.pointOfSale.filters);
  const facilities = useAppSelector(offlineFacilitiesSelector);

  const discoveryFilters: Array<IDiscoveryFilter> = useMemo(
    () => [
      {
        name: 'brandName',
        placeholder: 'Brand',
        options: getOptionsFromFacets('brandName'),
        values: String(filters.brandName?.value).split(',') ?? [],
        type: 'MULTISELECT',
        shouldCategorizeAlphabetically: true,
      },
      {
        name: 'variants.inStockFacilities',
        placeholder: 'Availability',
        options: facilities.data.ids
          .filter(id => facilities.data.records[id].facilityType !== 'VENDOR')
          .map(id => ({
            label: facilities.data.records[id].name,
            value: id,
          })),
        values: String(filters['variants.inStockFacilities']?.value).split(',').map(Number) ?? [],
        type: 'MULTISELECT',
      },
      {
        name: 'variants.size',
        placeholder: 'Size',
        options: getOptionsFromFacets('variants.size'),
        values: String(filters['variants.size']?.value).split(',') ?? [],
        type: 'MULTISELECT',
      },
      {
        name: 'variants.color',
        placeholder: 'Color',
        options: getOptionsFromFacets('variants.color'),
        values: String(filters['variants.color']?.value).split(',') ?? [],
        type: 'MULTISELECT',
        shouldSortAlphabetically: true,
      },
      {
        name: 'discounts',
        placeholder: 'Discounts',
        options: getDiscountFilterOptions(),
        values: [
          ...(String(filters['variants.discountGroupId']?.value).split(',') ?? []),
          ...(String(filters['variants.totalDiscount']?.value).split(',') ?? []),
        ],
        type: 'SINGLE_SELECT',
      },
    ],
    [filters, facilities, getOptionsFromFacets]
  );

  return { discoveryFilters };
};
