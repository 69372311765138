import { Button } from '@point-of-sale/components';
import BuyFromAnotherStore from '../AddOperations/BuyFromAnotherStore';
import useProductContext from '../context/useProductContext';
import {
  getInventoryStatus,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import PlaceMadeToOrder from '../AddOperations/PlaceMadeToOrder';
import * as Styles from './styles';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';

interface IFooterProps {
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const Footer = ({ serviceabilityData, isFetchingServiceability }: IFooterProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const cartItemEditCache = useAppSelector(store => store.pointOfSale.cartItemEditCache);

  const { isExpanded, selectedVariant, expandActions } = useProductContext();

  const {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    hasNoInventoryInAnyStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
  } = getInventoryStatus(selectedVariant);

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  if (isSelectedSalesChannelOnline) {
    inventoryInOtherStoreAndWarehouses.sort((a, b) => {
      if (a.name.toLowerCase() === 'try at home') return -1;
      if (b.name.toLowerCase() === 'try at home') return 1;
      return 0;
    });
  }

  let Left;

  if (hasInventoryInOtherStoreOrWarehouse) {
    const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];

    Left = () => (
      <Styles.Left>
        <BuyFromAnotherStore
          anotherStore={firstFacilityWithInventory}
          serviceabilityData={serviceabilityData}
          isFetchingServiceability={isFetchingServiceability}
        />
      </Styles.Left>
    );
  } else if (hasNoInventoryInAnyStoreOrWarehouse) {
    Left = () => (
      <Styles.Left>
        <PlaceMadeToOrder doesNotAddToCart />
      </Styles.Left>
    );
  }

  const shouldShowLeftPart =
    isExpanded &&
    !cartItemEditCache.isPopulated &&
    // isBarcodish &&
    mode === 'BUY_NOW' &&
    !hasInventoryInCurrentStoreOrWarehouse;

  return (
    <Styles.Wrapper
      style={{
        justifyContent: shouldShowLeftPart ? 'space-between' : 'flex-end',
      }}
    >
      {shouldShowLeftPart && Left && <Left />}
      <Button
        sizeVariant="xs"
        variant="outline"
        rightIcon={isExpanded ? 'DoubleChevronUp' : 'DoubleChevronDown'}
        onClick={expandActions.toggle}
        colorScheme="dark"
        // colorScheme={mode === 'BUY_NOW' ? 'primary' : 'secondary'}
      >
        {isExpanded ? 'Less' : 'More'} Details
      </Button>
    </Styles.Wrapper>
  );
};

export default Footer;
