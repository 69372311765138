import { motion } from 'motion/react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

export const Filter = styled(motion.div)`
  padding: 0 12px;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--white);
  width: fit-content;
`;
