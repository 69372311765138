import { ChangeEvent, Suspense, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal, SpinnerIcon, Text, TextArea } from '@point-of-sale/components';
import Preview from './Preview';

import {
  AlterationEntryDTOType,
  CartItemDTOType,
  CustomizationCategoryType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { DIAGRAM_IMAGE, PERSONALIZATION_FIELDS } from './constants';
import { upsertPersonalization, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import toast from 'react-hot-toast';

import { getDeliveryDateByServiceability } from '../../DestinationSelector/helpers';
import * as Styles from './styles';

export interface IPersonalizationProps {
  isOpen: boolean;
  onClose: VoidFunction;
  itemId: number;
  mode: 'CUSTOMIZATION' | 'ALTERATION';
  skuData: CartItemDTOType['productVariant'];
  customizationCategory: CustomizationCategoryType;
  alterationEntry: AlterationEntryDTOType | undefined;
  serviceabilityData: INormalizedServiceabilityData[string];
  cartItemData: CartItemDTOType;
}

const Personalization = ({
  itemId,
  mode,
  isOpen,
  onClose,
  customizationCategory,
  skuData,
  alterationEntry,
  serviceabilityData,
  cartItemData,
}: IPersonalizationProps) => {
  const isLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);
  const facilities = useAppSelector(store => store.common.facilities.data);

  const [formData, setFormData] = useState<AlterationEntryDTOType>({
    referenceColor: '',
    referenceSize: '',
    remarks: '',
    customizationCategory,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      referenceColor: skuData.color,
      referenceSize: skuData.size,
    }));
  }, [skuData]);

  useEffect(() => {
    if (alterationEntry) {
      setFormData({
        ...alterationEntry,
      });
    }
  }, [alterationEntry]);

  const formSections = useMemo(() => {
    if (mode === 'CUSTOMIZATION' && skuData.size !== 'C') {
      return [];
    }

    return customizationCategory === 'CO_ORD_SET'
      ? [PERSONALIZATION_FIELDS.TOP_WEAR, PERSONALIZATION_FIELDS.BOTTOM_WEAR]
      : [PERSONALIZATION_FIELDS[customizationCategory]];
  }, [mode, skuData.size]);

  type FieldName =
    | keyof AlterationEntryDTOType['bottomwearAlterationEntry']
    | keyof AlterationEntryDTOType['topwearAlterationEntry'];

  const heading = mode.toLowerCase();

  const DiagramImage = DIAGRAM_IMAGE[customizationCategory];

  function containsEmptyValues(): boolean {
    if (mode === 'ALTERATION') {
      return false;
    }

    let isAnyValueEmpty = false;

    let emptyFields = '';

    formSections.forEach(section => {
      const filledFields = formData[section.accessor] ?? {};

      section.fields.forEach(field => {
        if (
          field.isMandatory &&
          (!filledFields[field.name as FieldName] || filledFields[field.name as FieldName] === '')
        ) {
          isAnyValueEmpty = true;
          emptyFields += `${field.label}, `;
        }
      });
    });

    if (emptyFields.length > 0) {
      toast.error(`Please fill ${emptyFields}`);
    }

    return isAnyValueEmpty;
  }

  function getDefaultTat(): number {
    let isMto = false;
    let isMtc = false;

    if (mode === 'CUSTOMIZATION') {
      isMto = false;
      isMtc = true;
    }

    if (isMtc) {
      return cartItemData.productVariant.mtcTAT ? cartItemData.productVariant.mtcTAT + 1 : 6;
    }
    if (isMto) {
      return cartItemData.productVariant.mtoTAT ? cartItemData.productVariant.mtoTAT + 1 : 6;
    }

    return 3;
  }

  return (
    <Modal
      heading={<Text fontSize={20}>Add {heading} remarks</Text>}
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      footer={
        <Button
          isLoading={isLoading}
          colorScheme={mode === 'CUSTOMIZATION' ? 'secondary' : 'primary'}
          onClick={() => {
            if (containsEmptyValues()) {
              return;
            }

            let isMto = false;
            let isMtc = false;

            if (mode === 'CUSTOMIZATION') {
              isMto = false;
              isMtc = true;
            }

            let facilityId = cartItemData.fulfillmentFacilityId as number;

            if (facilityId === -1) {
              if (cartItemData.deliveryMode === 'STORE_PICKUP') {
                facilityId = cartItemData.customerDeliveryStoreId as number;
              }
              // if(cartItemData.deliveryMode === 'HOME_DELIVERY'){
              //   facilityId = cartItemData.customerDeliveryAddressId;
              // }
            }

            const date = getDeliveryDateByServiceability(
              serviceabilityData,
              facilityId,
              facilities,
              isMto,
              isMtc,
              getDefaultTat(),
              false // isJIT or what?
            );

            dispatch(
              upsertPersonalization({
                itemId,
                personalizationData: formData,
                promiseDate: date,
                successCallback: () => {
                  toast.success(`${heading} added successfully`);
                  onClose();
                },
              })
            );
          }}
        >
          Add
        </Button>
      }
    >
      <Styles.Wrapper>
        <Styles.Left>
          <Preview mode={mode} skuData={skuData} />

          {formSections.map(section => {
            return (
              <Styles.FormSectionContainer key={section.title}>
                <Text fontSize={20}>{section.title}</Text>
                <Styles.FormSection>
                  {section.fields.map(field => (
                    <Input
                      key={field.name}
                      label={`${field.label} ${
                        mode !== 'ALTERATION' && field.isMandatory ? '*' : ''
                      }`}
                      placeholder={field.label.replace('(inches)', '')}
                      sizeVariant="small"
                      name={field.name}
                      variant="outline"
                      type="number"
                      value={formData?.[section.accessor]?.[field.name as FieldName] ?? ''}
                      onChange={e =>
                        setFormData(prev => ({
                          ...prev,
                          [section.accessor]: {
                            ...prev[section.accessor],
                            [field.name]: e.target.value,
                          },
                        }))
                      }
                      min={0}
                    />
                  ))}
                </Styles.FormSection>
              </Styles.FormSectionContainer>
            );
          })}

          <TextArea
            label="Remarks"
            value={formData.remarks}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFormData(prev => ({ ...prev, remarks: e.target.value }))
            }
          />
        </Styles.Left>

        <Styles.Diagram>
          <Suspense fallback={<SpinnerIcon size={32} />}>
            <DiagramImage />
          </Suspense>
        </Styles.Diagram>
      </Styles.Wrapper>
    </Modal>
  );
};

export default Personalization;
