import { createSlice } from '@reduxjs/toolkit';
import { PermissionStateType, PermissionNameType, PermissionStatusType } from './types';
import { getPermissionStatus } from './thunks';

const initialState: PermissionStateType = {
  camera: 'unknown',
};

const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    writePermissionToState(
      state,
      action: {
        type: string;
        payload: { name: PermissionNameType; state: PermissionStatusType };
      }
    ) {
      const { name, state: permissionState } = action.payload;
      state[name] = permissionState;
      console.log(`[State Update] Permission for ${name} is set to ${permissionState}`);
    },
  },

  extraReducers: builder => {
    builder.addCase(getPermissionStatus.fulfilled, (state, action) => {
      const { name, state: permissionState } = action.payload;
      state[name] = permissionState;
    });
  },
});

export const permissionReducer = permissionSlice.reducer;

export const { writePermissionToState } = permissionSlice.actions;
