import styled from 'styled-components';
import { motion } from 'motion/react';

interface StyledImageProps {
  $isLoading: boolean;
}

export const MotionImageWrapper = styled(motion.div)`
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

export const Image = styled.img<StyledImageProps>`
  display: ${({ $isLoading }) => ($isLoading ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const IconWrapper = styled(motion.div)`
  border: 1px solid var(--athens-gray);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1px;
`;
