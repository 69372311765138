import { ColorSelector, SizeSelector } from '@point-of-sale/components';
import { useProductListItem } from '../context';
import * as Styles from './styles';

const VariantsInfo = () => {
  const { allColorOptions, allSizeOptions, data, onSelectColor, onSelectSize, enabledSizes } =
    useProductListItem();

  return (
    <Styles.Wrapper>
      <ColorSelector
        availableColors={allColorOptions.map(item => ({
          id: item.displayName,
          name: item.displayName,
          colorCodes: item.colorCodes ?? [],
        }))}
        enabledColors={allColorOptions.map(item => item.displayName)}
        selectedColor={data.selectedColor}
        onSelectColor={onSelectColor}
      />

      <SizeSelector
        boxSize="COMPACT"
        shouldShowCustomTailored={false}
        availableSizes={allSizeOptions}
        enabledSizes={enabledSizes}
        selectedSize={data.selectedSize}
        onSelectSize={onSelectSize}
      />
    </Styles.Wrapper>
  );
};

export default VariantsInfo;
