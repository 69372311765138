import { IconPropsType } from '../types';

const FilterIcon = ({ size = 24, stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={3}
    stroke={stroke}
    fill="none"
    {...props}
  >
    <line x1={50.69} y1={32} x2={56.32} y2={32} />
    <line x1={7.68} y1={32} x2={38.69} y2={32} />
    <line x1={26.54} y1={15.97} x2={56.32} y2={15.97} />
    <line x1={7.68} y1={15.97} x2={14.56} y2={15.97} />
    <line x1={35} y1={48.03} x2={56.32} y2={48.03} />
    <line x1={7.68} y1={48.03} x2={23} y2={48.03} />
    <circle cx={20.55} cy={15.66} r={6} />
    <circle cx={44.69} cy={32} r={6} />
    <circle cx={29} cy={48.03} r={6} />
  </svg>
);
export default FilterIcon;
