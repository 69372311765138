import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'motion/react';
import { usePopper } from 'react-popper';
import { endOfDay, fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { type DateRange, DayPicker } from 'react-day-picker';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import IconButton from '../IconButton';
import Text from '../Typography/Text';
import { ChevronRightIcon, EquilibriumIcon } from '../icons';
import Button from '../Button';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { formatToReadableDate } from '@point-of-sale/utils';

import * as Styles from './styles';
import 'react-day-picker/style.css';
import { IDateRange } from '@point-of-sale/types';

interface IDateRangePickerProps {
  className?: string;
  label: string;
  values: IDateRange;
  onChange: (selected: IDateRange) => void;
}

const DateRangePicker = ({ className, label, onChange, values }: IDateRangePickerProps) => {
  const [isModalOpen, modalOpenActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [selected, setSelected] = useState<DateRange>();

  useEffect(() => {
    setSelected({
      from: values.from ? new Date(values.from) : undefined,
      to: values.to ? new Date(values.to) : undefined,
    });
  }, [values]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      modalOpenActions.off();
    }
  });

  return (
    <Styles.Wrapper className={className} ref={wrapperRef}>
      <Styles.Trigger ref={ref => setReferenceElement(ref)} onClick={modalOpenActions.toggle}>
        <Text fontSize={16} color="var(--cod-gray)">
          {label}
        </Text>
        <Styles.IconWrapper>
          <ChevronRightIcon
            animate={isModalOpen ? 'open' : 'closed'}
            variants={{
              open: { rotate: -90 },
              closed: { rotate: 90 },
            }}
          />
        </Styles.IconWrapper>
      </Styles.Trigger>

      {createPortal(
        <AnimatePresence mode="wait">
          {isModalOpen && (
            <Styles.PopoverContainer
              ref={ref => setPopperElement(ref)}
              style={{
                ...styles.popper,
                width: 720,
                height: 600,
              }}
              {...attributes.popper}
              onMouseEnter={clickOutsideAllowedActions.off}
              onMouseLeave={clickOutsideAllowedActions.on}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <Styles.PopoverHeader>
                <Text fontSize={16} weight="bold">
                  {label}
                </Text>

                <IconButton
                  className="close-button"
                  shapeVariant="circle"
                  icon="CrossIcon"
                  onClick={modalOpenActions.off}
                />
              </Styles.PopoverHeader>
              <Styles.Content>
                <Styles.RangeDisplay>
                  <Text fontSize={14} weight="semibold">
                    From {values.from && formatToReadableDate(fromUnixTime(values.from))}
                  </Text>
                  <EquilibriumIcon
                    size={20}
                    fill="var(--alto)"
                    style={{
                      flexShrink: 0,
                    }}
                  />
                  <Text fontSize={14} weight="semibold">
                    To {values.to && formatToReadableDate(fromUnixTime(values.to))}
                  </Text>
                </Styles.RangeDisplay>
                <Styles.CalenderWrapper>
                  <DayPicker
                    mode="range"
                    selected={selected}
                    numberOfMonths={2}
                    onSelect={newSelected => {
                      setSelected(newSelected);
                    }}
                  />
                </Styles.CalenderWrapper>
              </Styles.Content>
              <Styles.PopoverFooter>
                <Button
                  onClick={() => {
                    // IDateRange
                    if (selected?.from && selected?.to) {
                      onChange({
                        from: getUnixTime(startOfDay(selected.from)),
                        to: getUnixTime(endOfDay(selected.to)),
                      });
                      // onChange({
                      //   from: startOfDay(selected.from).getTime() / 1000,
                      //   to: endOfDay(selected.to).getTime() / 1000,
                      // });
                    }
                    modalOpenActions.off();
                  }}
                >
                  APPLY
                </Button>
              </Styles.PopoverFooter>
            </Styles.PopoverContainer>
          )}
        </AnimatePresence>,
        document.body
      )}
    </Styles.Wrapper>
  );
};

export default DateRangePicker;
