import { useEffect } from 'react';
import {
  getOpenCarts,
  setOpenCarts,
  setOpenCartSearch,
  setOpenCartsPagination,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import Discovery from '../../../components/desktop/Discovery';
import { useDebounce } from '@point-of-sale/hooks';
import { emptyFunction } from '@point-of-sale/utils';

const OpenCartsDiscovery = () => {
  const search = useAppSelector(store => store.openCarts.search);

  function onSearchChange(searchQuery: string) {
    dispatch(
      setOpenCartSearch({
        query: searchQuery,
      })
    );

    dispatch(
      setOpenCartsPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    // dispatch(
    //   setOpenCarts({
    //     data: {
    //       ids: [],
    //       records: {},
    //     },
    //   })
    // );
  }

  const debouncedApiCall = useDebounce(() => dispatch(getOpenCarts()), 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [search.query]);

  const dispatch = useAppDispatch();
  return (
    <Discovery
      filters={[]}
      onFilterChange={emptyFunction}
      onFilterClear={emptyFunction}
      placeholder="Search by Customer Name, Customer Phone, Cart Id"
      onPressEnter={onSearchChange}
      searchQuery={search.query}
      renderSelectedFilters={() => null}
    />
  );
};

export default OpenCartsDiscovery;
