import { LegacySelect, Text, ThemeType } from '@point-of-sale/components';
import { motion } from 'motion/react';
import styled, { css } from 'styled-components';

export const Wrapper = styled(motion.div)<{ $isLoading: boolean }>`
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid var(--gallery);

  ${props =>
    props.$isLoading &&
    css`
      cursor: progress;
    `}
`;

export const Left = styled.div`
  height: 90px;
  width: 60px;
  position: relative;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
// fulfillmentMode

export const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Actionables = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ActionablesLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 8px;

  p {
    width: fit-content;
  }
`;

export const ActionablesRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextPair = styled(Text)<{ theme: ThemeType }>`
  b {
    color: var(--cod-gray);
    font-weight: ${props => props.theme.typography.fonts.weight.semibold};
  }
`;

export const UpdatedSelect = styled(LegacySelect)`
  input {
    padding: 6px 8px;
    width: 120px;
    font-size: 14px;
  }
`;

export const PersonalizationSummary = styled.div`
  border: 1px solid var(--athens-gray);
  margin-top: 12px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 34px;
  gap: 4px;

  button {
    border: none;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  p {
    white-space: nowrap;
  }
`;
