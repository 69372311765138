import { z } from 'zod';

export const CustomizationCategorySchema = z.union([
  z.literal('DRESS'),
  z.literal('TOP_WEAR'),
  z.literal('BOTTOM_WEAR'),
  z.literal('CO_ORD_SET'),
  z.literal('NONE'),
]);

export type CustomizationCategoryType = z.infer<typeof CustomizationCategorySchema>;
