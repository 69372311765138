import styled from 'styled-components';

export const ProductListWrapper = styled.div`
  flex: 1;
  background: var(--white);
  border-radius: 8px;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
