import React, { useEffect, useRef } from 'react';
import { CartUsageType } from '../types';
import { useAppSelector } from '@point-of-sale/store';
import { areArraysEqual, isLatestDate } from '@point-of-sale/utils';
import { useAnimation } from 'motion/react';
import { FulfillmentModeType } from '@point-of-sale/schemas';
import { usePrevious } from '@point-of-sale/hooks';

interface IUseScrollToViewProps {
  createdOn: string;
  usage: CartUsageType;
  fulfillmentMode: FulfillmentModeType;
}

const useScrollToView = ({ createdOn, usage, fulfillmentMode }: IUseScrollToViewProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const controls = useAnimation();

  const allCreatedOns = useAppSelector(store =>
    Object.values(store.pointOfSale.cart.data.cartItems.records).map(item => item.createdOn)
  );

  useEffect(() => {
    if (usage !== 'ADD_PRODUCTS') {
      return;
    }

    const isLatest = isLatestDate(allCreatedOns, createdOn);

    // const bgColor =
    //   fulfillmentMode === 'ONHAND' ? 'var(--alice-blue)' : 'var(--white-lilac)';

    if (isLatest) {
      // componentRef.current?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'center',
      // });
      //   controls.start({
      //     backgroundColor: [bgColor, 'var(--white)', bgColor, 'var(--white)'],
      //     transition: { duration: 2, times: [0, 0.3, 0.6, 1] },
      //   });
    }
  }, [createdOn, usage, allCreatedOns, controls, fulfillmentMode]);

  return { componentRef, controls };
};

export default useScrollToView;
