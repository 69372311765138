import { createAsyncThunk } from '@reduxjs/toolkit';
import type { PermissionNameType, PermissionStatusType } from './types';
import { writePermissionToState } from './slice';
import { RootStateType } from '../store';

export const getPermissionStatus = createAsyncThunk(
  'permissions/getPermissionStatus',
  async (
    permissionName: PermissionNameType
  ): Promise<{
    name: PermissionNameType;
    state: PermissionStatusType;
  }> => {
    try {
      const permission = await navigator.permissions.query({
        name: permissionName as PermissionName,
      });
      console.log(`Permission for ${permissionName} is ${permission.state}`);
      return { name: permissionName, state: permission.state };
    } catch (error) {
      console.error(`Failed to query permission for ${permissionName}:`, error);
      return { name: permissionName, state: 'unknown' };
    }
  }
);

export const getPermissionStatusOrRequestForCamera = createAsyncThunk(
  'permissions/getPermissionStatusOrRequestForCamera',
  async (
    _,
    { getState, dispatch }
  ): Promise<{
    name: PermissionNameType;
    state: PermissionStatusType;
  }> => {
    try {
      const persistedPermission = (getState() as RootStateType).permissions.camera;

      // * this shit doesn't work for camera
      // const permission = await navigator.permissions.query({
      //   name: 'camera' as PermissionName,
      // });

      console.log(`Permission for camera is ${persistedPermission}`);

      if (persistedPermission !== 'granted' && persistedPermission !== 'denied') {
        try {
          console.log(`Requesting Permission for camera`);
          // Try accessing the camera to trigger the permission prompt
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          stream.getTracks().forEach(track => track.stop());
          // If permission is granted, save to localStorage
          console.log(`Requested Permission for camera`);
          dispatch(writePermissionToState({ name: 'camera', state: 'granted' }));
        } catch (error) {
          console.log('Permissions for camera could not be granted');
        }
      }

      return { name: 'camera', state: persistedPermission as PermissionStatusType };
    } catch (error) {
      console.error(`Failed to query permission for camera:`, error);
      dispatch(writePermissionToState({ name: 'camera', state: 'unknown' }));
      return { name: 'camera', state: 'unknown' };
    }
  }
);
