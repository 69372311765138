import { ThemeType } from '@point-of-sale/components';
import { NAVIGATION_Z_INDEX } from '@point-of-sale/constants';
import { motion } from 'motion/react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';

// TODO : Refactor everything here

export const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 70;
  right: 0;
  bottom: 0;
  width: calc(100vw - 70px);
  height: max(100vh, 100dvh);
  z-index: ${NAVIGATION_Z_INDEX};
  user-select: none;
  -webkit-user-select: none;
  pointer-events: all;
`;

export const Wrapper = styled(motion.div)`
  height: 100vh;
  background: var(--white);
  width: 70px;
  position: relative;

  z-index: ${NAVIGATION_Z_INDEX};
  display: flex;
  flex-direction: column;
`;

// TODO: Fix styles
export const Header = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: var(--cod-gray);
  min-height: 52px;
`;

export const CompanyLogoWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cod-gray);
  width: 100%;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 8px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SectionHeading = styled(motion.p)<{ theme: ThemeType }>`
  text-transform: uppercase;
  padding: 24px;
  color: var(--dove-gray);
  font-size: ${props => props.theme.typography.fonts.size[12]};
  white-space: nowrap;
`;

interface IVisitableProps {
  theme: ThemeType;
  $isActive: boolean;
  $isExpanded: boolean;
}

export const Visitable = styled(Link)<IVisitableProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;
  /* border-radius: 1px; */
  border-radius: 8px;
  color: var(--dove-gray);

  font-size: ${props => props.theme.typography.fonts.size[16]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};

  &:hover {
    background: var(--athens-gray);
  }

  ${props =>
    props.$isExpanded &&
    props.$isActive &&
    css`
      background: var(--athens-gray);
      color: var(--cod-gray);
    `}

  ${props =>
    props.$isExpanded
      ? css`
          padding: 0 12px;
        `
      : css`
          justify-content: center;
        `}

${props =>
    props.$isActive &&
    !props.$isExpanded &&
    css`
      background: var(--athens-gray);
      color: var(--cod-gray);
      /* margin: 0 8px; */
    `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  flex: 1;
`;
