import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 2px solid red;

  height: 100dvh;
  width: 100vw;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;

  filter: blur(2px);
  --webkit-backdrop-filter: blur(2px);
`;
