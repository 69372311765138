import { Sheet } from 'react-modal-sheet';
import { useProductListItem } from '../context';
import InventoryTable from '../../../../shared/InventoryTable';
import CopyableSkuInfo from '../../../../shared/CopyableSkuInfo';
import * as Styles from './styles';

const InventoryBottomSheet = () => {
  const { isInventoryBottomSheetOpen, inventoryBottomSheetActions, selectedVariant } =
    useProductListItem();

  return (
    <Sheet
      rootId="root"
      snapPoints={[0.75, 0.5]}
      isOpen={isInventoryBottomSheetOpen}
      onClose={inventoryBottomSheetActions.off}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Styles.Wrapper>
            <CopyableSkuInfo selectedVariant={selectedVariant} orientation="vertical" />
            <InventoryTable inventory={selectedVariant?.facilityInventory} />
          </Styles.Wrapper>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default InventoryBottomSheet;
