import { motion } from 'motion/react';
import styled, { css } from 'styled-components';
import { ThemeType } from '@point-of-sale/components';

interface IStyledFilterNameProps {
  $isSelected?: boolean;
  theme: ThemeType;
}

const StyledFilterName = styled(motion.button)<IStyledFilterNameProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--porcelain);
  color: var(--cod-gray);

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background-color: var(--lavender-mist);
      font-weight: ${theme.typography.fonts.weight.semibold};
    `}
`;

interface IFilterNameProps {
  isSelected?: boolean;
  children: string;
  onClick: () => void;
}

const FilterName = ({ isSelected, children, onClick }: IFilterNameProps) => {
  return (
    <StyledFilterName $isSelected={isSelected} whileTap={{ scale: 0.95 }} onClick={onClick}>
      {children}
    </StyledFilterName>
  );
};

export default FilterName;
