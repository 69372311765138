import styled from 'styled-components';
import { Text } from '@point-of-sale/components';
import { IProductListItem, ModeType } from '@point-of-sale/store';
import { formatNumberToLocale } from '@point-of-sale/utils';
import { DeterminedVariantPrices } from '../../desktop/Product/hooks/types';

interface IWrapperProps {
  $textAlign?: 'left' | 'right';
}

const Wrapper = styled.div<IWrapperProps>`
  width: unset;
  /* margin-bottom: 4px; */

  display: flex;
  flex-direction: column;
  align-items: end;
  flex-wrap: wrap;

  p,
  span {
    text-align: ${props => props.$textAlign};
    white-space: nowrap;
  }
`;

Wrapper.defaultProps = {
  $textAlign: 'right',
};

interface IProductPricePrice {
  selectedVariant: IProductListItem['variants'][number] | null;
  discount: number;
  currencySymbol: string;
  determinedVariantPrices: DeterminedVariantPrices;
  mode?: ModeType;
  isExpanded?: boolean;
  textAlign?: 'left' | 'right';
  className?: string;
}

const ProductPrice = ({
  selectedVariant,
  discount,
  currencySymbol,
  determinedVariantPrices,
  mode = 'BUY_NOW',
  isExpanded = false,
  textAlign = 'right',
  className,
}: IProductPricePrice) => {
  return (
    <Wrapper className={className} $textAlign={textAlign}>
      {!selectedVariant && (
        <Text as="span" fontSize={12} color="var(--dove-gray)">
          Starting at
          <br />
        </Text>
      )}
      {discount > 0 && (
        <Text
          as="span"
          fontSize={isExpanded ? 14 : 10}
          weight="semibold"
          color="var(--persian-red)"
        >
          {`${discount}% off`}
          &nbsp;&nbsp;
          <Text
            as="span"
            fontSize={isExpanded ? 16 : 12}
            color="var(--alto)"
            textDecorationLine="line-through"
          >
            {currencySymbol}
            &nbsp;
            {formatNumberToLocale(determinedVariantPrices.mrp)}
          </Text>{' '}
        </Text>
      )}
      <Text
        fontSize={isExpanded ? 24 : 14}
        lineHeight={isExpanded ? 32 : 20}
        textAlign="right"
        weight={isExpanded ? 'bold' : 'semibold'}
        color="var(--cod-gray)"
      >
        {currencySymbol}
        &nbsp;
        {mode === 'BUY_NOW' && formatNumberToLocale(determinedVariantPrices.sellingPrice)}
        {mode === 'MADE_TO_ORDER' &&
          formatNumberToLocale(
            determinedVariantPrices.mtoSellingPrice ?? determinedVariantPrices.sellingPrice
          )}
      </Text>
    </Wrapper>
  );
};

export default ProductPrice;
