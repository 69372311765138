import { INormalizedServiceabilityData } from '@point-of-sale/schemas';
import { Context, createContext, useContext } from 'react';

export interface ICartContext {
  rawServiceabilityData: INormalizedServiceabilityData;
}

export const CartContext: Context<ICartContext> = createContext({} as ICartContext);

export function useCartContext() {
  const contextData = useContext(CartContext);
  return contextData;
}
