import { createContext, useContext } from 'react';
import { IDiscoveryFilter } from '../../desktop/Discovery';
import { IOption } from '@point-of-sale/components';
import { IDiscountOption } from '../../desktop/ProductDiscovery/helpers';

interface DiscoveryContextProps {
  filters: Array<IDiscoveryFilter>;

  selectedFilterName: string | null;
  setSelectedFilterName: React.Dispatch<React.SetStateAction<string | null>>;

  filtersToBeApplied: Record<string, Array<IOption | IDiscountOption>>;
  setFiltersToBeApplied: React.Dispatch<
    React.SetStateAction<Record<string, Array<IOption | IDiscountOption>>>
  >;
}

export const DiscoveryContext = createContext<DiscoveryContextProps | undefined>(undefined);

export const useDiscovery = (): DiscoveryContextProps => {
  const context = useContext(DiscoveryContext);
  if (!context) {
    throw new Error('useDiscovery must be used within a DiscoveryProvider');
  }
  return context;
};
