import { formatDistanceToNow } from 'date-fns';
import { getOpenCarts, setCartDetails, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { normalizeArrayToIdsAndRecords } from '@point-of-sale/utils';
import { Button, SpinnerIcon, Text } from '@point-of-sale/components';
import { useEffect, useState } from 'react';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import * as TableStyles from '../Table/styles';
import * as Styles from './styles';
import { NoResults } from '@runway/illustrations';

const OpenCartsTable = () => {
  const { data, isLoading } = useAppSelector(store => store.openCarts.carts);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(getOpenCarts());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  useEffect(() => {
    dispatch(getOpenCarts());
  }, []);

  const [selectedCartId, setSelectedCartId] = useState(-1);

  return (
    <TableStyles.Wrapper
      $shouldFillHeight={true}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableStyles.Table>
        <TableStyles.Thead>
          <TableStyles.TableHeadingRow>
            <TableStyles.TableHeader>Cart Id</TableStyles.TableHeader>
            <TableStyles.TableHeader>Created On</TableStyles.TableHeader>
            <TableStyles.TableHeader>Customer Details</TableStyles.TableHeader>
            <TableStyles.TableHeader>Quantity</TableStyles.TableHeader>
            <TableStyles.TableHeader>Amount</TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
          </TableStyles.TableHeadingRow>
        </TableStyles.Thead>
        <tbody>
          {data?.ids.map((id, index) => (
            <TableStyles.TableRow
              key={id}
              $isSelected={id === selectedCartId}
              {...(index === data.ids.length - 3 ? { ref: lastItemRef } : {})}
            >
              <TableStyles.TableData>
                {String(data.records[id].id).padStart(4, '0')}
              </TableStyles.TableData>
              <TableStyles.TableData>
                {formatDistanceToNow(data.records[id].createdOn)} ago
              </TableStyles.TableData>
              <TableStyles.TableData>
                {!data.records[id].customerId ? (
                  <Text fontSize={12} weight="medium">
                    GUEST
                  </Text>
                ) : (
                  <>
                    <Text fontSize={12} weight="medium" display="inline-block">
                      {data.records[id].customerBillingName}
                    </Text>
                    <Text fontSize={12} weight="medium" display="inline-block">
                      {data.records[id].customerPhone}
                    </Text>
                  </>
                )}
              </TableStyles.TableData>
              <TableStyles.TableData>{data.records[id].cartItems.length}</TableStyles.TableData>
              <TableStyles.TableData>
                {data.records[id].currency} &nbsp;
                {data.records[id].chargeableAmount}
              </TableStyles.TableData>
              <TableStyles.TableData>
                <Button
                  sizeVariant="xs"
                  variant="outline"
                  onClick={() => {
                    setSelectedCartId(id);
                    dispatch(
                      setCartDetails({
                        data: {
                          ...data.records[id],
                          cartItems: normalizeArrayToIdsAndRecords(data.records[id].cartItems),
                        },
                      })
                    );
                  }}
                >
                  View Cart
                </Button>
              </TableStyles.TableData>
            </TableStyles.TableRow>
          ))}
        </tbody>
      </TableStyles.Table>
      {!isLoading && data?.ids.length === 0 && <NoResults />}
      {isLoading && data?.ids.length === 0 && (
        <Styles.SpinnerWrapper>
          <SpinnerIcon size={48} />
        </Styles.SpinnerWrapper>
      )}
    </TableStyles.Wrapper>
  );
};

export default OpenCartsTable;
