import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  display: flex;
`;

export const CameraDisplay = styled.div`
  width: 100%;
  height: 400px; // Maintain a consistent height
  max-height: 400px; // Prevent growth beyond this
  flex: 1;
  overflow: hidden; // Prevent overflow issues
  position: relative;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
