import { Button } from '@point-of-sale/components';
import styled from 'styled-components';

export const NextButton = styled(Button)`
  width: 50%;
  /* margin: 0 auto; */
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 48px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const PolicyWrapper = styled.div``;
