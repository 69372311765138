import { useEffect, useState } from 'react';
import {
  IProductListItem,
  setSelectedColor,
  setSelectedSize,
  useAppDispatch,
} from '@point-of-sale/store';
import { ProductVariantDTOType } from '@point-of-sale/schemas';
import { IColor, ISize } from '@point-of-sale/components';
import { getVariantFilterOptions } from '../components/desktop/Product/utils';
import useGetQuantityForSizeByColor from '../components/desktop/Product/VariantSelection/useGetQuantityForSizeByColor';

export const useProductVariants = ({ data }: { data: IProductListItem }) => {
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantDTOType | null>(null);

  const [enabledSizes, setEnabledSizes] = useState<Array<string>>([]);

  const { getQuantityForSizeByColor } = useGetQuantityForSizeByColor(data);

  const allOptions = data.options.sort((a, b) => b.position - a.position);
  const allColorOptions = getVariantFilterOptions(allOptions, 'COLOR');
  const allSizeOptions = getVariantFilterOptions(allOptions, 'SIZE').map((item, index) => ({
    id: index + 1,
    name: item.displayName,
    quantity: getQuantityForSizeByColor(item.displayName),
  }));

  const dispatch = useAppDispatch();

  const onSelectSize = (newSize: ISize) => {
    const filteredColors = data.variants
      .filter(variant => variant.size === newSize.name)
      .map(variant => variant.color);

    if (data.selectedColor && !filteredColors.includes(data.selectedColor)) {
      // setSelectedColor('');
      dispatch(
        setSelectedColor({
          id: data.id,
          value: null,
        })
      );
    } else {
      // setSelectedSize(newSize.name);
      dispatch(
        setSelectedSize({
          id: data.id,
          value: newSize.name,
        })
      );
    }
  };

  const onSelectColor = (newColor: IColor) => {
    const filteredSizes = data.variants
      .filter(variant => variant.color === newColor.name)
      .map(variant => variant.size);

    setEnabledSizes(filteredSizes);

    if (data.selectedSize && !filteredSizes.includes(data.selectedSize)) {
      // setSelectedSize('');

      dispatch(
        setSelectedSize({
          id: data.id,
          value: null,
        })
      );
    } else {
      // setSelectedColor(newColor.name);

      dispatch(
        setSelectedColor({
          id: data.id,
          value: newColor.name,
        })
      );
    }
  };

  useEffect(() => {
    if (!data.selectedSize || !data.selectedColor) {
      setSelectedVariant(null);
      return;
    }

    const variant =
      data.variants.find(
        variant => variant.size === data.selectedSize && variant.color === data.selectedColor
      ) ?? null;

    if (!variant || variant.skuCode === selectedVariant?.skuCode) {
      return;
    }

    setSelectedVariant(variant);
  }, [data.selectedColor, data.selectedSize, data.variants, selectedVariant?.skuCode]);

  return {
    allColorOptions,
    allSizeOptions,
    selectedVariant,
    enabledSizes,
    setEnabledSizes,
    onSelectSize,
    onSelectColor,
  };
};
