import { z } from 'zod';
import { ProductTypeDTOSchema } from '../cart';
import { CustomizationCategorySchema } from '../cart/customizationCategory';

export const ProductOptionDTOSchema = z.object({
  name: z.string(),
  values: z.array(z.string()),
  optionsValues: z.array(
    z.object({
      displayName: z.string(),
      colorCodes: z.array(z.string()).optional(),
    })
  ),
  position: z.number(),
});
// .strict();

const ProductVariantFacilityInventoryDTOSchema = z.object({
  availableInventory: z.number().optional(),
});
// .strict();

export const ProductImageDTOSchema = z
  .object({
    id: z.number().optional(),
    uploaded: z.boolean(),
    url: z.string().url(),
    originalUrl: z.string().optional(),
  })
  .strict();

export const ProductVariantDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  skuId: z.number(),
  skuCode: z.string(),
  sellingPrice: z.number(),
  mrp: z.number(),
  vendorArticleNumber: z.string().optional(),
  enabled: z.boolean(),
  barcode: z.string(),
  color: z.string(),
  size: z.string(),
  currency: z.string(),
  option: z
    .object({
      SIZE: z.string(),
      COLOR: z.string(),
    })
    .strict(),

  facilityInventory: z.record(z.string(), ProductVariantFacilityInventoryDTOSchema).optional(),
  availableFacilities: z.array(z.string()).optional(),
  inventorySummary: z.record(z.string(), z.number()).optional(),
  mtoAcceptedByBrand: z.boolean(),
  mtcAcceptedByBrand: z.boolean().optional(),
  inStockFacilities: z.array(z.string()).optional(),
  mtoTAT: z.number().optional(),
  mtcTAT: z.number().optional(),

  exchangeable: z.boolean().optional(),
  returnable: z.boolean().optional(),

  images: z.array(ProductImageDTOSchema).optional(),

  mtoReturnable: z.boolean().optional(),
  mtcReturnable: z.boolean().optional(),

  mtoSellingPrice: z.number().optional(),

  jitFacilities: z.array(z.string()).optional(),
  discountGroupId: z.number().optional(),
});
// .strict();

export type ProductVariantDTOType = z.infer<typeof ProductVariantDTOSchema>;

const AddressSchema = z.object({
  pincode: z.string(),
  cityCode: z.string(),
  locality: z.string(),
  stateCode: z.string().optional(),
  addressLine: z.string(),
  countryCode: z.string(),
  cityDisplayName: z.string(),
  stateDisplayName: z.string().optional(),
  countryDisplayName: z.string(),
});

export const VendorDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  name: z.string(),
  email: z.string().optional(),
  vin: z.string(),
  vinType: z.string(),
  isDomestic: z.boolean(),
  status: z.string(),
  brands: z.array(z.object({ name: z.string() })),
  brandManagerId: z.number(),
  shippingAddress: AddressSchema.optional(),
  gstAddress: AddressSchema.optional(),
  jitOrderFulfilmentModel: z.string().optional(),
  jitOrderFulfilmentEnabled: z.boolean().optional(),
  jitTat: z.number().optional(),
  mtoTat: z.number().optional(),
  mtcTat: z.number().optional(),
});

export const ProductDTOSchema = z.object({
  id: z.number(),
  name: z.string(),
  components: z.string().optional(),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  vendorArticleNumber: z.string().optional(),
  brand: z.object({
    name: z.string(),
  }),
  vendorId: z.number(),
  variants: z.array(ProductVariantDTOSchema),
  options: z.array(ProductOptionDTOSchema),
  images: z.array(ProductImageDTOSchema).optional(),
  vendor: VendorDTOSchema,
  productTypeId: z.number(),
  productType: ProductTypeDTOSchema.optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
});

export type ProductDTOType = z.infer<typeof ProductDTOSchema>;
