import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Modal, SpinnerIcon, Text } from '@point-of-sale/components';
import {
  addInvoiceToOrder,
  dispatchThunksSequentially,
  getCartById,
  getCartTransactions,
  getOrderDetailsById,
  resetCheckoutState,
  resetPointOfSaleState,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';

import { AssetUploaderService } from '@point-of-sale/services';
import PrintableInvoice from '../../../../pages/desktop/Checkout/PrintableInvoice';
import { OrderDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';

interface IInvoiceRegenerationModalProps {
  orderData: OrderDTOType;
  isOpen: boolean;
  onClose: () => void;
}

const InvoiceRegenerationModal = ({
  orderData,
  isOpen,
  onClose,
}: IInvoiceRegenerationModalProps) => {
  const cartTransactions = useAppSelector(state => state.checkout.cartTransactions);

  const dispatch = useAppDispatch();

  const componentRef = useRef<HTMLDivElement>(null);

  const [isPdfLoading, pdfLoadingActions] = useBoolean();
  const [isFetchingData, fetchingDataActions] = useBoolean();

  const triggerPrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `COYU-${orderData.salesChannelOrderId}`,
  });

  const generatePDF = async () => {
    const content = componentRef.current;

    if (!content) {
      return;
    }

    pdfLoadingActions.on();

    // Convert the content to canvas using html2canvas
    const canvas = await html2canvas(content);

    // Convert 80mm to points (1mm = 2.83465 points)
    const pdfWidthMM = 80;
    const pdfWidthPoints = pdfWidthMM * 2.83465;

    // Calculate the height based on the canvas aspect ratio
    const aspectRatio = canvas.height / canvas.width;
    const pdfHeightPoints = pdfWidthPoints * aspectRatio;

    // Create jsPDF instance with the specified width of 80mm and dynamic height
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt', // Use points for precise sizing
      format: [pdfWidthPoints, pdfHeightPoints], // Set custom width and height
    });

    // Convert canvas to image data
    const imgData = canvas.toDataURL('image/png');

    // Add the image to the PDF
    pdf.addImage(
      imgData,
      'PNG',
      0, // X position (no margin)
      0, // Y position (no margin)
      pdfWidthPoints, // Set width exactly to 80mm (converted to points)
      pdfHeightPoints // Proportional height based on the aspect ratio
    );

    const pdfBlob = pdf.output('blob');

    const assetUploader = new AssetUploaderService();

    await assetUploader.uploadFile({
      file: pdfBlob,
      onError: (error: string) => {
        console.error('Error uploading file', error);
      },
      onSuccess: (uploadedFile: any) => {
        console.log('Uploaded File', uploadedFile);

        dispatch(
          addInvoiceToOrder(orderData.id, { id: uploadedFile.id, url: uploadedFile.url }, () => {
            dispatch(getOrderDetailsById(String(orderData.id)));
            triggerPrint();
            pdfLoadingActions.off();
            dispatch(resetPointOfSaleState());
            dispatch(resetCheckoutState());
            onClose();
          })
        );
      },
      usage: 'INVOICE',
    });

    return pdfBlob;
  };

  useEffect(() => {
    async function doAllWork() {
      fetchingDataActions.on();

      try {
        await dispatchThunksSequentially(dispatch, [
          [getCartById, orderData.cartId, false],
          [getCartTransactions, orderData.cartId],
        ]);
        generatePDF();
      } catch (error) {
        console.error('Error in processing:', error);
      } finally {
        fetchingDataActions.off();
      }
    }

    if (isOpen) {
      if (!orderData.purchaseReceipt?.url) {
        doAllWork();
      }
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="regular">
      <Styles.Wrapper>
        {(isFetchingData || isPdfLoading) && (
          <>
            <SpinnerIcon size={56} stroke="var(--tulip-tree)" />
            <Text fontSize={20} weight="bold" textAlign="center" color="var(--tulip-tree)">
              Invoice for Order {orderData.salesChannelOrderId} is being generated
            </Text>
          </>
        )}
      </Styles.Wrapper>

      {Object.keys(orderData).length > 0 && (
        <div ref={componentRef} style={{ width: '80mm' }}>
          <PrintableInvoice
            data={orderData}
            type="INVOICE"
            customerGSTIN={orderData.customerGSTIN}
            cartTransactions={cartTransactions.data.records}
            isBeingRegenerated
          />
        </div>
      )}
    </Modal>
  );
};

export default InvoiceRegenerationModal;
