import { AnimatePresence } from 'motion/react';
import { PosFilterNames, useAppSelector } from '@point-of-sale/store';
import { IconButton, Text } from '@point-of-sale/components';
import { IFilter } from '@point-of-sale/services';
import { useDiscovery } from '../context';
import * as Styles from './styles';

interface ISelectedFiltersProps {
  onClick: (filtersToBeApplied: Record<string, any>) => void;
}
const SelectedFilters = ({ onClick }: ISelectedFiltersProps) => {
  const { filtersToBeApplied, setFiltersToBeApplied } = useDiscovery();

  const facets = useAppSelector(store => store.pointOfSale.facets);
  const filters = useAppSelector(store => store.pointOfSale.filters);
  const facilities = useAppSelector(store => store.common.facilities.data.records);

  function getDisplayableValue(filter: IFilter, filterValue: string) {
    const filterName = filter.field as PosFilterNames;

    if (filterName === 'variants.color' || filterName === 'variants.size') {
      return filterValue;
    }

    if (filterName === 'brandName') {
      return facets
        .find(facet => facet.key.key === filterName)
        ?.values.find(value => value.id === filterValue)?.value;
    }

    if (filterName === 'variants.discountGroupId') {
      return `Buy 2 get 20% off`;
    }

    if (filterName === 'variants.totalDiscount') {
      return `${filterValue}% off`;
    }

    if (filterName === 'variants.inStockFacilities') {
      return Object.values(facilities).find(facility => String(facility.id) === String(filterValue))
        ?.name;
    }

    return `Unknown filter: ${filterName}`;

    // return Object.values(facilities).find(facility => String(facility.id) === String(filterValue))
    //   ?.name;
  }

  if (Object.values(filters).length === 0) {
    return null;
  }

  return (
    <Styles.Wrapper>
      <AnimatePresence mode="popLayout">
        {Object.values(filters).map(filter =>
          String(filter.value)
            .split(',')
            .map(value => {
              const displayableValue = getDisplayableValue(filter, value);

              if (!displayableValue || displayableValue?.length === 0) {
                return null;
              }

              return (
                <Styles.Filter
                  layout
                  key={value}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                  exit={{
                    scale: 0,
                    opacity: 0,
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 900,
                    damping: 40,
                    duration: 0.3,
                  }}
                >
                  <Text fontSize={10} color="var(--cod-gray)">
                    {displayableValue}
                  </Text>
                  <IconButton
                    icon="CrossIcon"
                    onClick={() => {
                      if (filter) {
                        const newFiltersToBeApplied = structuredClone(filtersToBeApplied);

                        const filterName = [
                          'variants.discountGroupId',
                          'variants.totalDiscount',
                        ].includes(filter.field)
                          ? 'discounts'
                          : filter.field;

                        const updatedFilters = newFiltersToBeApplied[filterName].filter(
                          f => String(f.value) !== String(value)
                        );

                        newFiltersToBeApplied[filterName] = updatedFilters;

                        setFiltersToBeApplied(newFiltersToBeApplied);
                        onClick(newFiltersToBeApplied);
                      }
                    }}
                    shapeVariant="circle"
                    sizeVariant="extraSmall"
                  />
                </Styles.Filter>
              );
            })
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default SelectedFilters;
