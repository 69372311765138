import styled, { css } from 'styled-components';

interface ISkuInfoRowProps {
  $orientation: 'horizontal' | 'vertical';
}

export const SkuInfoRow = styled.div<ISkuInfoRowProps>`
  padding-top: 4px;

  ${({ $orientation }) =>
    $orientation === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ $orientation }) =>
    $orientation === 'vertical' &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const SkuInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
`;
