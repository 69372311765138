import { motion } from 'motion/react';
import styled from 'styled-components';
import { CheckMarkIcon, Text, ThemeType } from '@point-of-sale/components';

interface IStyledFilterValueProps {
  theme: ThemeType;
}

const StyledFilterValue = styled(motion.button)<IStyledFilterValueProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 4px;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--white);
  color: var(--cod-gray);

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};

  border-bottom: 1px solid var(--athens-gray);

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

interface IFilterValueProps {
  isSelected?: boolean;
  value: string | number;
  label: string | number;
  onClick: (value: string | number) => void;
  count?: number;
}

const FilterValue = ({ isSelected, value, label, onClick, count }: IFilterValueProps) => {
  return (
    <StyledFilterValue
      onClick={() => onClick(value)}
      // whileTap={{ scale: 0.95 }}
    >
      <CheckMarkIcon stroke={isSelected ? 'var(--royal-blue)' : 'var(--oslo-gray)'} />
      <Text weight={isSelected ? 'bold' : 'regular'} fontSize={14} textAlign="left">
        {label}
      </Text>
      {count && (
        <Text fontSize={14} width={'min-content'} color="var(--oslo-gray)">
          {count}
        </Text>
      )}
    </StyledFilterValue>
  );
};

export default FilterValue;
