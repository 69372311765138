import { z } from 'zod';
import { CustomizationCategorySchema } from '../cart/customizationCategory';

export const AlterationEntryDTOSchema = z.object({
  referenceColor: z.string(),
  referenceSize: z.string(),
  remarks: z.string().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
  topwearAlterationEntry: z
    .object({
      shoulder: z.string().optional(),
      bust: z.string().optional(),
      armHole: z.string().optional(),
      waist: z.string().optional(),
      hips: z.string().optional(),
      sleeveLength: z.string().optional(),
      sleeveRound: z.string().optional(),
      dressLength: z.string().optional(),
    })
    .optional(),
  bottomwearAlterationEntry: z
    .object({
      waist: z.string().optional(),
      hips: z.string().optional(),
      thigh: z.string().optional(),
      pantLength: z.string().optional(),
    })
    .optional(),
});

export type AlterationEntryDTOType = z.infer<typeof AlterationEntryDTOSchema>;
