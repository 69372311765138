export type FontWeight = {
  regular: 400;
  medium: 500;
  semibold: 600;
  bold: 700;
};

const fontWeight: FontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

/**
 *
 * TODO: Make this extensible
 * such that same token is usable on desktop and mobile
 * currently, the tokens are only usable on desktop
 */
export type FontSize = {
  10: '10px';
  12: '12px';
  14: '14px';
  16: '16px';
  18: '18px';
  20: '20px';
  22: '22px';
  24: '24px';
  28: '28px';
  32: '32px';
  36: '36px';
  48: '48px';
};

export type FontSizeKeys = keyof FontSize;

export type FontFamily = {
  primary: string;
  secondary: string;
};

const fontFamily: FontFamily = {
  primary: 'Hanken Grotesk',
  secondary: 'DM Serif Text',
};

export type Typography = {
  fonts: {
    // family: {
    //   lato: 'Lato';
    //   manrope: 'Manrope';
    // };
    family: FontFamily;
    size: FontSize;
    weight: FontWeight;
  };
};

export type TypographyPlatforms = 'DESKTOP' | 'MOBILE';

export type TypographyWithPlatforms = Record<TypographyPlatforms, Typography>;

export const typography: TypographyWithPlatforms = {
  DESKTOP: {
    fonts: {
      // family: {
      //   lato: 'Lato',
      //   manrope: 'Manrope',
      // },
      size: {
        10: '10px',
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        28: '28px',
        32: '32px',
        36: '36px',
        48: '48px',
      },
      weight: {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
    },
  },
  MOBILE: {
    fonts: {
      // family: {
      //   lato: 'Lato',
      //   manrope: 'Manrope',
      // },
      size: {
        10: '10px',
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        28: '28px',
        32: '32px',
        36: '36px',
        48: '48px',
      },
      weight: {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
    },
  },
};
