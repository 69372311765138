import styled from 'styled-components';
import { VIEW_PORTS } from '@point-of-sale/components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  max-height: 100vh;
  background: var(--white);
`;

export const Left = styled.div`
  position: relative;
  background: var(--royal-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  ${VIEW_PORTS.large} {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  ${VIEW_PORTS.large} {
    width: 100%;
  }

  /* ${VIEW_PORTS.extraLarge} {
    width: 60%;
  } */
`;

export const ImageWrapper = styled.div`
  width: 60%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CompanyLogoWrapper = styled.div`
  position: absolute;

  left: 4px;
  bottom: 4px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 16px;
  padding: 32px;
  border: 1px solid var(--dove-gray);

  .login-input,
  .big-button {
    width: 100%;
  }

  ${VIEW_PORTS.large} {
    padding: 16px;
  }
`;

export const StyledOtpInput = styled.input`
  height: 55px;
  width: 55px !important;
  margin-right: 16px;
  border-radius: 1px;
  border: 1px solid var(--alto);

  &:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  ${VIEW_PORTS.extraLarge} {
    height: 50px;
    width: 50px !important;
    margin-right: 1rem;
    padding: 0;
  }

  ${VIEW_PORTS.medium} {
    height: 40px;
    width: 40px !important;
    margin-right: 1rem;
    padding: 0;
  }

  ${VIEW_PORTS.small} {
    height: 50px;
    width: 50px !important;
    font-size: 16px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const RightContent = styled.div`
  width: 30vw;

  ${VIEW_PORTS.large} {
    width: 50vw;
  }

  ${VIEW_PORTS.small} {
    width: 90vw;
  }
`;

export const Heading = styled.div`
  padding: 20px;
  border-top: 1px solid var(--dove-gray);
  border-left: 1px solid var(--dove-gray);
  border-right: 1px solid var(--dove-gray);
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  padding: 12px 0;
`;

export const OTPInputWrapper = styled.div``;
