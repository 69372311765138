import {
  DataCaptureView,
  Camera,
  DataCaptureContext,
  configure,
  FrameSourceState,
  RectangularViewfinder,
  RectangularViewfinderStyle,
  RectangularViewfinderLineStyle,
} from '@scandit/web-datacapture-core';
import type { Barcode, BarcodeCaptureSession } from '@scandit/web-datacapture-barcode';
import {
  barcodeCaptureLoader,
  BarcodeCapture,
  BarcodeCaptureSettings,
  Symbology,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
} from '@scandit/web-datacapture-barcode';

const SCANDIT_LICENSE_KEY = `Av2lBG3nSOjXI1dqDtS9wzUd1ZiIEBgR+il6EbIE0tb7TQTHagjz5m5ZfkInVcs+2VPraWZi624rWVhwqXH7Ffc/qJenDj4T5glfVpkMG02uSdb62DXWxXoVtGr4BZw9GT6vNqtepkd7WQsrbkbWjtdK5xosAEqbEzFeqeMH9Xv/ZgwGdEnN5ZJRMPMeDv6j0B3HKjkfiTdmW6GJX1MWsmZx1KKbEjs7PQB6/CA8APw5EA19sBOl0KhNxY8XHjXX7BV8z74XyGRYBVAD0CF2cbMwIrCEIxJchACgW+Ezt7cgL/gwpnBgang3SRy6JlzcCyePjykRzi8zCmWt5AGk8Zgv2IpfQv7GcFHB7rJ04tRZSEgVDlneVf8vahAxUc5Cd2uvYclXsztofAwGgi/ktflaA3dtDyVQUVetKkowCnGqW+jZgjxnX9pWEXUPcnF0Emsg7ytlm+GgQEgFxwTjaqp7uLp1V1b/eCpiUXobivfkZHTV8Wn9k2cVcBy/DF/3aE110GhskrL/VHLiaA7c92VI0t4IaMBT1ncWREhYTdtrOrmxBmDVnvwoPR54CAtW7FAvGuo0O5/YcOut2CzRnQJ+BN84bYWOQGnrFaZsagEaUQ9KsSRW+zdG3z3ebRNGYnDAs5ZHE7HVV6NBqnw5pZ9IRpM4DSQ9dmEDx1hHeSPoZxakgV88VSR07VUTNZo7CkbZBB5w3hI2ZeWza3SBzkJhqIJccgPA4kzP/bVtOgb+Uq6Z8jB4qYQ+FAIGRKbdoEARSTYBxk19a9jgrR6Xf49jYdolfvVXfWPiaQX5uYw0/C19up5e13ZvwFU7AWkIPqpIQZprbUNvpS8W6rTyPYpLLdqwA888wxO9Vss2IK7GtcnUM06BhMhpcMRW2nfyR7iJXIf53XZifSB6HUqtLD+IQkKug5UiYUGcb6/IXaXBPUQ+mw9icThTVKzWE5Ul+U2TipexCWCXGf6pgTbXYd4LAUo31Icxf3iubfZMbl4YDUi8m+/JO9aODZBZw0xCIKZbjAPZxpn7irgar056iFk17TSP7sKSWYpm5qsH4C97uoGcUEfW/4lVTQUN/aBdhAeqRHlQ+pF9rJeixags0KOo8aoQxiMp9K9j68kFSwWoCddHrTQXuDJgo5TbUsTx02TJkNs1eytBLAA+eLlnZ2EtjgkLtrCIV4lOw1VLk966ysFvwAxsC2qDbEnB8SRtFS10frk5Gj+sQpT7EqJ0IqJT783+bNqy+Df7m7xB68/XmJDCSK0a4b+YBqeJ6aSox1nZtV7CMQSEEjuCbfhwFv/bHvSTLpF1XuNAW0MshIS26TPH6DqLNi6Z0bdMMhD9bE2wJ+TFOcsyM1uSSiAl6CoS3PN5B2lX1S8CLqgwCdDmAGyuSgsYDR4n9JTPSSQAGyE8jXqj/5pU7otD1itGYe0cpRKIxTZ7V3D8VQ==`;
const SCANDIT_LIBRARY_LOCATION = '/sdc-lib';

type ScanditBarcodeScannerEvent = 'scan';

export class ScanditBarcodeScanner {
  private static instance: ScanditBarcodeScanner;
  private events: Record<string, CallableFunction> = {};
  private ranOnce = false;

  private barcodeCapture: BarcodeCapture | null = null;
  private barcodeCaptureOverlay: BarcodeCaptureOverlay | null = null;
  private viewfinder: RectangularViewfinder | null = null;

  public subscribe(eventName: ScanditBarcodeScannerEvent, callback: CallableFunction): void {
    if (typeof this.events[eventName] === 'function') {
      return;
    }

    this.events[eventName] = callback;
  }

  public unsubscribe(eventName: ScanditBarcodeScannerEvent): void {
    delete this.events[eventName];
  }

  public emit(eventName: ScanditBarcodeScannerEvent, ...args: Array<any>): void {
    const eventCallbacks = this.events[eventName];
    if (eventCallbacks) {
      eventCallbacks(...args);
    }
  }

  public static getInstance(): ScanditBarcodeScanner {
    if (!ScanditBarcodeScanner.instance) {
      ScanditBarcodeScanner.instance = new ScanditBarcodeScanner();
    }
    return ScanditBarcodeScanner.instance;
  }

  async run(element: HTMLDivElement) {
    // if (this.ranOnce) {
    //   await this.resumeScanning();
    // }

    const view = new DataCaptureView();
    view.connectToElement(element);

    view.showProgressBar();

    view.setProgressBarMessage('Verifying license...');

    await configure({
      licenseKey: SCANDIT_LICENSE_KEY,
      libraryLocation: SCANDIT_LIBRARY_LOCATION,
      moduleLoaders: [barcodeCaptureLoader()],
    });

    const context: DataCaptureContext = await DataCaptureContext.create();

    view.setProgressBarMessage('Creating Context...');
    // To visualize the ongoing barcode capturing process on screen, attach the data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    await view.setContext(context);

    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    const camera: Camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    view.setProgressBarMessage('Configuring Camera...');
    await camera.applySettings(cameraSettings);
    await context.setFrameSource(camera);

    // The barcode capturing process is configured through barcode capture settings,
    // they are then applied to the barcode capture instance that manages barcode recognition.
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();

    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies([Symbology.Code128]);
    this.barcodeCapture = await BarcodeCapture.forContext(context, settings);

    // Disable the barcode capture mode until the camera is accessed.
    await this.barcodeCapture?.setEnabled(false);

    view.setProgressBarMessage('Setting up barcode capture...');
    // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
    // the video preview. This is optional, but recommended for better visual feedback.
    this.barcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
      this.barcodeCapture,
      view,
      BarcodeCaptureOverlayStyle.Frame
    );

    this.viewfinder = new RectangularViewfinder(
      RectangularViewfinderStyle.Square,
      RectangularViewfinderLineStyle.Light
    );
    view.setProgressBarMessage('Setting up viewfinder...');
    await this.barcodeCaptureOverlay.setViewfinder(this.viewfinder);

    view.setProgressBarMessage('Turning on camera...');
    await this.barcodeCapture.setEnabled(true);

    // Switch the camera on to start streaming frames.
    // The camera is started asynchronously and will take some time to completely turn on.
    await camera.switchToDesiredState(FrameSourceState.On);

    view.hideProgressBar();

    // if (this.ranOnce) {
    // don't add event listener again
    //   return;
    // }

    this.barcodeCapture.addListener({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        await this.barcodeCapture?.setEnabled(false);
        await this.barcodeCaptureOverlay?.setViewfinder(null);

        const barcode: Barcode | null = session.newlyRecognizedBarcode;

        if (!barcode) {
          return;
        }

        this.emit('scan', barcode.data ?? '');
      },
    });

    // this.ranOnce = true;
  }
}
