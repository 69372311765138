import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const Left = styled.div`
  width: 30%;
  background-color: var(--porcelain);

  overflow: auto;
`;

export const Right = styled.div`
  width: 70%;
  overflow: auto;
`;

// ##################################################################### //
// ######################## Bottom Sheet Styles ######################## //
// ##################################################################### //

export const HeaderContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`;

export const ContentWrapper = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ContentBody = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const BottomSheetFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: sticky;
  bottom: 0;
  background: var(--white);
  width: 100%;
  padding: 12px;
`;
