import { useState, useRef } from 'react';
import { z } from 'zod';
import toast from 'react-hot-toast';
import {
  FacilityDTOType,
  INormalizedServiceabilityData,
  ServiceabilityDTOSchema,
  ServiceabilityDTOType,
} from '@point-of-sale/schemas';
import { getNormalizedServiceabilityData, getServiceabilityApi } from '@point-of-sale/store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { apiResponseHandler } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';

const useGetServiceability = () => {
  const isFetchingRef = useRef(false);

  const [isFetching, fetchingActions] = useBoolean();

  const [data, setData] = useState<INormalizedServiceabilityData>({});

  const fetchServiceabilityData = async (
    skuCodes: Array<string>,
    destinationPincodes: Array<string>,
    sourceFacilities: Array<FacilityDTOType>
  ) => {
    if (
      skuCodes?.filter(Boolean).length === 0 ||
      destinationPincodes?.filter(Boolean).length === 0 ||
      sourceFacilities?.filter(Boolean).length === 0
    ) {
      return { ids: [], records: {} };
    }

    if (isFetchingRef.current) {
      return { ids: [], records: {} };
    }

    fetchingActions.on();

    const payload = {
      skuCodes,
      destinationPincodes,
      sourceFacilities,
    };

    const response = await apiResponseHandler<Array<ServiceabilityDTOType>>(
      getServiceabilityApi(payload),
      z.array(ServiceabilityDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      // setData(fetchedData);
      toast.error(response.meta?.message ?? 'Something went wrong');
      fetchingActions.off();
    } else {
      setData(getNormalizedServiceabilityData(response.data));
    }

    fetchingActions.off();
  };

  return {
    serviceabilityData: data,
    fetchServiceabilityData,
    isFetchingServiceability: isFetching,
  };
};

export default useGetServiceability;
