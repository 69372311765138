// export const MEDIA_SIZES = Object.freeze({
//   small: '576px',
//   medium: '768px',
//   large: '992px',
//   extraLarge: '1200px',
// });

// export const DEVICE = Object.freeze({
//   smallViewport: `@media only screen and (max-width: ${MEDIA_SIZES.small})`,
//   mediumViewport: `@media only screen and (max-width: ${MEDIA_SIZES.medium})`,
//   largeViewport: `@media only screen and (max-width: ${MEDIA_SIZES.large})`,
//   extraLargeViewport: `@media only screen and (max-width: ${MEDIA_SIZES.extraLarge})`,
// });

// export const MAXIMUM_Z_INDEX = 2147483647;
// export const MINIMUM_Z_INDEX = -MAXIMUM_Z_INDEX;

// export const MODAL_Z_INDEX = MAXIMUM_Z_INDEX - 1;
// export const NAVIGATION_Z_INDEX = MAXIMUM_Z_INDEX - MODAL_Z_INDEX;

export const NAVIGATION_Z_INDEX = 10;
export const MODAL_Z_INDEX = 20;
