import type { Method } from 'axios';
import { ENV } from '@point-of-sale/env';
import { BaseApiService } from './BaseApiService';

// const baseApiService = new BaseApiService('');
const baseApiService = new BaseApiService(ENV.VITE_BASE_URL);

export const callApi = <T>(method: Method, endPoint: string, data?: T, headers?: HeadersInit) =>
  baseApiService.callApi<T>({
    method,
    endPoint,
    data,
    headers,
  });
