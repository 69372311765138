export const MEDIA_SIZES = {
  extraSmall: '480px',
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  ultraLarge: '1400px',
} as const;

export const VIEW_PORTS = {
  extraSmall: `@media only screen and (max-width: ${MEDIA_SIZES.extraSmall})`,
  small: `@media only screen and (max-width: ${MEDIA_SIZES.small})`,
  medium: `@media only screen and (max-width: ${MEDIA_SIZES.medium})`,
  large: `@media only screen and (max-width: ${MEDIA_SIZES.large})`,
  extraLarge: `@media only screen and (max-width: ${MEDIA_SIZES.extraLarge})`,
  ultraLarge: `@media only screen and (max-width: ${MEDIA_SIZES.ultraLarge})`,
} as const;
